import {INotification, INotificationsMain} from "../types/notificationsTypes";

export const addNewNotification = (state: INotificationsMain, nt: INotification) => {
    if(window.location.href.includes('calls')) {
        return
    }
    switch (nt.event) {
    case "USER_RELATIONSHIP_FRIEND_NEW":
        if (nt.is_self === 0) {
            state.numbOfNotifications = {
                ...state.numbOfNotifications,
                newFriends: state.numbOfNotifications.newFriends + 1
            }
        }
        break

    case "CHAT_USER_REQUEST_NEW":
        if (nt.is_self === 0) {
            state.numbOfNotifications = {
                ...state.numbOfNotifications,
                newRequestInChats: state.numbOfNotifications.newRequestInChats + 1
            }
        }
        break

    case "ROOM_USER_REQUEST_NEW":
        if (nt.is_self === 0) {
            state.numbOfNotifications = {
                ...state.numbOfNotifications,
                newRequestInRooms: state.numbOfNotifications.newRequestInRooms + 1
            }
        }
        break
    case "SCHEDULE_END":
        if (nt.is_self === 0) {
            state.notificationStart = undefined
            if (state.notifications) {
                state.notifications = [...state.notifications, nt]
            } else {
                state.notifications = [nt]
            }
        }
        break

    case "USER_RELATIONSHIP_FRIEND_ACCEPT":
    case "USER_RELATIONSHIP_FRIEND_DECLINE":
    case "CHAT_USER_REQUEST_APPROVED":
    case "CHAT_USER_REQUEST_DECLINED":
    case "ROOM_USER_REQUEST_APPROVED":
    case "ROOM_USER_REQUEST_DECLINED":
    case "SCHEDULE_USER_SUBSCRIBED":
    case "SCHEDULE_USER_UNSUBSCRIBED":
    case "SCHEDULE_5MIN_START":
    case "SCHEDULE_60MIN_START":
    case "SCHEDULE_START":
        if (nt.is_self === 0) {
            if (state.notifications) {
                state.notifications = [...state.notifications, nt]
            } else {
                state.notifications = [nt]
            }
        }
        break
    }

    state.last_id = nt.id
}
