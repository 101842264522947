import {ITagsFilter} from "../../models/ITagsFilter";

export const tagsFiltersEN: ITagsFilter[] = [
    {
        tagTitleAddiction: "Addiction", statusActive: false, isActiveSelectors: [], idNavTag: 'qwe',
        tagFilters: [
            {
                title: 'Addiction', isActive: false, idTag: '1'
            },
            {
                title: 'Alcoholism',
                isActive: false,
                idTag: '2'
            },
            {
                title: 'Ludomania', isActive: false, idTag: '3'
            },
            {
                title: 'Gambling addiction',
                isActive: false,
                idTag: '4'
            },
            {
                title: 'Codependency', isActive: false, idTag: '5'
            },
            // {
            //     title: 'Sexaholism',
            //     isActive: false,
            //     idTag: '2-6'
            // }
        ]
    },
    {
        tagTitleAddiction: "Issues", statusActive: false, isActiveSelectors: [], idNavTag: 'ewq',
        tagFilters: [
            {title: 'Insomnia', isActive: false, idTag: '1'},
            {
                title: 'Bullying',
                isActive: false,
                idTag: '2'
            },
            {title: 'Depression', isActive: false, idTag: '3'},
            {
                title: 'Kleptomania',
                isActive: false,
                idTag: '4'
            },
            {title: 'Uncertainty', isActive: false, idTag: '5'},
            {
                title: 'offense',
                isActive: false,
                idTag: '6'
            },
            {title: 'Loneliness', isActive: false, idTag: '7'},
            {
                title: 'PTSD',
                isActive: false,
                idTag: '8'
            },
            {title: 'Panic attacks', isActive: false, idTag: '9'},
            {
                title: 'Eating Disorders',
                isActive: false,
                idTag: '10'
            },
            {title: 'Anxiety', isActive: false, idTag: '11'},
            {
                title: 'Workaholism',
                isActive: false,
                idTag: '12'
            },
            {title: 'Loss of a loved one', isActive: false, idTag: '13'},
            {
                title: 'Phobias and allergies',
                isActive: false,
                idTag: '14'
            },
            {title: 'Sense of Fear', isActive: false, idTag: '15'}
        ]
    }
]
