import {useCallback, useMemo, useState} from "react";
import {UserGroup} from "../store/reducers/profile/types/profileTypes";
import {RoutePath} from "../router/routerConfig/routerConfig";
import {useAppSelector} from "../store/hooks/redux";
import {
    getProfileGroupId,
    getProfileId
} from "../store/reducers/profile/selectors/getProfile/getProfile";
import {useNavigate} from "react-router-dom";

export const useProfileModal = () => {
    const [isShowProfile, setIsShowProfile] = useState(false)
    const [profileModalId, setProfileModalId] = useState<string | undefined>()

    const myId = useAppSelector(getProfileId)
    const profileGroupId = useAppSelector(getProfileGroupId)
    const navigate = useNavigate()

    const onShowProfileModal = useCallback((id: number) => {
        if (myId === id) {
            if (profileGroupId === UserGroup.SPEC || profileGroupId === UserGroup.SPEC_PREMIUM) {
                navigate(RoutePath.specialist)
            } else {
                navigate(RoutePath.profile)
            }
        } else {
            setProfileModalId(id.toString())
            setIsShowProfile(true)
        }
        setProfileModalId(id.toString())
        setIsShowProfile(true)
    }, [myId, navigate, profileGroupId])

    const onCloseProfile = useCallback(() => {
        setIsShowProfile(false)
        setProfileModalId(undefined)
    }, [])

    return useMemo(() => ({
        isShowProfile,
        profileModalId,
        onShowProfileModal,
        onCloseProfile
    }), [isShowProfile, onCloseProfile, onShowProfileModal, profileModalId])

}
