import {ITagsFilter} from "../../models/ITagsFilter";

export const tagsFiltersRU: ITagsFilter[] = [
    {
        tagTitleAddiction: "Зависимость",
        statusActive: false,
        isActiveSelectors: [],
        idNavTag: 'qwe',
        tagFilters: [
            {
                title: 'Наркомания', isActive: false, idTag: '1'
            },
            {
                title: 'Алкоголизм',
                isActive: false,
                idTag: '2'
            },
            {
                title: 'Лудомания', isActive: false, idTag: '3'
            },
            {
                title: 'Игровая зависимость',
                isActive: false,
                idTag: '4'
            },
            {
                title: 'Созависимость', isActive: false, idTag: '5'
            },
            // {
            //     title: 'Сексоголизм',
            //     isActive: false,
            //     idTag: '2-6'
            // }
        ]
    },
    {

        tagTitleAddiction: "Проблематика",
        statusActive: false,
        isActiveSelectors: [],
        idNavTag: 'ewq',
        tagFilters: [
            {title: 'Бессоница', isActive: false, idTag: '1'}, {
                title: 'Буллинг',
                isActive: false,
                idTag: '2'
            },
            {title: 'Депрессия', isActive: false, idTag: '3'}, {
                title: 'Клептомания',
                isActive: false,
                idTag: '4'
            },
            {title: 'Неуверенность', isActive: false, idTag: '5'}, {
                title: 'Обида',
                isActive: false,
                idTag: '6'
            },
            {title: 'Одиночество', isActive: false, idTag: '7'}, {
                title: 'ПТСР',
                isActive: false,
                idTag: '8'
            },
            {title: 'Панические атаки', isActive: false, idTag: '9'}, {
                title: 'РПП',
                isActive: false,
                idTag: '10'
            },
            {title: 'Тревога', isActive: false, idTag: '11'}, {
                title: 'Трудоголизм',
                isActive: false,
                idTag: '12'
            },
            {title: 'Утрата близкого', isActive: false, idTag: '13'}, {
                title: 'Фобии и аллергии',
                isActive: false,
                idTag: '14'
            },
            {title: 'Чувство страха', isActive: false, idTag: '15'}
        ]
    },

]
