import {createAsyncThunk} from '@reduxjs/toolkit';
import {ThunkConfig} from "../../../config/StateSchema";
import {BaseResponse} from "../../../../api/api";
import {snackbarAction} from "../../../errors/SnackbarSlice";


interface IResponse {
    idRelationship: number
    idUser?: number
}

export const deleteProfileRelationship = createAsyncThunk<true,
    IResponse,
    ThunkConfig<string>>(
        'profile/deleteProfileRelationship',
        async ({idRelationship}, thunkAPI) => {
            const {extra, rejectWithValue, dispatch} = thunkAPI;
            try {
                const response = await extra.api.delete<BaseResponse>(`profile/relationship/${idRelationship}`,)
                if (!response.data.success) {
                    return rejectWithValue('error')
                }
                return response.data.success
            } catch (err) {
                dispatch(snackbarAction.addError('INTERNAL_ERROR'))
                return rejectWithValue('error');
            }
        },
    );
