import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
    IAppointmentModelData,
    ICalendarResponse,
    ICollectionConflictDate,
    ICurrentViewName,
    IRoomScheduler,
    IScheduler
} from "./types/IScheduler";
import uuid from "react-uuid";
import {changeRulesHelper} from "../../../utils/helpers/changeRulesHelper";
import {IMember} from "../../../models/IChatItem";
import {
    fetchCalendarMyCollection
} from "./services/fetchCalendarMyCollection/fetchCalendarMyCollection";
import {
    convertToResponseToAppointmentModelData
} from "./services/convertToResponseToAppointmentModelData/convertToResponseToAppointmentModelData";
import {
    fetchCalendarCollectionByUserId
} from "./services/fetchCalendarCollectionByUserId/fetchCalendarCollectionByUserId";
import {createCalendar} from "./services/createCalendar/createCalendar";
import {checkConflictData} from "./services/checkConflictData/checkConflictData";


const initialState: IScheduler = {
    data: [],
    // resources: [
    //     {
    //         fieldName: 'roomId',
    //         title: 'Room',
    //         instances: resourcesData,
    //     },
    //     {
    //         fieldName: 'members',
    //         title: 'Members',
    //         instances: owners,
    //         allowMultiple: false,
    //     },
    // ],
    appointmentChanges: {},
    editingAppointment: null,
    addedAppointment: {},
    locale: 'en-US',
    draftData: {
        startDate: ''
    },
    isLoading: false,
    isLoadingCalendar: false,
    currentViewName: 'Week',
    currentDateChange: new Date(),
    isDisabledSave: true,
    isMounted: false,
}

export const schedulerSlice = createSlice({
    name: 'schedule',
    initialState,
    reducers: {
        addData(state, action: PayloadAction<IAppointmentModelData[]>) {
            state.data = action.payload
        },
        addNewData(state, action: PayloadAction<IAppointmentModelData>) {
            const startingAddedId = uuid();
            state.data = [
                ...state.data,
                {
                    ...action.payload,
                    id: startingAddedId,
                }
            ]
        },
        setError(state, action: PayloadAction<string>) {
            state.error = action.payload
        },
        setIsSpecialistCalendar(state, action: PayloadAction<boolean>) {
            state.isSpecialistCalendar = action.payload
        },
        setData(state, action: PayloadAction<{ [key: string]: any }>) {
            state.data = state.data.map(el => action.payload[el.id as string]
                ? {
                    ...el,
                    ...action.payload[el.id as string]
                }
                :
                el
            )
        },
        setUserId(state, action: PayloadAction<number>) {
            state.userId = action.payload
        },
        setIsMounted(state, action: PayloadAction<boolean>) {
            state.isMounted = action.payload
        },
        setIsDisabledSave(state, action: PayloadAction<boolean>) {
            state.isDisabledSave = action.payload
        },
        setRoomId(state, action: PayloadAction<IRoomScheduler>) {
            state.selectRoom = action.payload
        },
        setCurrentViewName(state, action: PayloadAction<ICurrentViewName>) {
            state.currentViewName = action.payload
        },
        setCurrentDateChange(state, action: PayloadAction<Date>) {
            state.currentDateChange = action.payload
        },
        setIsLoadingCalendar(state, action: PayloadAction<boolean>) {
            state.isLoadingCalendar = action.payload
        },
        setIsLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload
        },
        setConferenceMembers(state, action: PayloadAction<IMember[]>) {
            state.conferenceMembers = action.payload
        },
        addMemberInData(state, action: PayloadAction<{
            idSession: string | number,
            member: IMember
        }>) {
            state.data = state.data.map(el => el.id === action.payload.idSession
                ? {...el, member: action.payload.member}
                : el
            )
        },
        deleteMemberInData(state, action: PayloadAction<{ idSession: string | number }>) {
            state.data = state.data.map(el => el.id === action.payload.idSession
                ? {...el, member: undefined}
                : el
            )
        },
        addMembersInData(state, action: PayloadAction<{
            idSession: string | number,
            member: IMember
        }>) {
            state.data = state.data.map(el => {
                if (el.id === action.payload.idSession) {
                    if (el.members) {
                        return {
                            ...el,
                            members: [
                                ...el.members,
                                action.payload.member
                            ]
                        }
                    } else {
                        return {
                            ...el,
                            members: [
                                action.payload.member
                            ]
                        }
                    }
                } else {
                    return el
                }
            }
            )
        },
        deleteMembersInData(state, action: PayloadAction<{
            idSession: string | number,
            idMember: number
        }>) {
            state.data = state.data.map(el => el.id === action.payload.idSession
                ? {
                    ...el,
                    members: el.members?.filter(memb => memb.idMember !== action.payload.idMember)
                }
                : el
            )
        },
        deleteData(state, action: PayloadAction<number | string>) {
            state.data = state.data.filter(el => el.id !== action.payload)
        },
        setEditingAppointment(state, action: PayloadAction<Partial<IAppointmentModelData> | null>) {
            if (action.payload) {
                state.editingAppointment = {
                    ...state.editingAppointment,
                    ...action.payload
                }
            } else {
                state.editingAppointment = null
            }
        },
        setAppointmentChanges(state, action: PayloadAction<Partial<IAppointmentModelData>>) {
            state.appointmentChanges = {
                ...state.appointmentChanges,
                ...action.payload
            }
        },
        setRulesAppointmentChanges(state, action: PayloadAction<{ label: string, value: string }>) {
            let newRule = ''
            if (state.appointmentChanges?.rRule !== undefined) {
                newRule = changeRulesHelper(state.appointmentChanges.rRule, action.payload.label, action.payload.value)
            } else if (state.editingAppointment?.rRule !== undefined) {
                newRule = changeRulesHelper(state.editingAppointment.rRule, action.payload.label, action.payload.value)
            }
            state.appointmentChanges.rRule = newRule
        },
        setAddedAppointment(state, action: PayloadAction<Partial<IAppointmentModelData>>) {
            state.addedAppointment = {
                ...state.addedAppointment,
                ...action.payload
            }
        },
        setRulesAddedAppointment(state, action: PayloadAction<{ label: string, value: string }>) {
            if (state.addedAppointment?.rRule !== undefined) {
                const newRule = changeRulesHelper(state.addedAppointment.rRule, action.payload.label, action.payload.value)
                state.addedAppointment.rRule = newRule
            }
        },
        setZeroingAddedAppointment(state) {
            state.addedAppointment = {}
            state.appointmentChanges = {}
            state.editingAppointment = null
            state.collectionConflictDate = []
        },
        setZeroingData(state) {
            state.data = []
            state.addedAppointment = {}
            state.appointmentChanges = {}
            state.editingAppointment = null
            state.userId = undefined
            state.currentDateChange = undefined
            state.currentViewName = undefined
            state.isLoading = false
            state.isLoadingCalendar = false
            state.selectRoom = undefined
            state.conferenceMembers = undefined
            state.isMounted = false
        },
        setDraftData(state, action: PayloadAction<Partial<IAppointmentModelData>>) {
            state.draftData = {
                ...state.draftData,
                ...action.payload
            }
        },
        setLocale(state, action: PayloadAction<string>) {
            state.locale = action.payload
        },
    },
    extraReducers: (builder) => {
        builder

            //fetch my collection
            .addCase(fetchCalendarMyCollection.pending, (state) => {
                state.isLoading = true
                state.error = undefined
            })
            .addCase(fetchCalendarMyCollection.fulfilled, (state, action: PayloadAction<ICalendarResponse[]>) => {
                const calendarData = action.payload.map(el => convertToResponseToAppointmentModelData(el))
                // @ts-ignore
                state.data = calendarData.length > 0 ? calendarData : []
                state.isLoading = false
                state.collectionConflictDate = []
                state.isDisabledSave = true
            })
            .addCase(fetchCalendarMyCollection.rejected, (state, action) => {
                state.isLoading = false
                if (action.payload === 2102) {
                    state.error = 'conflict-data'
                } else if (action.payload === 2107) {
                    state.error = 'conflict-max-duration'
                } else {
                    state.error = 'Error'
                }
            })

            //fetch collections by userId
            .addCase(fetchCalendarCollectionByUserId.pending, (state) => {
                state.isLoading = true
                state.error = undefined
            })
            .addCase(fetchCalendarCollectionByUserId.fulfilled, (state, action: PayloadAction<ICalendarResponse[]>) => {
                if (state.selectRoom) {
                    const calendarData = action.payload?.filter(el => el.room?.id.toString() === state.selectRoom?.roomId.toString())?.map(el => convertToResponseToAppointmentModelData(el))
                    if (calendarData) {
                        // @ts-ignore
                        state.data = calendarData
                    } else {
                        state.data = []
                    }
                } else {
                    // @ts-ignore
                    state.data = action.payload.map(el => convertToResponseToAppointmentModelData(el))
                }
                state.isLoading = false
                state.error = undefined
                state.collectionConflictDate = []
                state.isDisabledSave = true
            })
            .addCase(fetchCalendarCollectionByUserId.rejected, (state, action) => {
                state.isLoading = false
                if (action.payload === 2102) {
                    state.error = 'conflict-data'
                } else if (action.payload === 2107) {
                    state.error = 'conflict-max-duration'
                } else {
                    state.error = 'Error'
                }
            })

            //fetch collections by userId
            .addCase(createCalendar.rejected, (state, action) => {
                state.isLoading = false
            })

            //checkConflictData
            .addCase(checkConflictData.pending, (state) => {
                state.isLoadingCalendar = true
                state.isDisabledSave = true
                state.error = undefined
            })
            .addCase(checkConflictData.fulfilled, (state, action: PayloadAction<ICollectionConflictDate[]>) => {
                state.collectionConflictDate = action.payload
                state.isDisabledSave = action.payload.length !== 0
                state.isLoadingCalendar = false
            })
            .addCase(checkConflictData.rejected, (state, action) => {
                state.isLoading = false
                if(!state.isMounted) {
                    state.isMounted = true
                }
            })
    }
})

export const schedulerReducer = schedulerSlice.reducer
export const schedulerAction = schedulerSlice.actions


