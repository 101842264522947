import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IAuthProfile} from "./types/IAuthProfile";
import {fetchAuthRegister} from "./services/fetchAuthRegister/fetchAuthRegister";
import {fetchAuthLogout} from "./services/fetchAuthLogout/fetchAuthLogout";
import {fetchAuthLogin} from "./services/fetchAuthLogin/fetchAuthLogin";


const initialState: IAuthProfile = {
    isMainLoading: true,
    isAuthentication: false,
    isNavbarLoading: false,
    isShowModalAuth: false
}

export const authSlice = createSlice({
    name: 'authSlice',
    initialState,
    reducers: {
        setIsAuthentication(state, action: PayloadAction<boolean>) {
            state.isAuthentication = action.payload
        },
        changeIsMainLoading(state, action: PayloadAction<boolean>) {
            state.isMainLoading = action.payload
        },
        changeIsNavbarLoading(state, action: PayloadAction<boolean>) {
            state.isNavbarLoading = action.payload
        },
        clearErrors(state) {
            state.error = undefined
            state.errorRegister = undefined
        },
        setIsShowModalAuth(state, action: PayloadAction<boolean>) {
            state.isShowModalAuth = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAuthRegister.pending, (state) => {
                state.isNavbarLoading = true
                state.error = undefined
                state.errorRegister = undefined
            })
            .addCase(fetchAuthRegister.fulfilled, (state, action: PayloadAction<boolean>) => {
                state.isNavbarLoading = false
                state.isAuthentication = action.payload
            })
            .addCase(fetchAuthRegister.rejected, (state, action) => {
                if (typeof action.payload === "object") {
                    state.errorRegister = action.payload
                } else {
                    state.error = action.error.message
                }
                state.isNavbarLoading = false
            })

            .addCase(fetchAuthLogout.fulfilled, (state, action: PayloadAction<boolean>) => {
                state.isNavbarLoading = false
                state.isAuthentication = action.payload
            })

            .addCase(fetchAuthLogin.pending, (state) => {
                state.isNavbarLoading = true
                state.error = undefined
            })
            .addCase(fetchAuthLogin.rejected, (state, action) => {
                state.error = action.payload
                state.isNavbarLoading = false
            })

    },
})


export const authReducer = authSlice.reducer
export const authAction = authSlice.actions


