import {createAsyncThunk} from '@reduxjs/toolkit';
import {ThunkConfig} from "../../../config/StateSchema";
import {fetchProfileSelf} from "../../../profile/services/fetchProfileSelf/fetchProfileSelf";
import {setToken} from "../tokenManagement/tokenManagement";
import {snackbarAction} from "../../../errors/SnackbarSlice";

interface ILoginResponse {
    email: string
    password: string
}

export const fetchAuthLogin = createAsyncThunk<void,
    ILoginResponse, ThunkConfig<string>>(
        'auth/fetchAuthLogin',
        async (authData, thunkAPI) => {
            const {extra, rejectWithValue, dispatch} = thunkAPI;
            try {
                const response = await extra.api.post(
                    `/auth/login`,
                    authData);
                if (!response.data.success) {
                    return rejectWithValue('Incorrect email or password')
                }
                if (response.data.response.access_token) {
                    await setToken(response.data.response.access_token)
                    dispatch(fetchProfileSelf(response.data.response.access_token))
                }
            } catch (err) {
                dispatch(snackbarAction.addError('INTERNAL_ERROR'))
                console.log('err', err)
                // @ts-ignore
                return rejectWithValue(err.data.error);
            }
        },
    );
