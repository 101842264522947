import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IMyPrivateChatsToken, ITwilioChats} from "./types/chatItemTypes";
import {Client, Conversation, Message} from "@twilio/conversations";
import {
    fetchMyPrivateChatsTokens
} from "./services/fetchMyPrivateChatsTokens/fetchMyPrivateChatsTokens";
import {IChatsCollection} from "../chats/types/chatsTypes";
import {fetchTwilioToken} from "./services/fetchTwilioToken/fetchTwilioToken";

const initialState: ITwilioChats = {}

export const twilioChatsSlice = createSlice({
    name: 'twilioChatsSlice',
    initialState,
    reducers: {
        setClient(state, action: PayloadAction<Client>) {
            // @ts-ignore
            state.client = action.payload
        },
        addNewMessage(state, action: PayloadAction<Message>) {
            if (state.messages && state.messages.length > 0) {
                state.messages = [...state.messages, action.payload]
            } else {
                state.messages = [action.payload]
            }
        },
        addMessages(state, action: PayloadAction<Message[]>) {
            state.messages = action.payload
        },
        setConversation(state, action: PayloadAction<Conversation | undefined>) {
            state.conversation = action.payload
        },
        setNewNumberOFMessage(state, action: PayloadAction<string>) {
            if (state.myPrivateChatsToken) {
                state.myPrivateChatsToken = state?.myPrivateChatsToken.map(el => el.token === action.payload
                    ? {
                        token: el.token,
                        numberOFNewMessages: el.numberOFNewMessages ? el.numberOFNewMessages + 1 : 1
                    }
                    : el)
            }
        },
        deleteNumberOFMessage(state, action: PayloadAction<string>) {
            if (state.myPrivateChatsToken) {
                state.myPrivateChatsToken = state?.myPrivateChatsToken.map(el => el.token === action.payload
                    ? {
                        token: el.token,
                        numberOFNewMessages: 0
                    }
                    : el)
            }
        },
        updateNewMessages(state, action: PayloadAction<IChatsCollection[]>) {
            let newPrivateTokens: IMyPrivateChatsToken[] = action.payload.map(el => ({
                token: el.twilio_id,
                numberOFNewMessages: 0
            }))
            newPrivateTokens = newPrivateTokens.map(el => {
                if (state.myPrivateChatsToken && state.myPrivateChatsToken.length > 0) {
                    const oldMessage = state.myPrivateChatsToken.find(ms => ms.token === el.token)
                    if (oldMessage) {
                        return {
                            token: el.token,
                            numberOFNewMessages: oldMessage.numberOFNewMessages
                        }
                    } else {
                        return el
                    }
                } else {
                    return el
                }
            })
            state.myPrivateChatsToken = newPrivateTokens
        },
        addMyPrivateChatsToken(state, action: PayloadAction<Message[]>) {
            if (state.messages && state.messages.length > 0) {
                // @ts-ignore
                state.messages = [...state.messages, ...action.payload]
            } else {
                // @ts-ignore
                state.messages = action.payload
            }
        },
        addOldMessages(state, action: PayloadAction<Message[]>) {
            if (state.messages && state.messages.length > 0) {
                // @ts-ignore
                state.messages = [...action.payload, ...state.messages]
            } else {
                // @ts-ignore
                state.messages = action.payload
            }
        },
        setActiveTwilioId(state, action: PayloadAction<string>) {
            state.activeTwilioToken = action.payload
        },
        setPersonalTwilioToken(state, action: PayloadAction<string>) {
            state.personalTwilioToken = action.payload
        },
        clearClient(state) {
            state.client = undefined
            state.messages = undefined
            // state.activeTwilioToken = undefined
            state.personalTwilioToken = undefined
            state.myPrivateChatsToken = undefined
        },
        clearMessages(state) {
            state.messages = undefined
            state.conversation = undefined
            state.activeTwilioToken = undefined
        }
    },

    extraReducers: (builder) => {
        builder

        //fetch my collections chats tokens
            .addCase(fetchMyPrivateChatsTokens.fulfilled, (state, action: PayloadAction<IChatsCollection[] | null>) => {
                if (action.payload && action.payload.length > 0) {
                    state.myPrivateChatsToken = action.payload.map(el => (
                        {
                            token: el.twilio_id,
                            numberOFNewMessages: 0
                        }
                    ))
                }
            })

        //fetch twilio token
            .addCase(fetchTwilioToken.fulfilled, (state, action: PayloadAction<string>) => {
                state.personalTwilioToken = action.payload
            })
    }
}
)


export const twilioChatsReducer = twilioChatsSlice.reducer
export const twilioChatsAction = twilioChatsSlice.actions


