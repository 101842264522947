import {memo, Suspense} from 'react';
import {Modal} from "../Modal";
import {LoaderModal} from "../../../ui/loaderModal/LoaderModal";
import { ProfileForm } from './ProfileForm';


interface InformationModalProps {
    isOpen: boolean
    id: string
    onClose?: () => void
    isMoreZIndex?: boolean
}

export const ProfileModal  = memo((props: InformationModalProps) => {
    const {
        isOpen,
        id,
        onClose,
        isMoreZIndex
    } = props
    return (
        <Modal
            isOpen={isOpen}
            lazy
            onClose={onClose}
            isMoreZIndex={isMoreZIndex}
            isCenter
            isCloseOnClick
        >
            <Suspense fallback={<LoaderModal/>}>
                <ProfileForm
                    id={id}
                    onClose={onClose}
                />
            </Suspense>
        </Modal>
    );
});
