import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ITagsFilter} from "../../../models/ITagsFilter";
import {tagsFiltersRU} from "../../../utils/data/tagsFilterDataRU";
import {ILanguage} from "../../../models/Llanguage";
import {tagsFiltersEN} from "../../../utils/data/tagsFilterDataEN";


const initialState: ITagsFilter[] = tagsFiltersRU

interface IAddTagsIsActive {
  idNavTag: string
  idTag: string
}


export const tagsFilterSlice = createSlice({
    name: 'tagsFilter',
    initialState,
    reducers: {

        addTagIsActive(state, action: PayloadAction<IAddTagsIsActive>) {
            state.forEach((el) => {
                if (el.idNavTag === action.payload.idNavTag) {
                    if (el.isActiveSelectors.some(tg => tg === action.payload.idTag)) {
                        el.isActiveSelectors = el.isActiveSelectors.filter(tg => tg !== action.payload.idTag)
                    } else {
                        el.isActiveSelectors.push(action.payload.idTag)
                    }

                }
            })
        },
        changeStatusActive(state, action: PayloadAction<{ idNavTag: string, statusActive: boolean }>) {
            state.forEach(el => el.statusActive = false)
            state.forEach(el => {
                if (el.idNavTag === action.payload.idNavTag) {
                    el.statusActive = action.payload.statusActive
                }
            })
        },
        changeLanguageTags(state, action: PayloadAction<ILanguage>) {
            if (action.payload.language === "ru") {
                return state = tagsFiltersRU
            } else {
                return state = tagsFiltersEN
            }
        },
    },
    extraReducers: () => {
    },
})


export const tagsFilterReducer = tagsFilterSlice.reducer
export const tagsFilterAction = tagsFilterSlice.actions


