import {memo} from 'react';
import s from './Tooltip.module.css'
import {Tooltip as ReactTooltip} from "react-tooltip";
import 'react-tooltip/dist/react-tooltip.css'

interface ITooltipBox {
    title?: string
    content: string
    anchorId?: string
    place?: 'top' | 'bottom' | 'right' | 'left'
    classTitle?: string
    classTooltip?: string
}

export const TooltipBox = memo((props: ITooltipBox) => {
    const {
        title,
        content,
        place = 'top',
        classTitle,
        classTooltip,
        anchorId
    } = props
    return (
        <div className={s.tooltip_box}>
            {title && <span id={title} className={classTitle ? classTitle : ''}>{title}</span>}
            <ReactTooltip
                anchorId={title ? title : anchorId}
                place={place}
                content={content}
                className={`${classTooltip ? classTooltip : ''} ${s.tooltip}`}
            />
        </div>

    );
});
