import {createAsyncThunk} from '@reduxjs/toolkit';
import {ThunkConfig} from "../../../../config/StateSchema";
import {IUserRelationship} from "../../../types/profileTypes";
import {BaseResponse} from "../../../../../api/api";
import {IChatsCollectionsQuery} from "../../../../chats/types/chatsTypes";
import {snackbarAction} from "../../../../errors/SnackbarSlice";

export interface IResponseUpdateProfile {
    collection: IUserRelationship[]
}

export interface IResponseCollectionFriends extends Omit<IChatsCollectionsQuery, 'sort_members' & 'is_private' & 'addictions_ids[]' & 'problems_ids[]'> {
    status?: 0 | 1
}

export const fetchProfileFriends = createAsyncThunk<IUserRelationship[],
    IResponseCollectionFriends | void,
    ThunkConfig<string>>(
        'profile/fetchProfileFriends',
        async (data, thunkAPI) => {
            const {extra, rejectWithValue, dispatch} = thunkAPI;
            try {
                const response = await extra.api.get<BaseResponse<IResponseUpdateProfile>>('relationship/friend', {
                    params:data
                })
                if (!response.data.success) {
                    return rejectWithValue('error')
                }

                return response.data.response.collection
            } catch (err) {
                dispatch(snackbarAction.addError('INTERNAL_ERROR'))
                return rejectWithValue('error');
            }
        }
    );
