import {createAsyncThunk} from '@reduxjs/toolkit';
import {ThunkConfig} from "../../../config/StateSchema";
import {BaseResponse} from "../../../../api/api";
import {IChatItem} from "../../types/chatItemTypes";
import {snackbarAction} from "../../../errors/SnackbarSlice";
import {getProfileId} from "../../../profile/selectors/getProfile/getProfile";
import {chatItemAction} from "../../chatItemSlice";

interface IResponse {
    info: IChatItem
}

export const fetchChatById = createAsyncThunk<IChatItem,
    string,
    ThunkConfig<string>>(
        'chatItem/fetchChatById',
        async (id, thunkAPI) => {
            const {extra, rejectWithValue, dispatch, getState} = thunkAPI;
            try {
                const response = await extra.api.get<BaseResponse<IResponse>>(`chat/${id}`, )

                if (!response.data.success) {
                    return rejectWithValue('error')
                }

                const myId = getProfileId(getState())
                const chat =response.data.response.info
                if(chat.created_by.id !== myId) {
                    const users = chat.users
                    const isShowRules = users?.find(us => us.id === myId)?.is_rules_accepted
                    if(isShowRules === 0) {
                        dispatch(chatItemAction.setIsShowRules(true))
                    }

                }
                return  chat
            } catch (err) {
                dispatch(snackbarAction.addError('INTERNAL_ERROR'))
                return rejectWithValue('error');
            }
        },
    );
