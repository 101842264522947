import {createAsyncThunk} from '@reduxjs/toolkit';
import {BaseResponse} from "../../../../api/api";
import {ThunkConfig} from "../../../config/StateSchema";
import {snackbarAction} from "../../../errors/SnackbarSlice";
import {ICalendarResponse} from '../../../scheduler/types/IScheduler';
import moment from "moment";

export interface IResponseCalendarData {
    collection: ICalendarResponse[]
}

export const fetchProfileEventsMyCollection = createAsyncThunk<ICalendarResponse[],
    void,
    ThunkConfig<string>>(
        'profile/fetchProfileEventsMyCollection',
        async (_, thunkAPI) => {
            const {extra, rejectWithValue, dispatch} = thunkAPI;
            try {
                const from = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
                const to = moment(new Date()).add(30, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss');

                const response = await extra.api.get<BaseResponse<IResponseCalendarData>>(`schedule/my`, {
                    params: {
                        from, to
                    }
                })
                if (!response.data.success) {
                    return rejectWithValue(response.data.codeKey)
                }
                return response.data.response.collection
            } catch (err) {
                dispatch(snackbarAction.addError('INTERNAL_ERROR'))
                // @ts-ignore
                return rejectWithValue(err);
            }
        },
    );

// const formattedDate = moment(date).format('YYYY-MM-DD');
