import {removeTokenInterceptors, tokenInterceptors} from "./tokenInterceptors"
import {removeCookieToken, setCookieToken} from "./cookieToken";

export const setToken = async (token: string) => {
    try {
        await setCookieToken(token)
        await tokenInterceptors(token)
    } catch (err) {
        console.log('error')
    }
}

export const removeToken = async () => {
    try {
        await removeCookieToken()
        await removeTokenInterceptors()
        return true;
    } catch (err) {
        console.log('removeToken error: ', err);
        return false;
    }
};
