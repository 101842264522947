import {useCallback} from "react";
import {useNavigate} from "react-router-dom";

export const useNavigateToTop = (replace: boolean) => {
    const navigate = useNavigate();

    const navigateAndReset = useCallback((to: string) => {
        window.scrollTo(0, 0);
        navigate(to, {
            replace
        });
    }, [navigate, replace]);

    return navigateAndReset;
};
