import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ITwilioCalls} from "./types/ICalls";
import {
    fetchTwilioCallToken
} from "./services/startCalls/fetchTwilioCallToken/fetchTwilioCallToken";
import {
    fetchTwilioCallSchedule
} from "./services/startCalls/fetchTwilioCallSchedule/fetchTwilioCallSchedule";
import {ICalendarResponse} from "../scheduler/types/IScheduler";

const initialState: ITwilioCalls = {
    isLoading: true,
    isMounted: false
}

export const twilioCallsSlice = createSlice({
    name: 'twilioCalls',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload
        },
        setIsMounted(state, action: PayloadAction<boolean>) {
            state.isMounted = action.payload
        },
        resetState(state) {
            state.isLoading = true
            state.error = undefined
            state.isMounted = false
        },
    },
    extraReducers: (builder) => {
        builder
            // .addCase(fetchTwilioCallToken.pending, (state) => {
            //
            // })
            .addCase(fetchTwilioCallToken.fulfilled, (state, action: PayloadAction<string>) => {
                state.token = action.payload;
                if (state.token) {
                    state.error = undefined
                }
            })
            .addCase(fetchTwilioCallToken.rejected, (state, action) => {
                state.error = action.payload;
            })

            .addCase(fetchTwilioCallSchedule.pending, (state) => {
                state.error = undefined;
            })
            .addCase(fetchTwilioCallSchedule.fulfilled, (state, action: PayloadAction<ICalendarResponse>) => {
                state.currentScheduler = action.payload;
            })
            .addCase(fetchTwilioCallSchedule.rejected, (state, action) => {
                state.error = action.payload;
            });
    },
})


export const twilioCallsReducer = twilioCallsSlice.reducer
export const twilioCallsAction = twilioCallsSlice.actions
