import {createAsyncThunk} from '@reduxjs/toolkit';
import {ThunkConfig} from "../../../../config/StateSchema";
import {IUserRelationship} from "../../../types/profileTypes";
import {BaseResponse} from "../../../../../api/api";
import { IResponseCollectionFriends } from '../fetchProfileFriends/fetchProfileFriends';
import {snackbarAction} from "../../../../errors/SnackbarSlice";

export interface IResponseUpdateProfile {
    collection: IUserRelationship[]
}


export const fetchProfileFriendsByOffset = createAsyncThunk<IUserRelationship[],
    IResponseCollectionFriends | void,
    ThunkConfig<string>>(
        'profile/fetchProfileFriendsByOffset',
        async (data, thunkAPI) => {
            const {extra, rejectWithValue, dispatch} = thunkAPI;
            try {
                const response = await extra.api.get<BaseResponse<IResponseUpdateProfile>>('relationship/friend', {
                    params: data
                })
                if (!response.data.success) {
                    return rejectWithValue('error')
                }

                return response.data.response.collection
            } catch (err) {
                dispatch(snackbarAction.addError('INTERNAL_ERROR'))
                return rejectWithValue('error');
            }
        }
    );
