import {RootState} from "../../../store";

export const getNotificationCollection = (state: RootState) => state.notificationsReducer.notifications;
export const getNotificationPrivateChat = (state: RootState) => state.notificationsReducer.notificationPersonalChat;
export const getNumberOfNotifications = (state: RootState) => state.notificationsReducer.numbOfNotifications;
export const getNotificationsLastId= (state: RootState) => state.notificationsReducer.last_id;
export const getNotificationsStart= (state: RootState) => state.notificationsReducer.notificationStart;
export const getNotificationsIsLoading= (state: RootState) => state.notificationsReducer.isLoading;
export const getNotificationsIsNotificationIsLoading= (state: RootState) => state.notificationsReducer.notificationIsLoading;


