import {createAsyncThunk} from '@reduxjs/toolkit';
import {ThunkConfig} from "../../../../config/StateSchema";
import {BaseResponse} from "../../../../../api/api";
import {profileInfoAction} from "../../../../profileInfo/profileInfoSlice";
import {Relationship} from "../../../types/profileTypes";
import {snackbarAction} from "../../../../errors/SnackbarSlice";

interface IResponse {
    idRelationship: number
}

export const acceptProfileFriend = createAsyncThunk<boolean,
    IResponse,
    ThunkConfig<string>>(
        'profile/acceptProfileFriend',
        async ({idRelationship}, thunkAPI) => {
            const {extra, rejectWithValue, dispatch} = thunkAPI;
            try {
                const response = await extra.api.post<BaseResponse>(`relationship/friend/${idRelationship}/accept`,)
                if (!response.data.success) {
                    return rejectWithValue('error')
                }
                dispatch(profileInfoAction.setRelationCurrentUser({
                    user_id: 1,
                    status: 1,
                    created_by_user_id: 1,
                    type: Relationship.FRIENDSHIP,
                    id: 10
                }))
                return response.data.success
            } catch (err) {
                dispatch(snackbarAction.addError('INTERNAL_ERROR'))
                return rejectWithValue('error');
            }
        },
    );
