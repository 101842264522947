import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {fetchProfileInfoById} from "./services/fetchProfileInfoById/fetchProfileInfoById";
import {IProfileSpec, IProfileUser, IRelationCurrentUser} from "../profile/types/profileTypes";
import {IProfileInitialState} from "./types/profileInfoTypes";


const initialState: IProfileInitialState = {
    error: undefined,
    isLoading: true,
}

export const profileInfoSlice = createSlice({
    name: 'profileInfo',
    initialState,
    reducers: {
        setRelationCurrentUser(state, action: PayloadAction<IRelationCurrentUser | undefined>) {
            if (state.profileInfo) {
                state.profileInfo.relation_current_user = action.payload ? [action.payload] : undefined
            }
        },
        setIsFromSpecialistPage(state, action: PayloadAction<boolean | undefined>) {
            state.isFromSpecialistPage = action.payload
        },
        clearProfileInfo() {
            return {
                error: undefined,
                isLoading: true,
                profileInfo: undefined,
                isFromSpecialistPage: undefined
            }
        },
    },
    extraReducers: (builder) => {
        builder

            //get info profile by id
            .addCase(fetchProfileInfoById.pending, (state) => {
                state.isLoading = true
                state.error = undefined
            })
            .addCase(fetchProfileInfoById.fulfilled, (state, action: PayloadAction<IProfileSpec | IProfileUser>) => {
                state.profileInfo = action.payload
                state.profileInfo.avatar = action.payload.avatar
                state.isLoading = false
            })
            .addCase(fetchProfileInfoById.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.payload
            })

        // .addCase(fetchProfileInfoByIdToNavigate.pending, (state) => {
        //     state.isLoading = true
        //     state.error = undefined
        // })
        // .addCase(fetchProfileInfoByIdToNavigate.fulfilled, (state) => {
        //     state.isLoading = false
        // })
        // .addCase(fetchProfileInfoByIdToNavigate.rejected, (state, action) => {
        //     state.isLoading = false
        //     state.error = action.payload
        // })


    },
})


export const profileInfoReducer = profileInfoSlice.reducer
export const profileInfoAction = profileInfoSlice.actions
