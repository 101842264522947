import {createAsyncThunk} from '@reduxjs/toolkit';
import {ThunkConfig} from "../../../../config/StateSchema";
import {BaseResponse} from "../../../../../api/api";
import {IChatsCollection} from "../../../types/chatsTypes";
import {chatsAction} from "../../../chatsSlice";
import {createPersonalChat} from '../createPersonalChat/createPersonalChat';
import {navigatePersonalChat} from "../navigatePersonalChat";
import {snackbarAction} from "../../../../errors/SnackbarSlice";

interface IResponse {
    info: IChatsCollection
}

export interface IParamsCreatePersonalChat {
    idUser: number
    navigate: (to: string) => void
}

export const fetchChatsPersonalChat = createAsyncThunk<void,
    IParamsCreatePersonalChat,
    ThunkConfig<string>>(
        'chats/fetchChatsPersonalChat',
        async ({idUser, navigate}, thunkAPI) => {
            const {extra, rejectWithValue, dispatch} = thunkAPI;
            dispatch(chatsAction.setIsLoadingChat(true))
            try {
                const response = await extra.api.get<BaseResponse<IResponse>>(`chat/user/${idUser}/personal-chat`)

                if (!response.data.success) {
                    await dispatch(createPersonalChat({
                        idUser,
                        navigate
                    }))
                }

                if (response.data.success && response.data.response.info.twilio_id) {
                    dispatch(navigatePersonalChat({
                        twilio_id: response.data.response.info.twilio_id,
                        navigate
                    }))
                }

            } catch (err) {
                dispatch(snackbarAction.addError('INTERNAL_ERROR'))
                return rejectWithValue('error');
            } finally {
                dispatch(chatsAction.setIsLoadingChat(false))
            }
        },
    );
