import Cookies from 'universal-cookie';

export const cookie_key = 'Authorization_token'

const cookies = new Cookies();


export const setCookieToken = async (token: string) => {
    cookies.set(cookie_key, token, {path: '/'});
}

export const removeCookieToken = async () => {
    cookies.remove(cookie_key);
}

export const getCookieToken = async () => {
    return cookies.get(cookie_key);
}
