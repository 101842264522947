import {createAsyncThunk} from '@reduxjs/toolkit';
import {ThunkConfig} from "../../../../config/StateSchema";
import {BaseResponse} from "../../../../../api/api";

interface IResponse {
    access_token: string
}

export const fetchTwilioCallToken = createAsyncThunk<
    string,
    string,
    ThunkConfig<string>
>(
    'twilioCalls/fetchTwilioCallToken',
    async (scheduleId, thunkAPI) => {
        const {extra, rejectWithValue} = thunkAPI;
        try {
            const response = await extra.api.get<BaseResponse<IResponse>>(`schedule/${scheduleId}/conference/join`)
            if (!response.data.success) {
                return rejectWithValue(response.data.codeKey)
            }

            return response.data.response.access_token
        } catch (err) {
            // dispatch(snackbarAction.addError('INTERNAL_ERROR'))
            return rejectWithValue("NOT_FOUND");
        }
    },
);
