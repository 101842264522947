import {createAsyncThunk} from '@reduxjs/toolkit';
import {ThunkConfig} from '../../../config/StateSchema';
import {snackbarAction} from '../../../errors/SnackbarSlice';
import {BaseResponse} from "../../../../api/api";
import {INotification} from "../../types/notificationsTypes";

interface IResponse {
    collection: INotification[]
}

interface IPayload {
    last_id?: number
}

export const fetchNotificationsCollection = createAsyncThunk<
    INotification[],
    IPayload,
    ThunkConfig<string>
>(
    'notifications/fetchNotificationsCollection',
    async ({last_id}, thunkAPI) => {
        const {extra, rejectWithValue, dispatch} = thunkAPI;

        try {
            const response = await extra.api.get<BaseResponse<IResponse>>(
                `notification/collection`, {
                    params: {
                        last_id
                    }
                });
            if (!response.data.success) {
                return rejectWithValue('error')
            }
            response.data.response.collection.forEach(el => {
                if(el.event === 'SCHEDULE_START'  && !window.location.href.includes('calls')) {
                    dispatch(snackbarAction.addNewNotification(el))
                }
            })
            return response.data.response.collection

        } catch (err) {
            dispatch(snackbarAction.addError('INTERNAL_ERROR'))
            console.log('err', err)
            // @ts-ignore
            return rejectWithValue(err.data.error);
        }
    },
);
