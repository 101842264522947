import {memo, useCallback} from 'react';
import s from './headerLogo.module.css'
import {ReactComponent as Logo} from '../../../utils/images/newLogo/logo.svg'
import {NavLink} from "react-router-dom";
import {goToTop} from "../../../utils/helpers/goToTop";

type HeaderLogoProps = {
  className?: string
}

export const HeaderLogo= memo(({className}: HeaderLogoProps) => {
    const onClickHandler = useCallback(() => {
        goToTop('auto')
    }, [])
    return (
        <NavLink
            to={'/'}
            onClick={onClickHandler}
            className={`${s.header__logoLink} ${className ? className : ''}`}
        >
            <Logo/>
        </NavLink>
    );
});
