export const changeRulesHelper = (startRule: string, label: string, value: string) => {
    let isChange = true
    let arrRule = startRule.split(';')
    if (value === 'DAILY') {
        arrRule = arrRule.map(el => {
            if (el.includes('BYDAY=')) {
                return ''
            } else {
                return el
            }
        })
    }
    if (value === 'never') {
        arrRule = arrRule.map(el => {
            if (el.includes('COUNT=') || el.includes('UNTIL=')) {
                return ''
            } else {
                return el
            }
        })
    }
    if (label === 'COUNT=') {
        arrRule = arrRule.map(el => {
            if (el.includes('UNTIL=')) {
                return ''
            } else {
                return el
            }
        })
    } else if (label === 'UNTIL=') {
        arrRule = arrRule.map(el => {
            if (el.includes('COUNT=')) {
                return ''
            } else {
                return el
            }
        })
    } else {
        arrRule = arrRule.map(el => {
            if (el.includes(label)) {
                if (value === '') {
                    return ''
                }
                const number = el.indexOf(label);
                let newStr = `${label}${value}`
                if (number !== -1) {
                    newStr = `${el.substring(0, Number(number))}${label}${value}`
                }
                isChange = false
                return newStr
            } else {
                return el
            }
        })
    }
    if (isChange && value !== 'never' && value !== '') {
        arrRule.push(`${label}${value}`)
    }
    return arrRule.filter(el => el !== '').join(';')
}
