import s from "./ProfileForm.module.css";
import {CustomButton} from "../../../ui/Button/CustomButton";
import {useTranslation} from "react-i18next";
import {RoutePath} from "../../../router/routerConfig/routerConfig";
import {useNavigateToTop} from "../../../hooks/usenavigateToTop";
import {SpecialistInfo} from "../../profileInfo/SpecialistInfo";
import {SpecialistPrice} from "../../../pages/SpecialistProfile/SpecialistPrice/SpecialistPrice";
import {useAppDispatch, useAppSelector} from "../../../store/hooks/redux";
import {
    getProfileInfoIsLoading
} from "../../../store/reducers/profileInfo/selectors/getProfileInfoIsLoading/getProfileInfoIsLoading";
import {
    getProfileInfoById
} from "../../../store/reducers/profileInfo/selectors/getProfileInfoById/getProfileInfoById";
import {
    getConstantsAddictions
} from "../../../store/reducers/constants/selectors/getConstantsAddictions/getConstantsAddictions";
import {
    getConstantsProblems
} from "../../../store/reducers/constants/selectors/getConstantsProblems/getConstantsProblems";
import {useReturnDependencies} from "../../../utils/helpers/useReturnDependencies";
import {LoaderModal} from "../../../ui/loaderModal/LoaderModal";
import {
    IProfileSpec,
    IProfileUser,
    Relationship,
    UserGroup,
} from "../../../store/reducers/profile/types/profileTypes";
import {memo, useCallback, useEffect, useState} from "react";
import {
    fetchProfileInfoById
} from "../../../store/reducers/profileInfo/services/fetchProfileInfoById/fetchProfileInfoById";
import {profileInfoAction} from "../../../store/reducers/profileInfo/profileInfoSlice";
import {UserMood} from "../../userMood/UserMood";
import {UserInfo} from "../../profileInfo/UserInfo";
import defAvatar from '../../../utils/images/avatars/01.svg'
import {
    fetchChatsPersonalChat
} from "../../../store/reducers/chats/services/personalChat/fetchPersonalChat/fetchPersonalChat";
import {
    getChatsIsLoadingChat
} from "../../../store/reducers/chats/selectors/getChatsIsLoadingChat/getChatsIsLoadingChat";
import {
    declineProfileFriend
} from "../../../store/reducers/profile/services/friend/declineProfileFriend/declineProfileFriend";
import {
    addProfileFriend
} from "../../../store/reducers/profile/services/friend/addProfileFriend/addProfileFriend";
import {useRenderMyRelationship} from "../../../hooks/useRenderMyRelationship";
import {getProfileId} from "../../../store/reducers/profile/selectors/getProfile/getProfile";
import {
    acceptProfileFriend
} from "../../../store/reducers/profile/services/friend/acceptProfileFriend/acceptProfileFriend";
import {
    deleteProfileBlackList
} from "../../../store/reducers/profile/services/blackList/deleteProfileBlackList/deleteProfileBlackList";
import {Text} from "../../../ui/Text/Text";
import {classNames} from "../../../utils/helpers/classNames/classNames";
import {getAuthIsAuthentication} from "../../../store/reducers/auth/selectors/getAuth/getAuth";

type SpecialistFormProps = {
    id: string;
    onClose?: () => void
};

export const ProfileForm = memo((props: SpecialistFormProps) => {
    const {
        id,
        onClose
    } = props
    const isLoading = useAppSelector(getProfileInfoIsLoading)
    const isLoadingChat = useAppSelector(getChatsIsLoadingChat)
    const profileInfo = useAppSelector(getProfileInfoById)
    const myId = useAppSelector(getProfileId)
    const isAuth = useAppSelector(getAuthIsAuthentication)

    //Modal
    const [isShowModalDelete, setIsShowModalDelete] = useState(false)
    const [isShowModalBlock, setIsShowModalBlock] = useState(false)
    const [isShowModalUnblock, setIsShowModalUnblock] = useState(false)

    const addictionsData = useAppSelector(getConstantsAddictions)
    const problemsData = useAppSelector(getConstantsProblems)

    const navigate = useNavigateToTop(false)

    const dispatch = useAppDispatch()

    const addictions = useReturnDependencies(addictionsData.collection, profileInfo?.addictions_ids)
    const problems = useReturnDependencies(problemsData.collection, profileInfo?.problems_ids)

    const navigateToTop = useNavigateToTop(false)
    const {t} = useTranslation(['modal', 'profile', 'common'])

    const isSpecialist = (profileInfo?.user_group_id === UserGroup.SPEC || profileInfo?.user_group_id === UserGroup.SPEC_PREMIUM)

    const navigateToProfile = useCallback(() => {
        if (isSpecialist) {
            navigateToTop(`${RoutePath.specialist_profile}${id}`)
        } else {
            navigateToTop(`${RoutePath.profile_user}${id}`)
        }
        onClose?.()
    }, [id, isSpecialist, navigateToTop, onClose])

    const onWriteMessage = useCallback(async () => {
        if (Number(id)) {
            await dispatch(fetchChatsPersonalChat({
                idUser: Number(id),
                navigate
            }))
            onClose?.()
        }

    }, [dispatch, id, navigate, onClose])


    useEffect(() => {
        dispatch(fetchProfileInfoById(id.toString()))
        return () => {
            dispatch(profileInfoAction.clearProfileInfo())
        }
        // eslint-disable-next-line
    }, [])

    const shouldRenderIsDisabled = isLoading || isLoadingChat

    const relationUser =  profileInfo?.relation_current_user?.filter(rl => rl.type !== Relationship.CLIENT)?.[0]

    const {
        shouldRenderRequest,
        shouldRenderMyFriends,
        shouldRenderClient,
        shouldRenderRequestFromUser,
        shouldRenderAddFriends,
        shouldRenderMyBlock,
        shouldRenderBlockByUser
    } = useRenderMyRelationship(myId!, relationUser)

    // add friend
    const onAddInFriendHandler = useCallback(() => {
        if (profileInfo?.id) {
            dispatch(addProfileFriend({
                user_id: profileInfo?.id
            }))
        }
    }, [dispatch, profileInfo?.id])

    // modal delete friend
    const onCloseModalDeleteFriend = useCallback(() => {
        setIsShowModalDelete(false)
    }, [])

    const onDeleteFriendHandler = useCallback(() => {
        if (relationUser) {
            dispatch(declineProfileFriend({idRelationship: relationUser.id}))
        }
    }, [dispatch, relationUser])

    const onClickModalDeleteFriendOk = useCallback(() => {
        if (relationUser) {
            dispatch(declineProfileFriend({idRelationship: relationUser.id}))
        }
        onCloseModalDeleteFriend()
    }, [dispatch, onCloseModalDeleteFriend, relationUser])


    //friends requests
    const onRequestAddFriend = useCallback(() => {
        if (relationUser) {
            dispatch(acceptProfileFriend({
                idRelationship: relationUser?.id
            }))
        }
    }, [dispatch, relationUser])

    const onRequestsDeleteFriend = useCallback(() => {
        if (relationUser && Number(id)) {
            dispatch(declineProfileFriend(
                {
                    idRelationship: relationUser?.id,
                    idUser: Number(id)
                }
            ))
        }

    }, [dispatch, id, relationUser])

    const onClickModalDeleteFriendCancel = useCallback(() => {
        onCloseModalDeleteFriend()
    }, [onCloseModalDeleteFriend])

    const textDeleteFriend = `${t('modal:btn.deleteText')} ${profileInfo?.nickname} ${t('modal:btn.deleteTextFormFriends')}`
    const textDeleteFriendBtn = t('common:miniMenu.deleteFriend')


    //unblock user
    const textUnlockUser = `${t('modal:btn.unblockText')} ${profileInfo?.nickname}?`
    const onCloseModalUnblockBlock = useCallback(() => {
        setIsShowModalUnblock(false)
    }, [])


    const onCloseModalBlock = useCallback(() => {
        setIsShowModalBlock(false)
    }, [])

    const onClickModalUnblockUserOk = useCallback(() => {
        if (relationUser?.id) {
            dispatch(deleteProfileBlackList({idRelationship: relationUser?.id}))
        }
    }, [dispatch, relationUser?.id])


    const onClickModalUnblockUserCancel = useCallback(() => {
        onCloseModalUnblockBlock()
    }, [onCloseModalUnblockBlock])

    if (isLoading) {
        return <LoaderModal/>
    }

    if (profileInfo === undefined) {
        return <div
            style={{marginTop: `200px`, marginLeft: '40px', marginRight: 'auto', fontSize: '72px'}}>
            User not found (404)
        </div>
    }

    if (isSpecialist) {
        const {
            avatar,
            nickname,
            about_text,
            location,
            experience_text,
            cost_session_single,
            name
        } = profileInfo as IProfileSpec


        return (
            <div className={'modal__content'}>
                <div className={'modal-body'}>
                    <div className={'w100'}>
                        <div className={s.user__contentProfile}>
                            <div className={s.user__avatarPlace}>
                                <img src={avatar ? avatar : defAvatar} alt="avatar"/>

                                <div className={s.profile__userBtns}>
                                    {cost_session_single && (Number(cost_session_single) > 0) ?
                                        <SpecialistPrice
                                            price={cost_session_single}
                                            variant={"center"}
                                            className={s.price}
                                        />
                                        : null}
                                    {isAuth && <>
                                        {/*Добавить в друзья*/}
                                        {(shouldRenderAddFriends || shouldRenderClient)
                                            && <CustomButton
                                                onClick={onAddInFriendHandler}
                                                disabled={shouldRenderIsDisabled}
                                                variant={'light'}

                                            >
                                                {t('modal:information.btn.addFriend')}
                                            </CustomButton>}

                                        {/*Друг*/}
                                        {shouldRenderMyFriends
                                            && <>
                                                <CustomButton
                                                    onClick={onDeleteFriendHandler}
                                                    disabled={shouldRenderIsDisabled}
                                                    variant={'light'}
                                                >
                                                    {textDeleteFriendBtn}
                                                </CustomButton>

                                                <CustomButton
                                                    onClick={onWriteMessage}
                                                    variant={'pink'}
                                                    disabled={shouldRenderIsDisabled}
                                                >
                                                    {t('modal:information.btn.writeMessage')}
                                                </CustomButton>
                                            </>}

                                        {/*Запрос в друзья отправлен*/}
                                        {shouldRenderRequest
                                            && <CustomButton
                                                variant={'light'}
                                                onClick={onRequestsDeleteFriend}
                                            >
                                                {t('common:miniMenu.deleteRequest')}
                                            </CustomButton>
                                        }

                                        {/*Запрос в друзья от пользователя (принять или отклонить)*/}
                                        {shouldRenderRequestFromUser
                                            && <div className={s.requestsBtns}>
                                                <CustomButton
                                                    onClick={onRequestAddFriend}
                                                >
                                                    {t('profile:profileFriends.btn.addInFriend')}
                                                </CustomButton>
                                                <CustomButton
                                                    variant={'light'}
                                                    onClick={onRequestsDeleteFriend}
                                                >
                                                    {t('profile:profileFriends.btn.deleteFriend')}
                                                </CustomButton>
                                            </div>}


                                        {/*Пользователь заблокирован мной*/}
                                        {shouldRenderMyBlock
                                            && <div className={s.requestsBtns}>
                                                <CustomButton
                                                    variant={'light'}
                                                    onClick={onClickModalUnblockUserOk}
                                                >
                                                    {t('modal:btn.unblock')}
                                                </CustomButton>
                                            </div>}

                                        {/*Пользователь заблокировал меня*/}
                                        {shouldRenderBlockByUser
                                            && <Text
                                                text={t('modal:btn.blockByUser') || 'The user has blocked you'}
                                                align={'center'}
                                                theme={'error'}
                                            />}

                                    </>}

                                    {/*Не отображается возможность перейти на профиль при блоке*/}
                                    {(!shouldRenderBlockByUser && !shouldRenderMyBlock)
                                        && <CustomButton
                                            disabled={shouldRenderIsDisabled}
                                            onClick={navigateToProfile}
                                        >
                                            {t('modal:information.btn.goToProfile')}
                                        </CustomButton>}
                                </div>
                            </div>

                            <div className={classNames('user__data-place', {}, [s.hidden])}>

                                <div className={'user__title'}>
                                    <div className={classNames('user__title-box', {}, [])}>
                                        <h2>{name ? name : nickname}</h2>
                                    </div>
                                </div>

                                <SpecialistInfo
                                    location={location}
                                    addictions={addictions}
                                    about_text={about_text}
                                    experience_text={experience_text}
                                />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }


    const {
        avatar,
        nickname,
        about_text,
        location,
    } = profileInfo as IProfileUser

    return <div className={'modal__content'}>
        <div className={'modal-body'}>
            <div className={'w100'}>
                <div className={s.user__contentProfile}>
                    <div className={s.user__avatarPlace}>
                        <img src={avatar ? avatar : defAvatar} alt="avatar"/>

                        <div className={s.profile__userBtns}>
                            {isAuth && <>

                                {/*Добавить в друзья*/}
                                {(shouldRenderAddFriends || shouldRenderClient)
                                    && <CustomButton
                                        onClick={onAddInFriendHandler}
                                        disabled={shouldRenderIsDisabled}
                                        variant={'light'}

                                    >
                                        {t('modal:information.btn.addFriend')}
                                    </CustomButton>}


                                {/*Друг*/}
                                {shouldRenderMyFriends
                                    && <>
                                        <CustomButton
                                            onClick={onDeleteFriendHandler}
                                            disabled={shouldRenderIsDisabled}
                                            variant={'light'}
                                        >
                                            {textDeleteFriendBtn}
                                        </CustomButton>

                                        <CustomButton
                                            onClick={onWriteMessage}
                                            variant={'pink'}
                                            disabled={shouldRenderIsDisabled}
                                        >
                                            {t('modal:information.btn.writeMessage')}
                                        </CustomButton>
                                    </>}

                                {/*Запрос в друзья отправлен*/}
                                {shouldRenderRequest
                                    && <CustomButton
                                        variant={'light'}
                                        onClick={onRequestsDeleteFriend}
                                    >
                                        {t('common:miniMenu.deleteRequest')}
                                    </CustomButton>
                                }

                                {/*Запрос в друзья от пользователя (принять или отклонить)*/}
                                {shouldRenderRequestFromUser
                                    && <div className={s.requestsBtns}>
                                        <CustomButton
                                            onClick={onRequestAddFriend}
                                        >
                                            {t('profile:profileFriends.btn.addInFriend')}
                                        </CustomButton>
                                        <CustomButton
                                            variant={'light'}
                                            onClick={onRequestsDeleteFriend}
                                        >
                                            {t('profile:profileFriends.btn.deleteFriend')}
                                        </CustomButton>
                                    </div>}

                                {/*Пользователь заблокирован мной*/}
                                {shouldRenderMyBlock
                                    && <div className={s.requestsBtns}>
                                        <CustomButton
                                            variant={'light'}
                                            onClick={onClickModalUnblockUserOk}
                                        >
                                            {t('modal:btn.unblock')}
                                        </CustomButton>
                                    </div>}

                                {/*Пользователь заблокировал меня*/}
                                {shouldRenderBlockByUser
                                    && <Text
                                        text={t('modal:btn.blockByUser') || 'The user has blocked you'}
                                        align={'center'}
                                        theme={'error'}
                                    />}

                            </>}

                            {/*Не отображается возможность написать сообщение при блоке*/}
                            {(!shouldRenderBlockByUser && !shouldRenderMyBlock)
                                && <CustomButton
                                    disabled={shouldRenderIsDisabled}
                                    onClick={navigateToProfile}
                                >
                                    {t('modal:information.btn.goToProfile')}
                                </CustomButton>}
                        </div>
                    </div>

                    <div className={classNames('user__data-place', {}, [s.hidden])}>

                        <div className={'user__title'}>
                            <div className={classNames('user__title-box', {}, [])}>
                                <h2>{nickname && nickname}</h2>
                                <UserMood isChangeMood={false}/>
                            </div>
                        </div>

                        <UserInfo
                            about_text={about_text}
                            addictions={addictions}
                            problems={problems}
                            location={location}
                        />

                    </div>
                </div>
            </div>
        </div>

    </div>
});
