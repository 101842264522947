import {$api, BaseResponse} from "../../../../api/api";
import {IChatsCollection} from "../../../chats/types/chatsTypes";
import {snackbarAction} from "../../../errors/SnackbarSlice";
import {AppDispatch} from "../../../../store";
import {useAppSelector} from "../../../../hooks/redux";
import {getProfileId} from "../../../profile/selectors/getProfile/getProfile";

interface IResponseMyCollection {
    info: IChatsCollection
}

export const checkIfInChat = async (idChat: number, myId: number | undefined, dispatch: AppDispatch) => {
    try {
        if (!myId) {
            return false
        }

        const response = await $api.get<BaseResponse<IResponseMyCollection>>(`chat/${idChat}`)
        if (!response.data.success) {
            return false
        }
        const chat = response.data.response.info

        if (chat) {
            const user = chat?.users?.find(user => user.id === myId)
            if (user) {
                return true
            } else {
                return false
            }
        }

        return false

    } catch (err) {
        dispatch(snackbarAction.addError('INTERNAL_ERROR'))
        console.log('error');
    }
}
