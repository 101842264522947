import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ILanguage} from "../../../models/Llanguage";


const initialState: ILanguage = {
    language: null
}

export const languageSlice = createSlice({
    name: 'language',
    initialState,
    reducers: {
        changeLanguageWithButton(state, action: PayloadAction<ILanguage>) {
            state.language = action.payload.language === 'ru' ? 'en' : 'ru'
        },
        changeLanguage(state, action: PayloadAction<ILanguage>) {
            state.language = action.payload.language === 'ru' ? 'ru' : 'en'
        },

    },
    extraReducers: () => {
    },
})


export const languageReducer = languageSlice.reducer
export const languageAction = languageSlice.actions


