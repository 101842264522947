import {memo, Suspense} from 'react';
import {Modal} from "../Modal";
import {LoaderModal} from "../../../ui/loaderModal/LoaderModal";
import {AuthContainerModalAsync} from "./authModal/AuthContainerModal.async";

interface LoginModalProps {
    isOpen: boolean
    onClose: () => void
}

export const LoginModal = memo(({onClose, isOpen}: LoginModalProps) => {
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            lazy
            isCenter
        >
            <Suspense fallback={<LoaderModal/>}>
                <AuthContainerModalAsync setIsActiveModal={onClose}/>
            </Suspense>
        </Modal>
    );
});
