import {createAsyncThunk} from '@reduxjs/toolkit';
import {ThunkConfig} from "../../../config/StateSchema";
import {IProfileSpec, IProfileUser} from "../../types/profileTypes";
import {authAction} from "../../../auth/authSlice";
import {snackbarAction} from "../../../errors/SnackbarSlice";


export const fetchProfileSelfRepeat = createAsyncThunk<IProfileSpec | IProfileUser,
    void,
    ThunkConfig<string>>(
        'profile/fetchProfileSelfRepeat',
        async (_, thunkAPI) => {
            const {extra, rejectWithValue, dispatch} = thunkAPI;
            const {changeIsNavbarLoading} = authAction
            dispatch(changeIsNavbarLoading(true))
            try {
                const response = await extra.api.get('profile/self')
                if (!response.data.success) {
                    return rejectWithValue('error')
                }
                return response.data.response.info
            } catch (err) {
                dispatch(snackbarAction.addError('INTERNAL_ERROR'))
                return rejectWithValue('error');
            } finally {
                dispatch(changeIsNavbarLoading(false))
            }
        },
    );
