import {RootState} from "../../../../store";
import defAvatar from "../../../../../utils/images/avatars/01.svg";
import {createSelector} from "@reduxjs/toolkit";
import {UserGroup} from "../../types/profileTypes";


export const getProfile = (state: RootState) => state.profileReducer.profile;

export const getProfileAvatar = (state: RootState) => state.profileReducer.profile?.avatar || defAvatar;
export const getProfileEmail = (state: RootState) => state.profileReducer.profile?.email;
export const getProfileGroupId = (state: RootState) => state.profileReducer.profile?.user_group_id;
export const getProfileId = (state: RootState) => state.profileReducer.profile?.id;
export const getProfileNickname = (state: RootState) => state.profileReducer.profile?.nickname || 'Nickname';
export const getProfilePersonalId = (state: RootState) => state.profileReducer.profile?.personal_id;
export const getProfileIsVerification = (state: RootState) => state.profileReducer.profile?.approval_status_id || 0;

export const getProfileError = (state: RootState) => state.profileReducer.errors;
export const getProfileIsLoading = (state: RootState) => state.profileReducer.isLoading;
export const getProfileIsLoadingProfile = (state: RootState) => state.profileReducer.isLoadingProfile;

export const getProfileClients = (state: RootState) => state.profileReducer.clients
export const getProfileBlackList = (state: RootState) => state.profileReducer.blackList
export const getProfileEvents = (state: RootState) => state.profileReducer.events

export const getProfileFriends = (state: RootState) => state.profileReducer.friends
export const getProfileFindFriendsData = (state: RootState) => state.profileReducer.findFriends

export const getProfileHasMore = (state: RootState) => state.profileReducer.hasMore
export const getProfileIsMounted = (state: RootState) => state.profileReducer.isMounted || false

export const getProfileUserRelationship = (state: RootState) => state.profileReducer.userRelationship
export const getProfileIsDataAvailable = (state: RootState) => state.profileReducer.isDataAvailable

export const getProfileStatusFilterFriends = (state: RootState) => state.profileReducer.statusFilterFriends ?? 'friends'
export const getProfileStatusFilterCabinet = (state: RootState) => state.profileReducer.statusFilterCabinet ?? 'events'


export const getProfileFriendsRequest = createSelector(
    getProfileFriends,
    (friends) => {
        return friends?.filter(fr => fr.incoming)
    }
)

export const getProfileNameOrNickname = createSelector(
    getProfile,
    (profile) => {
        if (profile?.user_group_id === UserGroup.SPEC || profile?.user_group_id === UserGroup.SPEC_PREMIUM) {
            return profile.name ? profile.name : profile.nickname
        }
        return profile?.nickname ?? 'user'
    }
)
