import axios from 'axios';

const baseUrl = '/api/v1'

export const $api = axios.create({
    baseURL: baseUrl,
    headers: {
        ['X-Client-Timezone']: Intl.DateTimeFormat().resolvedOptions().timeZone
    }
});


export interface BaseResponse<T = {}> {
    statusCode: number
    response: T
    code: number
    codeKey: string
    success: boolean
}

export type Errors<T = {}> = Partial<Record<keyof T, string>>;

