import {createAsyncThunk} from '@reduxjs/toolkit';
import {ThunkConfig} from "../../../config/StateSchema";
import {BaseResponse} from "../../../../api/api";
import {snackbarAction} from "../../../errors/SnackbarSlice";
import {IChatItem} from "../../../chatItem/types/chatItemTypes";

interface IResponse {
    info: IChatItem
}

export const fetchPrivateChatById = createAsyncThunk<IChatItem,
    string,
    ThunkConfig<string>>(
        'privateChats/fetchPrivateChatById',
        async (id, thunkAPI) => {
            const {extra, rejectWithValue, dispatch} = thunkAPI;
            try {
                const response = await extra.api.get<BaseResponse<IResponse>>(`chat/${id}`, )

                if (!response.data.success) {
                    return rejectWithValue('error')
                }
                return response.data.response.info
            } catch (err) {
                dispatch(snackbarAction.addError('INTERNAL_ERROR'))
                return rejectWithValue('error');
            }
        },
    );
