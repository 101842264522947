import {createAsyncThunk} from '@reduxjs/toolkit';
import {ThunkConfig} from "../../../config/StateSchema";
import {BaseResponse} from "../../../../api/api";
import {snackbarAction} from "../../../errors/SnackbarSlice";
import {IRoomRequest} from "../../types/roomsTypes";
import {StatusChat} from "../../../chats/types/chatsTypes";

interface IResponse {
    info: IRoomRequest
}

interface IPayload {
    status: StatusChat
    id: number
}

export const updateRoomRequest = createAsyncThunk<
    IRoomRequest,
    IPayload,
    ThunkConfig<string>
>(
    'rooms/updateRoomRequest',
    async ({status, id}, thunkAPI) => {
        const {extra, rejectWithValue, dispatch} = thunkAPI;
        try {
            const response = await extra.api.put<BaseResponse<IResponse>>(`room/request/${id}/update`, {
                status
            })

            if (!response.data.success) {
                dispatch(snackbarAction.addError('INTERNAL_ERROR'))
                return rejectWithValue('error')
            }

            return response.data.response.info
        } catch (err) {
            dispatch(snackbarAction.addError('INTERNAL_ERROR'))
            return rejectWithValue('error');
        }
    },
);
