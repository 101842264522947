import s from "./HeaderItem.module.css";
import {NavLink} from "react-router-dom";
import {goToTop} from "../../../utils/helpers/goToTop";

interface IHeaderItem {
    title: string
    path: string
    isBorder?: boolean
}


export const HeaderItem = ({title, path, isBorder}: IHeaderItem) => {

    let styleNavLink = s.header__link

    if (isBorder) styleNavLink = `btn accent`

    const onClickHandler = () => {
        goToTop('auto')
    }
    return (
        <div className={s.header__item}>
            <NavLink className={styleNavLink}
                to={path}
                onClick={onClickHandler}>
                {title}
            </NavLink>
        </div>
    );
};
