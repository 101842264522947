import {createAsyncThunk} from '@reduxjs/toolkit';
import {ThunkConfig} from "../../../config/StateSchema";
import {BaseResponse} from "../../../../api/api";
import {IRoomItem} from "../../types/roomItemTypes";
import {snackbarAction} from "../../../errors/SnackbarSlice";
import {roomItemsAction} from "../../roomItemSlice";
import {getProfileId} from "../../../profile/selectors/getProfile/getProfile";

interface IResponse {
    info: IRoomItem
}

export const fetchRoomById = createAsyncThunk<IRoomItem,
    string,
    ThunkConfig<string>>(
        'roomItem/fetchRoomById',
        async (id, thunkAPI) => {
            const {extra, rejectWithValue, dispatch, getState} = thunkAPI;
            try {
                const response = await extra.api.get<BaseResponse<IResponse>>(`room/${id}`, )

                if (!response.data.success) {
                    return rejectWithValue('error')
                }
                const myId = getProfileId(getState())
                const room =response.data.response.info
                if(room.created_by.id !== myId) {
                    const users = room.users
                    const isShowRules = users?.find(us => us.id === myId)?.is_rules_accepted
                    if(isShowRules === 0) {
                        dispatch(roomItemsAction.setIsShowRules(true))
                    }
                }
                return response.data.response.info
            } catch (err) {
                dispatch(snackbarAction.addError('INTERNAL_ERROR'))
                return rejectWithValue('error');
            }
        },
    );
