import {tokenInterceptors} from "../tokenManagement/tokenInterceptors";
import {AppDispatch} from "../../../../store";
import {$api, BaseResponse} from "../../../../api/api";
import {removeToken} from "../tokenManagement/tokenManagement";
import {snackbarAction} from "../../../errors/SnackbarSlice";

interface IResponse {
    access_token: string
}

export const fetchAuthGuest = async (dispatch: AppDispatch) => {

    try {
        await removeToken()
        const response = await $api.post<BaseResponse<IResponse>>(`auth/guest`)

        if (!response.data.success) {
            dispatch(snackbarAction.addError('INTERNAL_ERROR'))
            return false
        }
        await tokenInterceptors(response.data.response.access_token)
        return response.data.response.access_token
    } catch (err) {
        dispatch(snackbarAction.addError('INTERNAL_ERROR'))
        console.log('err', err)
        // @ts-ignore
        return rejectWithValue(err.data.error);
    }

}
