import {createAsyncThunk} from '@reduxjs/toolkit';
import {ThunkConfig} from '../../../config/StateSchema';
import {getProfileGroupId} from "../../../profile/selectors/getProfile/getProfile";
import {chatsAction} from "../../chatsSlice";
import {UserGroup} from "../../../profile/types/profileTypes";
import {RoutePath} from "../../../../../router/routerConfig/routerConfig";
import {snackbarAction} from "../../../errors/SnackbarSlice";
import {twilioChatsAction} from "../../../twilioChats/twilioChatsSlice";

interface IParamsNavigatePersonalChat {
    twilio_id: string
    navigate: (to: string) => void
}

export const navigatePersonalChat = createAsyncThunk<void,
    IParamsNavigatePersonalChat,
    ThunkConfig<string>>(
        'chats/navigatePersonalChat',
        async ({twilio_id, navigate}, thunkAPI) => {
            const {rejectWithValue, dispatch, getState} = thunkAPI;
            const userGroupId = getProfileGroupId(getState())
            try {
                await dispatch(twilioChatsAction.setActiveTwilioId(twilio_id))
                if (userGroupId === UserGroup.SPEC || userGroupId === UserGroup.SPEC_PREMIUM) {
                    navigate(RoutePath.specialist_messages)
                }
                if (userGroupId === UserGroup.USER || userGroupId === UserGroup.USER_PREMIUM) {
                    navigate(RoutePath.profile_messages)
                }

            } catch (err) {
                dispatch(snackbarAction.addError('INTERNAL_ERROR'))
                return rejectWithValue('error');
            } finally {
                dispatch(chatsAction.setIsLoadingChat(false))
            }
        },
    );
