import {createAsyncThunk} from '@reduxjs/toolkit';
import {ThunkConfig} from "../../../config/StateSchema";
import {BaseResponse, Errors} from "../../../../api/api";
import {IProfileSpec, IProfileUser, IVerification,} from "../../types/profileTypes";
import {fetchProfileSelfRepeat} from "../fetchProfileSelfRepeat/fetchProfileSelfRepeat";
import {snackbarAction} from "../../../errors/SnackbarSlice";

export interface IResponseUpdateProfile {
    info: IProfileSpec | IProfileUser
}


export const updateProfileVerification = createAsyncThunk<void, FormData,
    ThunkConfig<Errors<IVerification>>>(
        'profile/updateProfileVerification',
        async (data, thunkAPI) => {
            const {extra, rejectWithValue, dispatch} = thunkAPI;
            try {
                const response = await extra.api.post<BaseResponse>(`verification/spec`, data)
                if (!response.data.success) {
                    return rejectWithValue({
                        errorsVerification: response.data.codeKey
                    })
                }
                if (response.data.success) {
                    await thunkAPI.dispatch(fetchProfileSelfRepeat())
                }

            } catch (err) {
                dispatch(snackbarAction.addError('INTERNAL_ERROR'))
                // @ts-ignore
                return rejectWithValue(err);
            }
        },
    );
