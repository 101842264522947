import {createAsyncThunk} from '@reduxjs/toolkit';
import {IUserRelationship} from "../../../../profile/types/profileTypes";
import {ThunkConfig} from "../../../../config/StateSchema";
import {BaseResponse} from "../../../../../api/api";
import {snackbarAction} from "../../../../errors/SnackbarSlice";


export interface IResponseUpdateProfile {
    collection: IUserRelationship[]
}


export const fetchNotificationsRequestsFriend = createAsyncThunk<number,
    void,
    ThunkConfig<string>
>(
    'notifications/fetchNotificationsRequestsFriend',
    async (_, thunkAPI) => {
        const {extra, rejectWithValue, dispatch} = thunkAPI;
        try {
            const response = await extra.api.get<BaseResponse<IResponseUpdateProfile>>('relationship/friend', {
                params: {
                    status: 0
                }
            })
            if (!response.data.success) {
                return rejectWithValue('error')
            }

            return response.data.response.collection?.filter(fr => fr.incoming).length
        } catch (err) {
            dispatch(snackbarAction.addError('INTERNAL_ERROR'))
            return rejectWithValue('error');
        }
    }
);
