import {useEffect} from "react";

export const goToTop = (behavior: 'auto' | 'smooth') => {
    window.scrollTo({
        top: 0,
        behavior: behavior
    });
}

export const useScrollToTop = () => {
    useEffect(() => {
        const scrollToTop = () => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        };

        const handleScrollToTop = () => {
            scrollToTop();
        };

        // Добавляем обработчик события при монтировании компонента
        window.addEventListener('scrollToTop', handleScrollToTop);

        // Удаляем обработчик события при размонтировании компонента
        return () => {
            window.removeEventListener('scrollToTop', handleScrollToTop);
        };
    }, []);

    const handleClick = () => {
        window.dispatchEvent(new Event('scrollToTop'));
    };

    return handleClick;
};
