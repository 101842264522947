import {createAsyncThunk} from '@reduxjs/toolkit';
import {ThunkConfig} from "../../../config/StateSchema";
import {BaseResponse} from "../../../../api/api";
import {snackbarAction} from "../../../errors/SnackbarSlice";
import {IChatsCollection, IChatsMyCollectionsQuery} from "../../../chats/types/chatsTypes";


interface IResponse {
    collection: IChatsCollection[] | null
}

export const fetchMyPrivateChatsTokens = createAsyncThunk<IChatsCollection[] | null,
    IChatsMyCollectionsQuery | void,
    ThunkConfig<string>>(
        'twilioChats/fetchMyPrivateChatsTokens',
        async (data, thunkAPI) => {
            const {extra, rejectWithValue, dispatch} = thunkAPI;
            try {
                const response = await extra.api.get<BaseResponse<IResponse>>('chat/my', {
                    params: data
                })

                if (!response.data.success) {
                    return rejectWithValue('error')
                }

                return response.data.response.collection
            } catch (err) {
                dispatch(snackbarAction.addError('INTERNAL_ERROR'))
                return rejectWithValue('error');
            }
        },
    );
