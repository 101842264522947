import {$api, BaseResponse} from "../../../../api/api";
import {IRoomsCollection} from "../../../rooms/types/roomsTypes";
import {snackbarAction} from "../../../errors/SnackbarSlice";
import {AppDispatch} from "../../../../store";

interface IResponseMyCollection {
    info: IRoomsCollection
}

export const checkIfInRoom = async (idRoom: number, myId: number | undefined,  dispatch: AppDispatch) => {
    try {
        if (!myId) {
            return false
        }

        const response = await $api.get<BaseResponse<IResponseMyCollection>>(`room/${idRoom}`)
        if (!response.data.success) {
            return false
        }
        const room = response.data.response.info

        if (room) {
            const user = room?.users?.find(user => user.id === myId)
            if (user) {
                return true
            } else {
                return false
            }
        }

        return false

    } catch (err) {
        dispatch(snackbarAction.addError('INTERNAL_ERROR'))
        console.log('error');
    }
}
