import {createSlice, DeepPartial, PayloadAction} from "@reduxjs/toolkit";
import {
    ISpecialistFindCollection,
    ISpecialistSelectionData,
    ISpecialistsFindMain
} from "./types/specialistFindTypes";
import {fetchSpecialistsFind} from "./services/fetchSpecialistsFind/fetchSpecialistsFind";
import {
    fetchSpecialistsFindWithEmptyData
} from "./services/fetchSpecialistsFind/fetchSpecialistsFindWithEmptyData";


const initialState: ISpecialistsFindMain = {
    error: undefined,
    isLoading: false,
    specialistsCollection: [],
}

export const specialistsFindSlice = createSlice({
    name: 'specialistsFindSlice',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload
        },
        setSelectionData(state, action: PayloadAction<DeepPartial<ISpecialistSelectionData>>) {
            state.selectionData = {
                ...action.payload
            }
        },
        setCost(state, action: PayloadAction<number[]>) {
            if(state.selectionData){
                state.selectionData = {
                    ...state.selectionData,
                    cost: action.payload
                }
            } else {
                state.selectionData = {
                    cost: action.payload
                }
            }
        },
        setAddictions(state, action: PayloadAction<number[]>) {
            if(state.selectionData){
                state.selectionData = {
                    ...state.selectionData,
                    addictions: action.payload
                }
            } else {
                state.selectionData = {
                    addictions: action.payload
                }
            }
        },
        setProblems(state, action: PayloadAction<number[]>) {
            if(state.selectionData){
                state.selectionData = {
                    ...state.selectionData,
                    problems: action.payload
                }
            } else {
                state.selectionData = {
                    problems: action.payload
                }
            }
        },
        setLanguage(state, action: PayloadAction<string>) {
            if(state.selectionData){
                state.selectionData = {
                    ...state.selectionData,
                    language: action.payload
                }
            } else {
                state.selectionData = {
                    language: action.payload
                }
            }
        },
        clearData(state) {
            state.specialistsCollection = []
            state.error = undefined
            state.isLoading = false
            state.selectionData = undefined
        },
        clearSelectionData(state) {
            state.selectionData = undefined
        },
    },
    extraReducers: (builder) => {
        builder

            //fetch specialists
            .addCase(fetchSpecialistsFind.pending, (state) => {
                state.isLoading = true
                state.error = undefined
            })
            .addCase(fetchSpecialistsFind.fulfilled, (state, action: PayloadAction<ISpecialistFindCollection[]>) => {
                if (action.payload?.length > 0) {
                    state.specialistsCollection = action.payload
                    state.isLoading = false
                }
            })
            .addCase(fetchSpecialistsFind.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.payload
            })

            //fetch specialists with empty data
            .addCase(fetchSpecialistsFindWithEmptyData.fulfilled, (state, action: PayloadAction<ISpecialistFindCollection[]>) => {
                state.specialistsCollection = action.payload
                state.isLoading = false
            })
            .addCase(fetchSpecialistsFindWithEmptyData.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.payload
            })
    }
})


export const specialistsFindSliceReducer = specialistsFindSlice.reducer
export const specialistsFindSliceAction = specialistsFindSlice.actions
