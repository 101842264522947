import {createAsyncThunk} from '@reduxjs/toolkit';
import {ThunkConfig} from "../../../config/StateSchema";
import {profileAction} from "../../../profile/profileSlice";
import {authAction} from "../../authSlice";
import {removeToken} from "../tokenManagement/tokenManagement";
import {chatsAction} from "../../../chats/chatsSlice";
import {chatItemAction} from "../../../chatItem/chatItemSlice";
import {snackbarAction} from "../../../errors/SnackbarSlice";


export const fetchAuthLogout = createAsyncThunk<boolean,
    void,
    ThunkConfig<string>>(
        'auth/fetchProfileLogout',
        async (_, thunkAPI) => {
            const {changeIsNavbarLoading} = authAction
            const {extra, rejectWithValue, dispatch} = thunkAPI;

            dispatch(changeIsNavbarLoading(true))
            try {
                const response = await extra.api.post(
                    `/auth/logout`, {});
                if (!response.data.success) {
                    return rejectWithValue('error')
                }
                await removeToken()
                dispatch(profileAction.clearProfile())
                dispatch(chatsAction.clearChats())
                dispatch(chatItemAction.clearChatItem())
                return false
            } catch (err) {
                dispatch(snackbarAction.addError('INTERNAL_ERROR'))
                return rejectWithValue('error');
            } finally {
                dispatch(changeIsNavbarLoading(false))
            }
        },
    );
