import {createAsyncThunk} from '@reduxjs/toolkit';
import {BaseResponse} from "../../../../api/api";
import {ThunkConfig} from "../../../config/StateSchema";
import {ICalendarResponse} from "../../types/IScheduler";
import {snackbarAction} from "../../../errors/SnackbarSlice";

export interface IResponseCalendarData {
    collection: ICalendarResponse[]
}

export interface IParamsMyCalendarCollection {
    from: string
    to: string
}

export const fetchCalendarMyCollection = createAsyncThunk<ICalendarResponse[],
    IParamsMyCalendarCollection,
    ThunkConfig<number>>(
        'scheduler/fetchCalendarMyCollection',
        async ({
            from, to
        }, thunkAPI) => {
            const {extra, rejectWithValue, dispatch} = thunkAPI;
            try {
                const response = await extra.api.get<BaseResponse<IResponseCalendarData>>(`schedule/my`, {
                    params: {
                        from, to
                    }
                }
                )
                if (!response.data.success) {
                    return rejectWithValue(response.data.code)
                }
                return response.data.response.collection
            } catch (err) {
                dispatch(snackbarAction.addError('INTERNAL_ERROR'))
                // @ts-ignore
                return rejectWithValue(err);
            }
        },
    );

// const formattedDate = moment(date).format('YYYY-MM-DD');
