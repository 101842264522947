import {FC} from 'react';
import s from './ChangeMood.module.css'
import {useTranslation} from "react-i18next";
import {MoodItem} from "./moodItem/MoodItem";
import {CSSTransition} from "react-transition-group";
import {useAppDispatch} from "../../store/hooks/redux";

type ChangeMoodPropsType = {
  moods: IMood[]
  idMood: string
  isActive: boolean
  setIsActiveFalse: () => void
}

export interface IMood {
    moonIMG: string
    moodTitle: string
    idMood: string
}

export const ChangeMood: FC<ChangeMoodPropsType> = ({
    idMood,
    moods,
    isActive,
    setIsActiveFalse
}) => {
    const {t} = useTranslation(['common'])
    const dispatch = useAppDispatch()


    // const changeActiveMood = (idMood: string) => {
    //     dispatch(changeMood({idIsActiveMood: idMood}))
    //     setIsActiveFalse()
    // }

    return (
        <CSSTransition classNames={'moods'} timeout={300} in={isActive} mountOnEnter unmountOnExit>
            <div className={s.mood}>

                <div className={s.mood__title}>
                    {t('common:changeMood')}
                </div>

                <div className={s.mood__list}>
                    {/*{moods.map(md => <MoodItem key={md.idMood}*/}
                    {/*    changeActiveMood={changeActiveMood}*/}
                    {/*    titleMood={md.moodTitle}*/}
                    {/*    imgMood={md.moonIMG}*/}
                    {/*    idMood={md.idMood}*/}
                    {/*    isActive={md.idMood === idMood}/>)}*/}
                </div>

            </div>
        </CSSTransition>
    );
};
