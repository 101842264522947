import s from "./ProfileInfo.module.css";
import { useTranslation } from "react-i18next";
import { IConstantCollection } from "../../store/reducers/constants/types/constantsTypes";
import { useSpecializations } from "../../hooks/useSpecializations";

type SpecialistInfoPropsType = {
  location?: string;
  about_text?: string;
  experience_text?: string;
  addictions?: IConstantCollection[];
  problems?: IConstantCollection[];
};

export const SpecialistInfo = (props: SpecialistInfoPropsType) => {
    const {
        location,
        about_text,
        addictions,
        problems,
        experience_text
    } = props
    const {t} = useTranslation(['profile', 'specialist', 'addictions', 'problems'])
    const specialization = useSpecializations(t, addictions, problems)

    return (
        <div className={s.user__info}>
            {specialization.length ? (
                <div className={s.user__infoLine}>
                    <div className={s.user__infoName}>
                        {t("specialist:profile.title-profile")}
                    </div>
                    <div className={s.user__infoData}>{specialization.join(", ")}</div>
                </div>
            ) : null}

            {location ? (
                <div className={s.user__infoLine}>
                    <div className={s.user__infoName}>{t("profile:profile.city")}:</div>
                    <div className={s.user__infoData}>{location}</div>
                </div>
            ) : null}

            {experience_text ? (
                <div className={s.user__infoLine}>
                    <div className={s.user__infoName}>
                        {t("specialist:profile.title-workExperience")}
                    </div>
                    <div className={s.user__infoData}>{experience_text}</div>
                </div>
            ) : null}

            {about_text ? (
                <div className={s.user__infoLine}>
                    <div className={s.user__infoName}>
                        {t("profile:profile.aboutMe")}:
                    </div>
                    <div className={s.user__infoData}>{about_text}</div>
                </div>
            ) : null}
        </div>
    );
};
