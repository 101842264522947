import {createAsyncThunk} from '@reduxjs/toolkit';
import {ThunkConfig} from "../../../config/StateSchema";
import {BaseResponse} from "../../../../api/api";
import {IChatRequest, StatusChat} from "../../types/chatsTypes";
import {snackbarAction} from "../../../errors/SnackbarSlice";
import {IChatItem} from "../../../chatItem/types/chatItemTypes";

interface IResponse {
    info: IChatRequest
}

interface IPayload {
    status: StatusChat
    id: number
}

export const updateChatRequest = createAsyncThunk<
    IChatRequest,
    IPayload,
    ThunkConfig<string>
>(
    'chats/updateChatRequest',
    async ({status, id}, thunkAPI) => {
        const {extra, rejectWithValue, dispatch} = thunkAPI;
        try {
            const response = await extra.api.put<BaseResponse<IResponse>>(`chat/request/${id}/update`, {
                status
            })

            if (!response.data.success) {
                dispatch(snackbarAction.addError('INTERNAL_ERROR'))
                return rejectWithValue('error')
            }

            return response.data.response.info
        } catch (err) {
            dispatch(snackbarAction.addError('INTERNAL_ERROR'))
            return rejectWithValue('error');
        }
    },
);
