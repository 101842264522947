import {createAsyncThunk} from '@reduxjs/toolkit';
import {ThunkConfig} from "../../../config/StateSchema";
import {BaseResponse} from "../../../../api/api";
import {snackbarAction} from "../../../errors/SnackbarSlice";

export interface IResponseUpdateProfile {
    info: {
        extension: string
        id: number
        mime_type: string
        name: string
        path: string
    }
}

export const updateProfileAvatar = createAsyncThunk<string,
    FormData,
    ThunkConfig<string>>(
        'profile/updateProfileAvatar',
        async (formData, thunkAPI) => {
            const {extra, rejectWithValue, dispatch} = thunkAPI;
            try {
                const response = await extra.api.put<BaseResponse<IResponseUpdateProfile>>('profile/change-avatar',
                    formData
                )
                if (!response.data.success) {
                    return rejectWithValue('error')
                }
                return response.data.response.info.path
            } catch (err) {
                console.log('err', err)
                dispatch(snackbarAction.addError('INTERNAL_ERROR'))
                return rejectWithValue('error');
            }
        },
    );
