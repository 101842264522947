import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Client} from "@twilio/conversations";
import {IRoomItem, IRoomItemMain} from "./types/roomItemTypes";
import defIcon from '../../../utils/images/avatars/02.svg'
import {fetchRoomById} from "./services/fetchRoomById/fetchRoomById";


const initialState: IRoomItemMain = {
    error: undefined,
    isLoading: true,
    isLoadingRoom: false,
    isShowRules: false,
    isMounted: false
}

export const roomItemsSlice = createSlice({
    name: 'roomItems',
    initialState,
    reducers: {
        changeRoomTokenTwilio(state, action: PayloadAction<string>) {
            state.roomTokenTwilio = action.payload
        },
        setIsLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload
        },
        setIsLoadingRoom(state, action: PayloadAction<boolean>) {
            state.isLoadingRoom = action.payload
        },
        setIsMounted(state, action: PayloadAction<boolean>) {
            state.isMounted = action.payload
        },
        clearRoomItem(state) {
            state.room = undefined
            state.roomTokenTwilio = undefined
        },
        setClient(state, action: PayloadAction<Client>) {
            // @ts-ignore
            state.client = action.payload
        },
        setIsShowRules(state, action: PayloadAction<boolean>) {
            state.isShowRules = action.payload
        },
        resetLoaders(state) {
            state.isLoadingRoom = true
            state.isLoading = true
            state.isShowRules = false
            state.isMounted = false
        },
    },
    extraReducers: (builder) => {
        builder
            //fetch room by id
            .addCase(fetchRoomById.pending, (state) => {
                state.isLoading = true
                state.error = undefined
            })
            .addCase(fetchRoomById.fulfilled, (state, action: PayloadAction<IRoomItem>) => {
                state.room = {
                    ...action.payload,
                    icon: action.payload.icon || defIcon
                }
                state.isLoading = false
            })
            .addCase(fetchRoomById.rejected, (state, action) => {
                state.error = action.payload
                state.isLoading = false
            })



    },
})


export const roomItemsReducer = roomItemsSlice.reducer
export const roomItemsAction = roomItemsSlice.actions


