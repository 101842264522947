import {createAsyncThunk} from '@reduxjs/toolkit';
import {ThunkConfig} from "../../../config/StateSchema";
import {BaseResponse} from "../../../../api/api";
import {chatsAction} from "../../../chats/chatsSlice";
import {IJoinChatData} from "../../types/chatItemTypes";
import {RoutePath} from "../../../../../router/routerConfig/routerConfig";
import {fetchChatsInfoById} from "../../../chats/services/fetchChatsInfoById/fetchChatsInfoById";
import {snackbarAction} from "../../../errors/SnackbarSlice";
import {chatItemAction} from "../../chatItemSlice";

interface IResponse {
    status: number
}

export const joinChatItem = createAsyncThunk<void,
    IJoinChatData,
    ThunkConfig<string>>(
        'chats/joinChatItem',
        async ({id, navigate}, thunkAPI) => {
            const {extra, rejectWithValue, dispatch} = thunkAPI;

            try {
                const response = await extra.api.post<BaseResponse<IResponse>>(`chat/${id}/join`,)

                if (!response.data.success) {
                    dispatch(chatsAction.addChatError({id, error: response.data.codeKey}))
                    return rejectWithValue('error')
                }
                if (response.data.response.status === 2) {
                    navigate(RoutePath.chat_view + id)
                } else {
                    await dispatch(fetchChatsInfoById(id))
                }

            } catch (err) {
                dispatch(snackbarAction.addError('INTERNAL_ERROR'))
                return rejectWithValue('error');
            }
        },
    );
