import s from './UserMood.module.css'
import {TooltipBox} from "../tooltip/TooltipBox";
import {ChangeMood} from "../ChangeMood/ChangeMood";
import {useOutside} from "../../hooks/useOutside";
import {useAppSelector} from "../../store/hooks/redux";
import {getUserInfoMoods} from "../../store/reducers/userInfo/getUserInfoMoods/getUserInfoMoods";

type UserMoodPropsType = {
    isChangeMood?: boolean
}

export const UserMood = ({isChangeMood = true}: UserMoodPropsType) => {
    const moodsDate = useAppSelector(getUserInfoMoods)
    const {idIsActiveMood, arrMoods} = moodsDate || {}

    const mood = arrMoods?.filter(m => m.idMood === idIsActiveMood)[0]

    const {isShow, setIsShow, ref} = useOutside(false)

    const showChangeMoods = () => {
        setIsShow(!isShow)
    }

    return (
        <div className={s.user__mood} ref={ref}>
            <div className={s.user__activeMood} onClick={showChangeMoods}>
                {mood && (
                    <>
                        <img src={mood.moonIMG} alt="moon" id={mood.idMood}/>
                        <TooltipBox content={mood.moodTitle} anchorId={mood.idMood}/>
                    </>
                )
                }
            </div>
            {isChangeMood && arrMoods && idIsActiveMood
                &&
                <ChangeMood setIsActiveFalse={() => setIsShow(false)}
                    moods={arrMoods}
                    idMood={idIsActiveMood}
                    isActive={isShow}/>
            }
        </div>
    );
};
