import {createAsyncThunk} from '@reduxjs/toolkit';
import {ThunkConfig} from "../../../config/StateSchema";
import {BaseResponse} from "../../../../api/api";
import {snackbarAction} from "../../../errors/SnackbarSlice";

interface IResponse {
    access_token: string
}

export const fetchTwilioToken= createAsyncThunk<string,
    void,
    ThunkConfig<string>>(
        'twilioChat/fetchTwilioToken',
        async (_, thunkAPI) => {
            const {extra, rejectWithValue, dispatch} = thunkAPI;

            try {
                const response = await extra.api.get<BaseResponse<IResponse>>(`auth/twilio/token`, )
                if (!response.data.success) {
                    return rejectWithValue('error')
                }

                return response.data.response.access_token
            } catch (err) {
                dispatch(snackbarAction.addError('INTERNAL_ERROR'))
                return rejectWithValue('error');
            }
        },
    );
