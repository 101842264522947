import {useMemo} from 'react'
import {IRelationCurrentUser, Relationship} from "../store/reducers/profile/types/profileTypes";

export const useRenderMyRelationship =(myId: number, relation_current_user?: IRelationCurrentUser) => {
    return useMemo(()=> {
        const shouldRenderMyFriends = relation_current_user
            && (relation_current_user.type === Relationship.FRIENDSHIP)
            && relation_current_user.status === 1

        const shouldRenderRequest = relation_current_user
            && relation_current_user.type === Relationship.FRIENDSHIP
            && relation_current_user.created_by_user_id === myId
            && relation_current_user.status === 0

        const shouldRenderRequestFromUser = relation_current_user
            && relation_current_user.type === Relationship.FRIENDSHIP
            && relation_current_user.created_by_user_id !== myId
            && relation_current_user.status === 0

        const shouldRenderClient = relation_current_user
            && relation_current_user.type === Relationship.CLIENT

        const shouldRenderAddFriends = !relation_current_user

        const shouldRenderMyBlock = relation_current_user
            && relation_current_user.created_by_user_id === myId
            && relation_current_user.type === Relationship.BLACKLIST

        const shouldRenderBlockByUser = relation_current_user
            && relation_current_user.created_by_user_id !== myId
            && relation_current_user.type === Relationship.BLACKLIST
        return {
            shouldRenderMyFriends,
            shouldRenderRequest,
            shouldRenderRequestFromUser,
            shouldRenderClient,
            shouldRenderAddFriends,
            shouldRenderMyBlock,
            shouldRenderBlockByUser
        }
    }, [myId, relation_current_user])
}
