import {createAsyncThunk} from '@reduxjs/toolkit';
import {ThunkConfig} from "../../../config/StateSchema";
import {BaseResponse} from "../../../../api/api";
import {roomsAction} from "../../../rooms/roomsSlice";
import {IJoinChatData} from "../../../chatItem/types/chatItemTypes";
import {RoutePath} from '../../../../../router/routerConfig/routerConfig';
import {fetchRoomsInfoById} from "../../../rooms/services/fetchRoomsInfoById/fetchRoomsInfoById";
import {snackbarAction} from "../../../errors/SnackbarSlice";
import {chatItemAction} from "../../../chatItem/chatItemSlice";
import {roomItemsAction} from "../../roomItemSlice";

interface IResponse {
    status: number
}

export const joinRoomItem= createAsyncThunk<void,
   IJoinChatData,
    ThunkConfig<string>>(
        'rooms/joinRoomItem',
        async ({id, navigate}, thunkAPI) => {
            const {extra, rejectWithValue, dispatch} = thunkAPI;

            try {
                const response = await extra.api.post<BaseResponse<IResponse>>(`room/${id}/join`, )
                if (!response.data.success) {
                    dispatch(roomsAction.addRoomError({id, error: response.data.codeKey}))
                    return rejectWithValue('error')
                }
                if(response.data.response.status === 2) {

                    navigate(RoutePath.room_view + id)
                } else {
                    await dispatch(fetchRoomsInfoById(id))
                }

            } catch (err) {
                dispatch(snackbarAction.addError('INTERNAL_ERROR'))
                return rejectWithValue('error');
            }
        },
    );
