import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
    INotification,
    INotificationPrivateChat,
    INotificationsMain,
    INotificationStart
} from "./types/notificationsTypes";
import {
    fetchNotificationsCollection
} from "./services/fetchNotificationsCollection/fetchNotificationsCollection";
import {
    fetchNotificationsRequestsFriend
} from "./services/fetchNotificationsRequests/fetchNotificationsRequestsFriend/fetchNotificationsRequestsFriend";
import {
    fetchNotificationsRequestsChat
} from "./services/fetchNotificationsRequests/fetchNotificationsRequestsChat/fetchNotificationsRequestsChat";
import {
    fetchNotificationsRequestsRoom
} from "./services/fetchNotificationsRequests/fetchNotificationsRequestsRoom/fetchNotificationsRequestsRoom";
import {addNewNotification} from "./services/addNewNotification";
import {markReadeNotification} from "./services/markReadeNotification/markReadeNotification";


const initialState: INotificationsMain = {
    isLoading: false,
    numbOfNotifications: {
        newFriends: 0,
        newRequestInChats: 0,
        newRequestInRooms: 0
    },
    notificationIsLoading: [],
    // notificationStart: {
    //     id: 578,
    //     event: "SCHEDULE_START",
    //     is_self: 0,
    //     user_id: 2,
    //     date_creat: "2023-08-10 11:38:01",
    //
    //     schedule: {
    //         date: "2023-08-10",
    //         time_end: "13:00:00",
    //         time_start: "1:38:00",
    //         spec: {
    //             email: "tera1134@gmail.com",
    //             id: 2,
    //             name: "Экспертный эксперт",
    //             nickname: "my_nickname",
    //             personal_id: "6ffc9e06b0f3fc3eebedee4909014dfd",
    //             user_group_id: 4,
    //             verified_email: "",
    //         },
    //         id: 112,
    //         name: "КонференцияКонференцияКонференцияКонференцияКонференция Конференция Конференция Конференция Конференция ",
    //         room: {
    //             id: 4,
    //             name: " Ламповыей Конференция Конференция Конференция КонференцияКонференция Конференция",
    //         },
    //     },
    // }
}

export const notificationsSlice = createSlice({
    name: 'notificationsSlice',
    initialState,
    reducers: {
        addNewNotification(state, action: PayloadAction<INotification>) {
            if(action.payload.event === 'SCHEDULE_START') {
                state.notificationStart = action.payload as INotificationStart
            } else {
                addNewNotification(state, action.payload)
            }
        },
        clearNotification(state, action: PayloadAction<number>) {
            if (state.notifications) {
                state.notifications = state.notifications.filter(nt => nt.id !== action.payload)
            }
            state.numbOfNotifications.newFriends = 0
        },
        clearAddNewFriends(state) {
            if (state.notifications) {
                state.notifications = state.notifications.filter(nt => nt.event !== 'USER_RELATIONSHIP_FRIEND_NEW')
            }
            state.numbOfNotifications = {
                ...state.numbOfNotifications,
                newFriends: 0
            }
        },
        clearRequestsInChats(state) {
            // if (state.notifications) {
            //     state.notifications = state.notifications.filter(nt => nt.event !== 'USER_RELATIONSHIP_FRIEND_NEW')
            // }
            state.numbOfNotifications = {
                ...state.numbOfNotifications,
                newRequestInChats: 0
            }
        },
        addIdInNotificationIsLoading(state, action: PayloadAction<number>) {
            state.notificationIsLoading = [...state.notificationIsLoading, action.payload]
        },
        addNotificationStart(state, action: PayloadAction<INotificationStart>) {
            state.notificationStart = action.payload
        },
        clearNotificationStart(state) {
            state.notificationStart = undefined
        },
        removeIdInNotificationIsLoading(state, action: PayloadAction<number>) {
            state.notificationIsLoading = state.notificationIsLoading.filter(id => id !== action.payload)
        },
        clearRequestsInRooms(state) {
            // if (state.notifications) {
            //     state.notifications = state.notifications.filter(nt => nt.event !== 'USER_RELATIONSHIP_FRIEND_NEW')
            // }
            state.numbOfNotifications = {
                ...state.numbOfNotifications,
                newRequestInRooms: 0
            }
        },
        addNotificationPersonalMessage(state, action: PayloadAction<INotificationPrivateChat>) {
            state.notificationPersonalChat = action.payload
        },
        clearNotificationPersonalChat(state) {
            state.notificationPersonalChat = undefined
        },
        clearNotificationsData(state) {
            state.notifications = undefined
            state.isLoading = false
            state.error = undefined
            state.notificationIsLoading = []
            state.notificationStart = undefined
            state.notificationPersonalChat = undefined
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchNotificationsCollection.pending, (state) => {
                state.isLoading = true
                state.error = undefined
            })
            .addCase(fetchNotificationsCollection.fulfilled, (state, action: PayloadAction<INotification[]>) => {
                if (!!action.payload && action.payload.length > 0) {
                    action.payload.forEach(nt => {
                        if (nt.event === 'SCHEDULE_START') {
                            state.notificationStart = nt as INotificationStart
                        } else {
                            if (nt.event === 'SCHEDULE_END') {
                                state.notificationStart = undefined
                            }
                            addNewNotification(state, nt)
                        }
                    })
                }
                state.isLoading = false
            })
            .addCase(fetchNotificationsCollection.rejected, (state, action) => {
                state.error = action.payload
                state.isLoading = false
            })

            //friend requests
            .addCase(fetchNotificationsRequestsFriend.pending, (state) => {
                state.isLoading = true
                state.error = undefined
            })
            .addCase(fetchNotificationsRequestsFriend.fulfilled, (state, action: PayloadAction<number>) => {
                if (action.payload > 0) {
                    state.numbOfNotifications.newFriends = action.payload
                }
            })
            .addCase(fetchNotificationsRequestsFriend.rejected, (state, action) => {
                state.error = action.payload
                state.isLoading = false
            })

            //room requests
            .addCase(fetchNotificationsRequestsChat.pending, (state) => {
                state.isLoading = true
                state.error = undefined
            })
            .addCase(fetchNotificationsRequestsChat.fulfilled, (state, action: PayloadAction<number>) => {
                if (action.payload > 0) {
                    state.numbOfNotifications.newRequestInChats = action.payload
                }
            })
            .addCase(fetchNotificationsRequestsChat.rejected, (state, action) => {
                state.error = action.payload
                state.isLoading = false
            })

            //chat requests
            .addCase(fetchNotificationsRequestsRoom.pending, (state) => {
                state.isLoading = true
                state.error = undefined
            })
            .addCase(fetchNotificationsRequestsRoom.fulfilled, (state, action: PayloadAction<number>) => {
                if (action.payload > 0) {
                    state.numbOfNotifications.newRequestInRooms = action.payload
                }
            })
            .addCase(fetchNotificationsRequestsRoom.rejected, (state, action) => {
                state.error = action.payload
                state.isLoading = false
            })

            //mark reade notification
            .addCase(markReadeNotification.pending, (state) => {
                state.error = undefined
                state.isLoading = true
            })
            .addCase(markReadeNotification.fulfilled, (state, action: PayloadAction<INotification>) => {
                state.notifications = state.notifications?.filter(nt => nt.id !== action.payload.id)
                state.isLoading = false
            })
            .addCase(markReadeNotification.rejected, (state, action) => {
                const {id} = action.meta.arg
                if (id) {
                    state.notifications = state.notifications?.filter(nt => nt.id !== id)
                }
                state.error = action.payload
                state.isLoading = false
            })
    },
})


export const notificationsReducer = notificationsSlice.reducer
export const notificationsAction = notificationsSlice.actions


