import {ChangeEvent, HTMLInputTypeAttribute} from 'react';
import s from './InputEffect.module.css'

interface IInputEffect {
    title: string
    value: string
    onBlur: () => void
    onChange: (e: ChangeEvent<HTMLInputElement>) => void
    type?: HTMLInputTypeAttribute
    disabled?: boolean
}

export const InputEffect= (props:IInputEffect) => {
    const {
        title,
        value,
        onChange,
        onBlur,
        type = 'text',
        disabled
    } = props

    return (
        <div className={s.inputEffect}>
            <input className={`${s.inputCustom} ${value ? s.hasContent : ''}`}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                type={type}
                disabled={disabled}
            />
            <label>
                {title}
            </label>
            <span className={s.focusBorder}></span>
        </div>
    );
};
