import { $api } from "../../../../api/api";

let myInterceptor = 0;

export const tokenInterceptors = async (token: string) => {
    if(myInterceptor === 0) {
        myInterceptor = $api.interceptors.request.use(
            (config) => {
                config.headers['Authorization'] = `Bearer ${token}`;
                return config;
            },
            (error) => {
                return Promise.reject(error);
            }
        );
    }
};

export const removeTokenInterceptors = () => {
    $api.interceptors.request.eject(myInterceptor);
    myInterceptor = 0;
};
