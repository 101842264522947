import {FC, MutableRefObject, useRef} from 'react';
import s from './Container.module.css'
import {classNames, Mods} from "../../utils/helpers/classNames/classNames";
import { useInfiniteScroll } from '../../hooks/useInfiniteScroll';

type Variant = 'main' | 'noVerticalPadding' | 'chat'

interface IContainer {
    children: React.ReactNode
    className?: string
    variant?: Variant
    isCrumbs?: boolean
    onScrollEnd?: () => void
}

export const Container: FC<IContainer> = ({
    children,
    className,
    isCrumbs = true,
    variant,
    onScrollEnd
}) => {
    const mods: Mods = {
        [s.crumbs]: isCrumbs,
    };
    const wrapperRef = useRef() as MutableRefObject<HTMLDivElement>;
    const triggerRef = useRef() as MutableRefObject<HTMLDivElement>;

    useInfiniteScroll({
        triggerRef,
        wrapperRef,
        callback: onScrollEnd,
    });

    return (
        <section
            className={classNames(s.container, mods, [className as string, s[variant ? variant : '']])}
            ref={wrapperRef}
        >
            {children}

            {onScrollEnd
                ? <div className={s.trigger} ref={triggerRef}></div>
                : null}
        </section>
    );
};
