import {RouteProps} from 'react-router-dom';
import {MainAsync} from "../../pages/Main/Main.async";

import {ProfileAsync} from "../../pages/Profile/Profile.async";
import {ProfileChatsAsync} from "../../pages/ProfileChats/ProfileChats.async";
import {ProfileSettingsAsync} from "../../pages/ProfileSettings/ProfileSettings.async";
import {ProfileRoomsAsync} from "../../pages/ProfileRooms/ProfileRooms.async";
import {ProfileMessagesAsync} from "../../pages/ProfileMessages/ProfileMessages.async";
import {ProfileFriendsAsync} from "../../pages/ProfileFriends/ProfileFriends.async";
import {ProfileFindFriendsAsync} from "../../pages/ProfileFindFriends/ProfileFindFriends.async";
import {ProfileCabinetAsync} from "../../pages/ProfileCabinet/Profile.async";
import {SpecialistChatsAsync} from "../../pages/SpecialistChats/SpecialistChats.async"
import {SpecialistRoomsAsync} from "../../pages/SpecialistRooms/SpecialistRooms.async";
import {SpecialistFriendsAsync} from "../../pages/SpecialistFriends/SpecialistFriends.async";
import {
    SpecialistFindFriendsAsync
} from "../../pages/SpecialistFindFriends/SpecialistFindFriends.async";
import {SpecialistMessagesAsync} from "../../pages/SpecialistMessages/SpecialistMessages.async";
import {SpecialistCabinetAsync} from "../../pages/SpecialistCabinet/SpecialistCabinet.async";
import {SpecialistAsync} from "../../pages/Specialist/Specialist.async";
import {SpecialistSettingsAsync} from "../../pages/SpecialistSettings/SpecialistSettings.async";
import {ChatAsync} from "../../pages/Chat/Chat.async";
import {TestPageStepAsync} from "../../pages/TestPageStep/TestPageStep.async";
import {AboutAsync} from "../../pages/About/About.async";
import {MissionAsync} from "../../pages/Mission/Mission.async";
import {VirtualAboutAsync} from "../../pages/VirtualAbout/VirtualAbout.async";
import { RoomAsync } from '../../pages/Room/Room.async';
import {SpecialistsFindAsync} from "../../pages/SpecialistsFind/SpecialistsFind.async";
import {ChatViewAsync} from "../../pages/ChatView/ChatView.async";
import {RoomViewAsync} from "../../pages/RoomView/RoomView.async";
import { ProfileUserAsync } from '../../pages/ProfileUser/ProfileUser.async';
import { SpecialistProfileAsync } from '../../pages/SpecialistProfile/SpecialistProfile.async';
import {PageNotFoundAsync} from "../../pages/Error/PageNotFound.async";
import { SpecialistVerificationAsync } from '../../pages/SpecialistVerification/SpecialistVarification.async';
import {SpecialistsResultAsync} from "../../pages/SpecialistsResult/SpecialistsResult.async";
import { TwilioCallsAsync } from '../../components/twilioCalls/TwilioCallsApp.async';


export type AppRoutersProps = RouteProps & {
    authOnlyUser?: boolean
    authOnlySpecialist?: boolean
}

export enum AppRoutes {
    MAIN = 'main',
    MISSION_PAGE = 'mission',
    VIRTUAL_REHABILITATION_PAGE = 'virtual_rehabilitation',
    ABOUT_PAGE = 'about',

    //login
    // RECOVER_PASSWORD_PAGE = 'login',
    // LOGIN_PAGE = 'recover_password',
    // REGISTRATION_PAGE = 'registration',

    //room
    ROOM_PAGE = 'room_page',
    ROOM_VIEW_PAGE = 'room_view', // +id

    //chat
    CHAT_PAGE = 'chat_page',
    CHAT_VIEW_PAGE = 'chat_view',// +id

    //test
    TEST_PAGE_STEP = 'test_page_step',

    //find specialists
    SPECIALISTS_FIND = 'specialists_find',
    SPECIALISTS_RESULT = 'specialists_result',

    //profile
    PROFILE_PAGE = "profile",
    PROFILE_CHATS_PAGE = "profile_chats",
    PROFILE_ROOMS_PAGE = "profile_rooms",
    PROFILE_FRIENDS_PAGE = "profile_friends",
    PROFILE_FIND_FRIENDS_PAGE = "profile_friends_search",
    PROFILE_MESSAGES_PAGE = "profile_messages",
    PROFILE_CABINET_PAGE = "profile_cabinet",
    PROFILE_SETTING_PAGE = "profile_setting",

    PROFILE_USER_PAGE = "profile_user", // +id

    //specialist
    SPECIALIST_PAGE = "specialist",
    SPECIALIST_PROFILE_CHATS_PAGE = "specialist_chats",
    SPECIALIST_PROFILE_ROOMS_PAGE = "specialist_rooms",
    SPECIALIST_PROFILE_FRIENDS_PAGE = "specialist_friends",
    SPECIALIST_PROFILE_FIND_FRIENDS_PAGE = "specialist_friends_search",
    SPECIALIST_PROFILE_MESSAGES_PAGE = "specialist_messages",
    SPECIALIST_PROFILE_CABINET_PAGE = "specialist_cabinet",
    SPECIALIST_PROFILE_SETTING_PAGE = "specialist_setting",
    SPECIALIST_PROFILE_VERIFICATION_PAGE = "specialist_verification",

    SPECIALIST_PROFILE_PAGE = "specialist_profile", // +id

    //calls
    GROUP_CALLS = 'calls', // +id


    // last
    NOT_FOUND = 'not_found'
}

export const RoutePath: Record<AppRoutes, string> = {
    [AppRoutes.MAIN]: '/',
    [AppRoutes.MISSION_PAGE]: '/mission',
    [AppRoutes.VIRTUAL_REHABILITATION_PAGE]: '/virtual-rehabilitation',
    [AppRoutes.ABOUT_PAGE]: '/about',

    //login
    // [AppRoutes.RECOVER_PASSWORD_PAGE]: '/login',
    // [AppRoutes.LOGIN_PAGE]: '/recover-password',
    // [AppRoutes.REGISTRATION_PAGE]: '/registration',

    //room
    [AppRoutes.ROOM_PAGE]: '/room-page',
    [AppRoutes.ROOM_VIEW_PAGE]: '/room-page/',

    //chat
    [AppRoutes.CHAT_PAGE]: '/chat-page',
    [AppRoutes.CHAT_VIEW_PAGE]: '/chat-page/',

    //test
    [AppRoutes.SPECIALISTS_FIND]: '/specialists-find',
    [AppRoutes.TEST_PAGE_STEP]: '/test-page-step',
    [AppRoutes.SPECIALISTS_RESULT]: '/specialists-result', // +params

    //profile
    [AppRoutes.PROFILE_PAGE]: '/profile',
    [AppRoutes.PROFILE_CHATS_PAGE]: '/profile-chats',
    [AppRoutes.PROFILE_ROOMS_PAGE]: '/profile-rooms',
    [AppRoutes.PROFILE_FRIENDS_PAGE]: '/profile-friends',
    [AppRoutes.PROFILE_FIND_FRIENDS_PAGE]: '/profile-friends-search',
    [AppRoutes.PROFILE_MESSAGES_PAGE]: '/profile-messages',
    [AppRoutes.PROFILE_CABINET_PAGE]: '/profile-cabinet',
    [AppRoutes.PROFILE_SETTING_PAGE]: '/profile-setting',

    [AppRoutes.PROFILE_USER_PAGE]: '/profile-user/',// +id

    //specialist
    [AppRoutes.SPECIALIST_PAGE]: '/specialist',
    [AppRoutes.SPECIALIST_PROFILE_CHATS_PAGE]: '/specialist-chats',
    [AppRoutes.SPECIALIST_PROFILE_ROOMS_PAGE]: '/specialist-rooms',
    [AppRoutes.SPECIALIST_PROFILE_FRIENDS_PAGE]: '/specialist-friends',
    [AppRoutes.SPECIALIST_PROFILE_FIND_FRIENDS_PAGE]: '/specialist-friends-search',
    [AppRoutes.SPECIALIST_PROFILE_MESSAGES_PAGE]: '/specialist-messages',
    [AppRoutes.SPECIALIST_PROFILE_CABINET_PAGE]: '/specialist-cabinet',
    [AppRoutes.SPECIALIST_PROFILE_SETTING_PAGE]: '/specialist-setting',
    [AppRoutes.SPECIALIST_PROFILE_VERIFICATION_PAGE]: '/specialist-verification',

    [AppRoutes.SPECIALIST_PROFILE_PAGE]: '/specialist-profile/', // +id


    //calls
    [AppRoutes.GROUP_CALLS]: '/calls/',// +id

    // last
    [AppRoutes.NOT_FOUND]: '/*',
};

export const routerConfig: Record<AppRoutes, AppRoutersProps> = {
    [AppRoutes.MAIN]: {
        path: RoutePath.main,
        element: <MainAsync/>,
    },
    [AppRoutes.MISSION_PAGE]: {
        path: RoutePath.mission,
        element: <MissionAsync/>,
    },
    [AppRoutes.VIRTUAL_REHABILITATION_PAGE]: {
        path: RoutePath.virtual_rehabilitation,
        element: <VirtualAboutAsync/>,
    },
    [AppRoutes.ABOUT_PAGE]: {
        path: RoutePath.about,
        element: <AboutAsync/>,
    },

    //login
    // [AppRoutes.RECOVER_PASSWORD_PAGE]: {
    //     path: RoutePath["recover-password"],
    //     element: <Rec />,
    // },

    //Room
    [AppRoutes.ROOM_PAGE]: {
        path: RoutePath.room_page,
        element: <RoomAsync/>,
    },
    [AppRoutes.ROOM_VIEW_PAGE]: {
        path: `${RoutePath.room_view}:id`,
        element: <RoomViewAsync/>,
    },

    // //Chat
    [AppRoutes.CHAT_PAGE]: {
        path: RoutePath.chat_page,
        element: <ChatAsync/>,
    },
    [AppRoutes.CHAT_VIEW_PAGE]: {
        path: `${RoutePath.chat_view}:id`,
        element: <ChatViewAsync/>,
    },

    // //Test
    [AppRoutes.SPECIALISTS_FIND]: {
        path: RoutePath.specialists_find,
        element: <SpecialistsFindAsync/>,
    },
    [AppRoutes.SPECIALISTS_RESULT]: {
        path: RoutePath.specialists_result,
        element: <SpecialistsResultAsync/>,
    },
    [AppRoutes.TEST_PAGE_STEP]: {
        path: RoutePath.test_page_step,
        element: <TestPageStepAsync/>,
    },

    //Profile
    [AppRoutes.PROFILE_PAGE]: {
        path: RoutePath.profile,
        element: <ProfileAsync/>,
        authOnlyUser: true
    },
    [AppRoutes.PROFILE_CHATS_PAGE]: {
        path: RoutePath.profile_chats,
        element: <ProfileChatsAsync/>,
        authOnlyUser: true
    },
    [AppRoutes.PROFILE_ROOMS_PAGE]: {
        path: RoutePath.profile_rooms,
        element: <ProfileRoomsAsync/>,
        authOnlyUser: true
    },
    [AppRoutes.PROFILE_FRIENDS_PAGE]: {
        path: RoutePath.profile_friends,
        element: <ProfileFriendsAsync/>,
        authOnlyUser: true
    },
    [AppRoutes.PROFILE_FIND_FRIENDS_PAGE]: {
        path: RoutePath.profile_friends_search,
        element: <ProfileFindFriendsAsync/>,
        authOnlyUser: true
    },
    [AppRoutes.PROFILE_MESSAGES_PAGE]: {
        path: RoutePath.profile_messages,
        element: <ProfileMessagesAsync/>,
        authOnlyUser: true
    },
    [AppRoutes.PROFILE_CABINET_PAGE]: {
        path: RoutePath.profile_cabinet,
        element: <ProfileCabinetAsync/>,
        authOnlyUser: true
    },
    [AppRoutes.PROFILE_SETTING_PAGE]: {
        path: RoutePath.profile_setting,
        element: <ProfileSettingsAsync/>,
        authOnlyUser: true
    },

    //show user profile
    [AppRoutes.PROFILE_USER_PAGE]: {
        path: `${RoutePath.profile_user}:id`,
        element: <ProfileUserAsync/>,
    },


    // //Specialist
    [AppRoutes.SPECIALIST_PAGE]: {
        path: RoutePath.specialist,
        element: <SpecialistAsync/>,
        authOnlySpecialist: true
    },
    [AppRoutes.SPECIALIST_PROFILE_CHATS_PAGE]: {
        path: RoutePath.specialist_chats,
        element: <SpecialistChatsAsync/>,
        authOnlySpecialist: true
    },
    [AppRoutes.SPECIALIST_PROFILE_ROOMS_PAGE]: {
        path: RoutePath.specialist_rooms,
        element: <SpecialistRoomsAsync/>,
        authOnlySpecialist: true
    },
    [AppRoutes.SPECIALIST_PROFILE_FRIENDS_PAGE]: {
        path: RoutePath.specialist_friends,
        element: <SpecialistFriendsAsync/>,
        authOnlySpecialist: true
    },
    [AppRoutes.SPECIALIST_PROFILE_FIND_FRIENDS_PAGE]: {
        path: RoutePath.specialist_friends_search,
        element: <SpecialistFindFriendsAsync/>,
        authOnlySpecialist: true
    },
    [AppRoutes.SPECIALIST_PROFILE_MESSAGES_PAGE]: {
        path: RoutePath.specialist_messages,
        element: <SpecialistMessagesAsync/>,
        authOnlySpecialist: true
    },
    [AppRoutes.SPECIALIST_PROFILE_CABINET_PAGE]: {
        path: RoutePath.specialist_cabinet,
        element: <SpecialistCabinetAsync/>,
        authOnlySpecialist: true
    },
    [AppRoutes.SPECIALIST_PROFILE_SETTING_PAGE]: {
        path: RoutePath.specialist_setting,
        element: <SpecialistSettingsAsync/>,
        authOnlySpecialist: true
    },
    [AppRoutes.SPECIALIST_PROFILE_VERIFICATION_PAGE]: {
        path: RoutePath.specialist_verification,
        element: <SpecialistVerificationAsync/>,
        authOnlySpecialist: true
    },

    //show specialist profile
    [AppRoutes.SPECIALIST_PROFILE_PAGE]: {
        path: `${RoutePath.specialist_profile}:id`,
        element: <SpecialistProfileAsync/>,
    },

    //calls
    [AppRoutes.GROUP_CALLS]: {
        path: `${RoutePath.calls}:id`,
        element: <TwilioCallsAsync/>
    },

    // last
    [AppRoutes.NOT_FOUND]: {
        path: RoutePath.not_found,
        element: <PageNotFoundAsync/>,
    },
};
