import {createAsyncThunk} from '@reduxjs/toolkit';
import {ThunkConfig} from "../../../../config/StateSchema";
import {BaseResponse} from "../../../../../api/api";
import {profileInfoAction} from "../../../../profileInfo/profileInfoSlice";
import {snackbarAction} from "../../../../errors/SnackbarSlice";


interface IResponse {
    idRelationship: number
}

export const deleteProfileBlackList = createAsyncThunk<true,
    IResponse,
    ThunkConfig<string>>(
        'profile/deleteProfileBlackList',
        async ({idRelationship}, thunkAPI) => {
            const {extra, rejectWithValue, dispatch} = thunkAPI;
            try {
                const response = await extra.api.delete<BaseResponse>(`relationship/blacklist/${idRelationship}`,)
                if (!response.data.success) {
                    return rejectWithValue('error')
                }
                dispatch(profileInfoAction.setRelationCurrentUser())
                return response.data.success
            } catch (err) {
                dispatch(snackbarAction.addError('INTERNAL_ERROR'))
                return rejectWithValue('error');
            }
        },
    );
