import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export type NavbarShowType = {
    isShowNavbar: boolean
    isShowNavbarDesktop: boolean
    isShowNavbarBurger: boolean
}

const initialState: NavbarShowType = {
    isShowNavbar: true,
    isShowNavbarBurger: true,
    isShowNavbarDesktop: true,
}


export const navbarSlice = createSlice({
    name: 'navbar',
    initialState,
    reducers: {
        changeShowNavbarDesktop (state, action: PayloadAction<boolean>) {
            state.isShowNavbarDesktop = action.payload
        },
        changeShowNavbarBurger(state, action: PayloadAction<boolean>) {
            state.isShowNavbarBurger = action.payload
        },
        changeShowNavbar(state, action: PayloadAction<boolean>) {
            state.isShowNavbar = action.payload
        },
    },
    extraReducers: () => {
    },
})


export const navbarReducer = navbarSlice.reducer
export const navbarAction = navbarSlice.actions


