import s from './LoaderModal.module.css';
import {classNames} from "../../utils/helpers/classNames/classNames";

type LoaderModalPropsType = {
    className?: string
    variant?: 'basic' | 'calendar' | 'page'
}

export const LoaderModal = ({className, variant = 'basic'}: LoaderModalPropsType) => {
    return (
        <div className={classNames(s.ldsBox, {}, [className, s[variant]])}>
            <div className={s.ldsRing}>
                <div/>
                <div/>
                <div/>
                <div/>
            </div>
        </div>
    );
}
