import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export type FooterShowType = {
    isShowFooter: boolean
}

const initialState: FooterShowType = {
    isShowFooter: true
}

export const footerSlice = createSlice({
    name: 'footer',
    initialState,
    reducers: {
        changeShowFooter(state, action: PayloadAction<boolean>) {
            state.isShowFooter = action.payload
        },
    },
    extraReducers: () => {
    },
})


export const footerReducer = footerSlice.reducer
export const footerAction = footerSlice.actions


