import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IPersonalChat} from "./types/personalChatTypes";
import {fetchPrivateChatById} from "./services/fetchPrivateChatById/fetchPrivateChatById";
import defIcon from '../../../utils/images/avatars/02.svg'
import {Client} from "@twilio/conversations";
import {IChatsCollection} from "../chats/types/chatsTypes";
import {
    fetchMyPrivateChatsCollection
} from "./services/fetchMyPrivateChatsCollection/fetchMyPrivateChatsCollection";
import {IChatItem} from "../chatItem/types/chatItemTypes";
import {
    fetchMyPrivateChatsCollectionStart
} from "./services/fetchMyPrivateChatsCollectionStart/fetchMyPrivateChatsCollectionStart";

const initialState: IPersonalChat = {
    error: undefined,
    isLoading: true,
    isLoadingPersonalChat: false,
    isMounted: false,
    myCollection: []
}

export const personalChats = createSlice({
    name: 'personalChats',
    initialState,
    reducers: {
        changeChatTokenTwilio(state, action: PayloadAction<string>) {
            state.chatTokenTwilio = action.payload
        },
        setIsLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload
        },
        setIsShowRules(state, action: PayloadAction<boolean>) {
            state.isShowRules = action.payload
        },
        setIsLoadingChat(state, action: PayloadAction<boolean>) {
            state.isLoadingPersonalChat = action.payload
        },
        clearChatItem(state) {
            state.chat = undefined
            state.chatTokenTwilio = undefined
        },
        setClient(state, action: PayloadAction<Client>) {
            // @ts-ignore
            state.client = action.payload
        },
        resetLoaders(state) {
            state.isLoadingPersonalChat = true
            state.isLoading = true
        },
        resetLoadersPrivateChats(state) {
            state.isLoadingPersonalChat = true
            state.isLoading = true
            state.isShowRules = false
            state.chat = undefined
            state.chatTokenTwilio = undefined
            state.isMounted = false
            state.myCollection= []
        },
    },
    extraReducers: (builder) => {
        builder

            .addCase(fetchPrivateChatById.pending, (state) => {
                state.isLoading = true
                state.error = undefined
            })
            .addCase(fetchPrivateChatById.fulfilled, (state, action: PayloadAction<IChatItem>) => {
                state.chat = {
                    ...action.payload,
                    icon: action.payload.icon || defIcon
                }
                state.isLoading = false
            })
            .addCase(fetchPrivateChatById.rejected, (state, action) => {
                state.error = action.payload
                state.isLoading = false
            })


            .addCase(fetchMyPrivateChatsCollection.pending, (state) => {
                state.isLoading = true
                state.error = undefined
            })
            .addCase(fetchMyPrivateChatsCollection.fulfilled, (state, action: PayloadAction<IChatsCollection[] | null>) => {
                if (action.payload) {
                    state.myCollection = [
                        ...action.payload
                    ]
                }
                state.isLoading = false
                if (!state.isMounted) {
                    state.isMounted = true
                }
            })
            .addCase(fetchMyPrivateChatsCollection.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.payload
            })



            .addCase(fetchMyPrivateChatsCollectionStart.pending, (state) => {
                state.error = undefined
            })
            .addCase(fetchMyPrivateChatsCollectionStart.fulfilled, (state, action: PayloadAction<IChatsCollection[] | null>) => {
                if (action.payload) {
                    state.myCollection = [
                        ...action.payload
                    ]
                }
                if (!state.isMounted) {
                    state.isMounted = true
                }
            })
            .addCase(fetchMyPrivateChatsCollectionStart.rejected, (state, action) => {
                state.error = action.payload
            })

    },
}
)


export const personalChatsReducer = personalChats.reducer
export const personalChatsAction = personalChats.actions


