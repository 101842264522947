import {Dispatch, LegacyRef, SetStateAction, useEffect, useRef, useState} from "react";

type TypeOut = {
    ref: LegacyRef<HTMLDivElement> | undefined
    isShow: boolean
    setIsShow: Dispatch<SetStateAction<boolean>>
}

export const useOutside = (initialIsVisible: boolean): TypeOut => {
    const [isShow, setIsShow] = useState(initialIsVisible)
    const ref = useRef<HTMLDivElement>(null)
    const handlerClickOutside = (event: any) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setIsShow(false)
        }
    }

    useEffect(() => {
        document.addEventListener('click', handlerClickOutside, true)
        return () => {
            document.addEventListener('click', handlerClickOutside, true)
        }
    })
    // @ts-ignore
    return {ref, isShow, setIsShow}
}

