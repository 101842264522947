import {createAsyncThunk} from '@reduxjs/toolkit';
import {ISignUp} from "../../types/authTypes";
import {ThunkConfig} from "../../../config/StateSchema";
import {fetchProfileSelf} from "../../../profile/services/fetchProfileSelf/fetchProfileSelf";
import {IErrorAuthRegister} from "../../types/IAuthProfile";
import {removeToken, setToken} from "../tokenManagement/tokenManagement";
import {snackbarAction} from "../../../errors/SnackbarSlice";

export const fetchAuthRegister = createAsyncThunk<boolean,
    ISignUp, ThunkConfig<IErrorAuthRegister>>(
        'auth/fetchAuthRegister',
        async (authData, thunkAPI) => {
            const {extra, rejectWithValue, dispatch} = thunkAPI;
            await removeToken()
            try {
                const response = await extra.api.post(
                    `/auth/register`,
                    authData);
                if (!response.data.success) {
                    return rejectWithValue(response.data.response.errors)
                }
                await setToken(response.data.response.access_token)
                dispatch(fetchProfileSelf(response.data.response.access_token))
                return true
            } catch (err) {
                dispatch(snackbarAction.addError('INTERNAL_ERROR'))
                // @ts-ignore
                return rejectWithValue('error fetchAuthRegister');
            }
        },
    );
