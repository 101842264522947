import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IChatItem, IChatItemMain} from "./types/chatItemTypes";
import {fetchChatById} from "./services/fetchChatById/fetchChatById";
import defIcon from '../../../utils/images/avatars/02.svg'

const initialState: IChatItemMain = {
    error: undefined,
    isLoading: true,
    isLoadingChat: false,
    isMounted: false
}

export const chatItemSlice = createSlice({
    name: 'chatItem',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload
        },
        setIsShowRules(state, action: PayloadAction<boolean>) {
            state.isShowRules = action.payload
        },
        setIsLoadingChat(state, action: PayloadAction<boolean>) {
            state.isLoadingChat = action.payload
        },
        setIsMounted(state, action: PayloadAction<boolean>) {
            state.isMounted = action.payload
        },
        clearChatItem(state) {
            state.chat = undefined
        },
        resetLoaders(state) {
            state.isLoadingChat = true
            state.isLoading = true
            state.isShowRules = false
            state.chat = undefined
            state.isMounted = false
        },
    },
    extraReducers: (builder) => {
        builder

        //fetch chat by id
            .addCase(fetchChatById.pending, (state) => {
                state.isLoading = true
                state.error = undefined
            })
            .addCase(fetchChatById.fulfilled, (state, action: PayloadAction<IChatItem>) => {
                state.chat = {
                    ...action.payload,
                    icon: action.payload.icon || defIcon
                }
                state.isLoading = false
            })
            .addCase(fetchChatById.rejected, (state, action) => {
                state.error = action.payload
                state.isLoading = false
            })


    },
}
)


export const chatItemReducer = chatItemSlice.reducer
export const chatItemAction = chatItemSlice.actions


