import {createSlice, DeepPartial, PayloadAction} from "@reduxjs/toolkit";
import {fetchProfileSelf} from "./services/fetchProfileSelf/fetchProfileSelf";
import {
    IProfileInitialState,
    IProfileSpec,
    IProfileUser,
    ISearchUsers,
    IUserRelationship,
    IVerification,
    Relationship,
    UserGroup,
} from "./types/profileTypes";
import {IResponseUpdateProfile, updateProfile} from "./services/updateProfile/updateProfile";
import {updateProfileAvatar} from "./services/updateProfileAvatar/updateProfileAvatar";
import devAvatar from '../../../utils/images/avatars/01.svg'
import {fetchProfileSelfRepeat} from "./services/fetchProfileSelfRepeat/fetchProfileSelfRepeat";
import {
    updateProfileVerification
} from "./services/updateProfileVerification/updateProfileVerification";
import {Errors} from "../../api/api";
import {fetchProfileClients,} from "./services/clients/fetchProfileClients.ts/fetchProfileClients";
import {searchProfileFriends} from "./services/search/searchProfileFriends/searchProfileFriends";
import {
    searchProfileFriendsByOffset
} from "./services/search/searchProfileFriendsByOffset/searchProfileFriendsByOffset";
import {
    deleteProfileRelationship
} from "./services/deleteProfileRelationship/deleteProfileRelationship";
import {fetchProfileFriends} from "./services/friend/fetchProfileFriends/fetchProfileFriends";
import {
    fetchProfileFriendsByOffset
} from "./services/friend/fetchProfileFriendsByOffset/fetchProfileFriendsByOffset";
import {
    fetchProfileBlackList
} from "./services/blackList/fetchProfileBlackList/fetchProfileBlackList";
import {
    fetchProfileBlackListByOffset
} from "./services/blackList/fetchProfileBlackListByOffset/fetchProfileBlackListByOffset";
import {declineProfileFriend} from "./services/friend/declineProfileFriend/declineProfileFriend";
import {
    deleteProfileBlackList
} from "./services/blackList/deleteProfileBlackList/deleteProfileBlackList";
import {addProfileFriend} from "./services/friend/addProfileFriend/addProfileFriend";
import {addProfileBlackList} from "./services/blackList/addProfileBlackList/addProfileBlackList";
import {acceptProfileFriend} from "./services/friend/acceptProfileFriend/acceptProfileFriend";
import {
    fetchProfileClientsByOffset
} from "./services/clients/fetchProfileClientsByOffset.ts/fetchProfileClientsByOffset";
import {
    checkRelationshipWithUser
} from "./services/user/checkRelationshipWithUser/checkRelationshipWithUser";
import {StatusFilterCabinetType, StatusFilterProfileSpecialist} from "../../../models/ITabsFilter";
import {
    fetchProfileEventsMyCollection
} from "./services/fetchProfileEventsMyCollection/fetchProfileEventsMyCollection";
import {ICalendarResponse} from "../scheduler/types/IScheduler";
import moment from "moment";

const initialState: IProfileInitialState = {
    errors: undefined,
    isLoading: false,
    isLoadingProfile: false,
    hasMore: true,
    isMounted: false,
    isDataAvailable: false
}

export const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        updateProfile(state, action: PayloadAction<DeepPartial<IProfileSpec | IProfileUser>>) {
            state = {
                ...state,
                ...action.payload,
            }
        },
        updateVerification(state, action: PayloadAction<DeepPartial<IVerification>>) {
            state.verification = {
                ...state.verification,
                ...action.payload,
            }
        },
        changeUserPic(state, action: PayloadAction<{ userPic: string }>) {
            if (state.profile) {
                state.profile.avatar = action.payload.userPic;
            }
        },
        setIsMounted(state, action: PayloadAction<boolean>) {
            state.isMounted = action.payload
        },
        setStatusFilterFriends(state, action: PayloadAction<StatusFilterProfileSpecialist>) {
            state.statusFilterFriends = action.payload
        },
        setStatusFilterCabinet(state, action: PayloadAction<StatusFilterCabinetType>) {
            state.statusFilterCabinet = action.payload
        },
        changeError(state, action: PayloadAction<Errors<IVerification>>) {
            if (state.verification) {
                state.verification.errorsVerification = {
                    ...action.payload
                }
            } else {
                state.verification = {
                    errorsVerification: {
                        ...action.payload
                    }
                }
            }
        },
        clearUserRelationship(state) {
            state.isLoading = false
            state.errors = undefined
            state.userRelationship = undefined
        },
        clearProfile() {
            return {
                error: undefined,
                isLoading: false,
                isLoadingProfile: false,
                profile: undefined,
                hasMore: true,
                isMounted: false,
                isDataAvailable: false
            }
        },
        clearProfileInfo() {
            return {
                errors: undefined,
                isLoading: false,
                profile: undefined,
                isLoadingFriend: false,
                hasMore: true,
                isMounted: false,
                isDataAvailable: false,
                isLoadingProfile: false
            }
        },
        clearProfileClients() {
            return {
                errors: undefined,
                isLoading: false,
                clients: undefined,
                isLoadingFriend: false,
                hasMore: true,
                isMounted: false,
                isDataAvailable: false,
                isLoadingProfile: false
            }
        },
        clearProfileCabinet(state) {
            state.isLoading = false
            state.hasMore = true
            state.isMounted = false
            state.clients = undefined
            state.events = undefined
        },
        clearProfileFriend(state) {
            state.errors = undefined
            state.isLoading = false
            state.isLoadingProfile = false
            state.hasMore = true
            state.isMounted = false
            state.findFriends = undefined
            state.friends = undefined
            state.blackList = undefined
            state.clients = undefined
            state.isDataAvailable = false
            state.statusFilterFriends = undefined
            state.statusFilterCabinet = undefined
            state.events = undefined
        },
        clearProfileVerification(state) {
            state.errors = undefined
            state.isLoading = false
            state.verification = {
                ...state.verification,
                errorsVerification: undefined,
                successful: undefined
            }
        },
        clearRelationship(state) {
            state.friends = undefined
            state.clients = undefined
            state.blackList = undefined
        },
        clearProfileVerificationErrorAndSuccessful(state) {
            state.verification = {
                ...state.verification,
                errorsVerification: undefined,
                successful: undefined
            }
        }

    },
    extraReducers: (builder) => {
        builder
            // getProfileSelf
            .addCase(fetchProfileSelf.fulfilled, (state, action: PayloadAction<IProfileSpec | IProfileUser>) => {
                const {
                    avatar,
                    experience_text,
                    location,
                    phone_number,
                    demo_video_link,
                    about_text,
                    user_group_id,
                } = action.payload

                if (user_group_id === UserGroup.SPEC || user_group_id === UserGroup.SPEC_PREMIUM) {
                    const {
                        name,
                        language,
                        avatar,
                        attachments,
                        addictions_ids,
                        problems_ids,
                    } = action.payload

                    state.verification = {
                        name: name,
                        //убираем повторяющиеся элементы
                        language: Array.from(new Set(language)),
                        phone_number: phone_number,
                        about_text: about_text,
                        location: location,
                        demo_video_link: demo_video_link,
                        experience_text: experience_text,
                        avatar: avatar,
                        certificates: attachments?.filter(el => el.type === 2).map(el => el.path) || [],
                        passports: attachments?.filter(el => el.type === 3).map(el => el.path) || [],
                        addictions_ids: addictions_ids,
                        problems_ids: problems_ids
                    }
                }
                state.profile = {
                    ...action.payload,
                    avatar: avatar ? `${avatar}` : devAvatar
                }
                state.isLoading = false
                state.errors = undefined

            })


            //updateProfile
            .addCase(updateProfile.pending, (state) => {
                state.isLoading = true
                state.errors = undefined
            })
            .addCase(updateProfile.fulfilled, (state, action: PayloadAction<IResponseUpdateProfile>) => {
                state.profile = {
                    ...state.profile,
                    ...action.payload.info
                }
                if (action.payload.info.avatar) {
                    state.profile.avatar = `${action.payload.info.avatar}`
                }
                state.isLoading = false
            })
            .addCase(updateProfile.rejected, (state, action) => {
                state.isLoading = false
                state.errors = action.payload
            })

            //updateAvatar
            .addCase(updateProfileAvatar.pending, (state) => {
                state.isLoading = true
                state.errors = undefined
            })
            .addCase(updateProfileAvatar.fulfilled, (state, action: PayloadAction<string>) => {
                if (state.profile) {
                    state.profile.avatar = `${action.payload}`
                }
                state.isLoading = false
            })
            .addCase(updateProfileAvatar.rejected, (state, action) => {
                state.isLoading = false
                state.errors = action.payload
            })

            //verification
            .addCase(updateProfileVerification.pending, (state) => {
                state.isLoading = true
                state.errors = undefined
            })
            .addCase(updateProfileVerification.fulfilled, (state) => {
                state.isLoading = false
                if (state.verification) {
                    state.verification.successful = true
                } else {
                    state.verification = {
                        successful: true
                    }
                }
            })
            .addCase(updateProfileVerification.rejected, (state, action) => {
                state.isLoading = false
                if (state.verification) {
                    state.verification.errorsVerification = {
                        ...action.payload
                    }
                } else {
                    state.verification = {
                        errorsVerification: {
                            ...action.payload
                        }
                    }
                }

            })

            //repeat getProfileSet
            .addCase(fetchProfileSelfRepeat.fulfilled, (state, action: PayloadAction<IProfileSpec | IProfileUser>) => {
                const {
                    avatar,
                    experience_text,
                    location,
                    phone_number,
                    demo_video_link,
                    about_text,
                    user_group_id,
                } = action.payload

                if (user_group_id === UserGroup.SPEC || user_group_id === UserGroup.SPEC_PREMIUM) {
                    const {
                        name,
                        language,
                        avatar,
                        attachments,
                        addictions_ids,
                        problems_ids,
                    } = action.payload

                    state.verification = {
                        name: name,
                        //убираем повторяющиеся элементы
                        language: Array.from(new Set(language)),

                        phone_number: phone_number,
                        about_text: about_text,
                        location: location,
                        demo_video_link: demo_video_link,
                        experience_text: experience_text,
                        avatar: avatar,
                        certificates: attachments?.filter(el => el.type === 2).map(el => el.path) || [],
                        passports: attachments?.filter(el => el.type === 3).map(el => el.path) || [],
                        addictions_ids: addictions_ids,
                        problems_ids: problems_ids
                    }
                }
                state.profile = {
                    ...action.payload,
                    avatar: avatar ? `${avatar}` : devAvatar
                }
                state.isLoading = false
                state.errors = undefined
            })


        //clients

            //fetch clients
            .addCase(fetchProfileClients.pending, (state) => {
                state.isLoading = true
                state.errors = undefined
            })
            .addCase(fetchProfileClients.fulfilled, (state, action: PayloadAction<IUserRelationship[]>) => {
                state.isLoading = false
                state.clients = [...action.payload]
                state.isMounted = true
            })
            .addCase(fetchProfileClients.rejected, (state, action) => {
                state.isLoading = false
                state.errors = action.payload
            })

            //clients by offset
            .addCase(fetchProfileClientsByOffset.pending, (state) => {
                state.isLoadingProfile = true
                state.errors = undefined
            })
            .addCase(fetchProfileClientsByOffset.fulfilled, (state, action: PayloadAction<IUserRelationship[]>) => {
                state.isLoadingProfile = false
                if (state.clients) {
                    state.clients = [
                        ...state.clients,
                        ...action.payload
                    ]
                } else {
                    state.clients = [...action.payload]
                }
                state.hasMore = action.payload.length > 0
            })
            .addCase(fetchProfileClientsByOffset.rejected, (state, action) => {
                state.isLoadingProfile = false
                state.errors = action.payload
                state.errors = action.payload
            })



        //find Friends

            //searchFriends
            .addCase(searchProfileFriends.pending, (state) => {
                state.isLoading = true
                state.errors = undefined
            })
            .addCase(searchProfileFriends.fulfilled, (state, action: PayloadAction<ISearchUsers[]>) => {
                state.findFriends = [
                    ...action.payload
                ]
                if (!state.isMounted) {
                    state.isMounted = true
                }
                state.isLoading = false
                state.hasMore = action.payload.length > 0
            })
            .addCase(searchProfileFriends.rejected, (state, action) => {
                state.isLoading = false
                state.errors = action.payload
            })

            //fetch Friends by offset
            .addCase(searchProfileFriendsByOffset.pending, (state) => {
                state.isLoading = true
                state.errors = undefined
            })
            .addCase(searchProfileFriendsByOffset.fulfilled, (state, action: PayloadAction<ISearchUsers[]>) => {
                state.isLoading = false
                if (state.findFriends) {
                    state.findFriends = [
                        ...state.findFriends,
                        ...action.payload
                    ]
                } else {
                    state.findFriends = action.payload
                }
                state.hasMore = action.payload.length > 0
            })
            .addCase(searchProfileFriendsByOffset.rejected, (state, action) => {
                state.isLoading = false
                state.errors = action.payload
            })

        // //create Profile Relationship
        // .addCase(createProfileRelationship.pending, (state) => {
        //     state.isLoading = true
        //     state.errors = undefined
        // })
        // .addCase(createProfileRelationship.fulfilled, (state, action: PayloadAction<IClientsCollection>) => {
        //     state.isLoading = false
        //     if (action.payload.type === Relationship.FRIENDSHIP) {
        //         state.findFriends = state?.findFriends?.map(fr => fr.id === action.payload.user.id
        //             ? {
        //                 ...fr,
        //                 relation_current_user: {
        //                     type: action.payload.type,
        //                     id: action.payload.id,
        //                     user_id: action.payload.user.id
        //                 }
        //             }
        //             : fr)
        //     }
        //     if (action.payload.type === Relationship.BLACKLIST) {
        //         state.friends = state?.friends?.filter(fr => fr.user.id !== action.payload.user.id)
        //         state.findFriends = state?.findFriends?.filter(fr => fr.id !== action.payload.user.id)
        //     }
        // })
        // .addCase(createProfileRelationship.rejected, (state, action) => {
        //     state.isLoading = false
        //     state.errors = action.payload
        // })


        //Relationship

            //delete Profile Relationship
            .addCase(deleteProfileRelationship.pending, (state) => {
                state.isLoading = true
                state.errors = undefined
            })
            .addCase(deleteProfileRelationship.fulfilled, (state, action: PayloadAction<boolean>) => {
                state.isLoading = false
                // @ts-ignore
                const {idUser, idRelationship} = action.meta.arg
                if (idRelationship) {
                    state.friends = state.friends?.filter(fr => fr.id !== idRelationship)
                    state.blackList = state.blackList?.filter(bl => bl.id !== idRelationship)
                }
                if (idUser) {
                    state.findFriends = state?.findFriends?.map(fr => fr.id === idUser
                        ? {
                            ...fr,
                            relation_current_user: undefined
                        }
                        : fr)
                }
            })
            .addCase(declineProfileFriend.rejected, (state, action) => {
                state.isLoading = false
                state.errors = action.payload
            })


        //friends

            //fetch Profile Friends
            .addCase(fetchProfileFriends.pending, (state) => {
                state.isLoading = true
                state.errors = undefined
            })
            .addCase(fetchProfileFriends.fulfilled, (state, action: PayloadAction<IUserRelationship[]>) => {
                state.friends = [
                    ...action.payload
                ]
                // @ts-ignore
                const {status} = action.meta.arg
                if (!state.isMounted) {
                    if (status === 0) {
                        state.isDataAvailable = action.payload?.filter(fr => fr.incoming).length > 0
                    } else {
                        state.isDataAvailable = action.payload.length > 0
                    }
                    state.isMounted = true
                }
                state.isLoading = false
                state.hasMore = action.payload.length > 0
            })
            .addCase(fetchProfileFriends.rejected, (state, action) => {
                state.isLoading = false
                state.errors = action.payload
            })

            //fetch Profile Friends by offset
            .addCase(fetchProfileFriendsByOffset.pending, (state) => {
                state.isLoading = true
                state.errors = undefined
            })
            .addCase(fetchProfileFriendsByOffset.fulfilled, (state, action: PayloadAction<IUserRelationship[]>) => {
                if (state.friends) {
                    state.friends = [
                        ...state.friends,
                        ...action.payload
                    ]
                } else {
                    state.friends = [
                        ...action.payload
                    ]
                }
                state.isLoading = false
                state.hasMore = action.payload.length > 0
            })
            .addCase(fetchProfileFriendsByOffset.rejected, (state, action) => {
                state.isLoading = false
                state.errors = action.payload
            })

            //add Profile Friend
            .addCase(addProfileFriend.pending, (state) => {
                state.isLoading = true
                state.errors = undefined
            })
            .addCase(addProfileFriend.fulfilled, (state, action: PayloadAction<IUserRelationship>) => {
                state.isLoading = false
                state.findFriends = state.findFriends?.map(fr => {
                    if (fr.id === action.payload.user.id) {
                        return {
                            ...fr,
                            relation_current_user: [
                                {
                                    id: action.payload.id,
                                    type: action.payload.type,
                                    user_id: action.payload.created_by_user_id,
                                    status: action.payload.status,
                                    created_by_user_id: state.profile?.id || 0
                                }
                            ]
                        }
                    } else {
                        return fr
                    }
                })
                state.friends = state.friends?.filter(fr => fr.user.id !== action.payload.user.id)
            })
            .addCase(addProfileFriend.rejected, (state, action) => {
                state.isLoading = false
                state.errors = action.payload
            })
            //{
            //                             id: action.payload.id,
            //                             type: action.payload.type,
            //                             user_id: action.payload.created_by_user_id,
            //                             status: action.payload.status,
            //                             created_by_user_id: state.profile?.id || 0
            //                         }

        //requests

            //accept Profile Friend
            .addCase(acceptProfileFriend.pending, (state) => {
                state.isLoading = true
                state.errors = undefined
            })
            .addCase(acceptProfileFriend.fulfilled, (state, action: PayloadAction<boolean>) => {
                // @ts-ignore
                const {idRelationship} = action.meta.arg
                if (idRelationship) {
                    const friendsIsDataAvailable = state.friends?.filter(fr => (fr.id !== idRelationship && fr.incoming))
                    state.friends = state.friends?.filter(fr => fr.id !== idRelationship)
                    state.isDataAvailable = !!friendsIsDataAvailable && friendsIsDataAvailable.length > 0
                    state.findFriends = state.findFriends?.map(
                        fr => {
                            const relationUser = fr.relation_current_user?.filter(rl =>
                                rl.type !== Relationship.CLIENT)?.[0]
                            return relationUser
                                ? {
                                    ...fr,
                                    relation_current_user: fr.relation_current_user?.map(el => el.id === relationUser.id
                                        ? {...el, type: 1, status: 1}
                                        : el)
                                }
                                : fr
                        }
                    )
                }

                state.isLoading = false
                state.errors = undefined
            })
            .addCase(acceptProfileFriend.rejected, (state, action) => {
                state.isLoading = false
                state.errors = action.payload
            })

            //decline Profile friend
            .addCase(declineProfileFriend.pending, (state) => {
                state.isLoading = true
                state.errors = undefined
            })
            .addCase(declineProfileFriend.fulfilled, (state, action: PayloadAction<boolean>) => {
                state.isLoading = false
                // @ts-ignore
                const {idUser, idRelationship} = action.meta.arg
                if (idRelationship) {
                    const friendsIsDataAvailable = state.friends?.filter(fr => (fr.id !== idRelationship && fr.incoming))
                    state.friends = state.friends?.filter(fr => fr.id !== idRelationship)
                    state.isDataAvailable = !!friendsIsDataAvailable && friendsIsDataAvailable.length > 0
                }
                if (idUser) {
                    state.findFriends = state?.findFriends?.map(fr => fr.id === idUser
                        ? {
                            ...fr,
                            relation_current_user: undefined
                        }
                        : fr)

                }
            })
            .addCase(deleteProfileRelationship.rejected, (state, action) => {
                state.isLoading = false
                state.errors = action.payload
            })


        //blacklist

            //add Profile BlackList
            .addCase(addProfileBlackList.pending, (state) => {
                state.isLoading = true
                state.errors = undefined
            })
            .addCase(addProfileBlackList.fulfilled, (state, action: PayloadAction<IUserRelationship>) => {
                state.isLoading = false
                state.findFriends = state.findFriends?.filter(fr => fr.id !== action.payload.user.id)
                state.friends = state.friends?.filter(fr => fr.user.id !== action.payload.user.id)
                state.isDataAvailable = !!state.friends && state.friends.length > 0
            })
            .addCase(addProfileBlackList.rejected, (state, action) => {
                state.isLoading = false
                state.errors = action.payload
            })

            //delete Profile blackList
            .addCase(deleteProfileBlackList.pending, (state) => {
                state.isLoading = true
                state.errors = undefined
            })
            .addCase(deleteProfileBlackList.fulfilled, (state, action: PayloadAction<boolean>) => {
                state.isLoading = false
                // @ts-ignore
                const {idRelationship} = action.meta.arg
                if (idRelationship) {
                    state.blackList = state.blackList?.filter(fr => fr.id !== idRelationship)
                    state.isDataAvailable = !!state.blackList && state.blackList.length > 0
                }
            })
            .addCase(deleteProfileBlackList.rejected, (state, action) => {
                state.isLoading = false
                state.errors = action.payload
            })

            //fetch Profile BlackList
            .addCase(fetchProfileBlackList.pending, (state) => {
                state.isLoading = true
                state.errors = undefined
            })
            .addCase(fetchProfileBlackList.fulfilled, (state, action: PayloadAction<IUserRelationship[]>) => {
                state.blackList = [
                    ...action.payload
                ]
                if (!state.isMounted) {
                    state.isDataAvailable = action.payload.length > 0
                    state.isMounted = true
                }
                state.isLoading = false
                state.hasMore = action.payload.length > 0
            })
            .addCase(fetchProfileBlackList.rejected, (state, action) => {
                state.isLoading = false
                state.errors = action.payload
            })

            //fetch Profile BlackList by offset
            .addCase(fetchProfileBlackListByOffset.pending, (state) => {
                state.isLoading = true
                state.errors = undefined
            })
            .addCase(fetchProfileBlackListByOffset.fulfilled, (state, action: PayloadAction<IUserRelationship[]>) => {
                if (state.blackList) {
                    state.blackList = [
                        ...state.blackList,
                        ...action.payload
                    ]
                } else {
                    state.blackList = [
                        ...action.payload
                    ]
                }
                state.isLoading = false
                state.hasMore = action.payload.length > 0
            })
            .addCase(fetchProfileBlackListByOffset.rejected, (state, action) => {
                state.isLoading = false
                state.errors = action.payload
            })

            //check Relationship With User
            .addCase(checkRelationshipWithUser.pending, (state) => {
                state.isLoading = true
                state.errors = undefined
            })
            .addCase(checkRelationshipWithUser.fulfilled, (state, action: PayloadAction<IUserRelationship[]>) => {
                state.userRelationship = action.payload
                state.isLoading = false
            })
            .addCase(checkRelationshipWithUser.rejected, (state, action) => {
                state.isLoading = false
                state.errors = action.payload
            })


            //events
            //fetch my
            .addCase(fetchProfileEventsMyCollection.pending, (state) => {
                state.isLoading = true
                state.errors = undefined
            })
            .addCase(fetchProfileEventsMyCollection.fulfilled, (state, action: PayloadAction<ICalendarResponse[]>) => {
                if (action.payload && action.payload.length > 0) {
                    state.events = action.payload.sort((a, b) => {
                        const firstDate = moment(a.time_start).toDate()
                        const secondDate = moment(b.time_start).toDate()
                        // if (moment(firstDate).isBefore(moment(secondDate))) {
                        //     return -1
                        // }
                        return new Date(firstDate).getTime() -
                            new Date(secondDate).getTime()
                    })
                }
                if (!state.isMounted) {
                    state.isMounted = true
                }
                state.isLoading = false
            })
            .addCase(fetchProfileEventsMyCollection.rejected, (state, action) => {
                state.isLoading = false
                state.errors = action.payload
            })
    },
})


export const profileReducer = profileSlice.reducer
export const profileAction = profileSlice.actions
