import s from './NavbarLoader.module.css';
import LinearProgress from "@mui/material/LinearProgress";
import {classNames} from "../../utils/helpers/classNames/classNames";

interface NavbarLoaderProps {
    className?: string
}

export const NavbarLoader = ({className}: NavbarLoaderProps) => {

    return (
        <div className={classNames(s.navbarLoader, {}, [className])}>
            <LinearProgress
                sx={{
                    backgroundColor: '#2a266b'
                }}
            />
        </div>
    );
};
