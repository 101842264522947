import {createAsyncThunk} from '@reduxjs/toolkit';
import {ThunkConfig} from "../../../config/StateSchema";
import {BaseResponse} from "../../../../api/api";
import {snackbarAction} from "../../../errors/SnackbarSlice";
import {IChatsCollection, IChatsMyCollectionsQuery} from "../../../chats/types/chatsTypes";
import {twilioChatsAction} from "../../../twilioChats/twilioChatsSlice";
import {
    checkRelationshipWithUser
} from "../../../profile/services/user/checkRelationshipWithUser/checkRelationshipWithUser";
import {
    getTwilioChatsActiveTwilioToken
} from "../../../twilioChats/selectors/getTwilioChats/getTwilioChats";
import {getProfileId} from "../../../profile/selectors/getProfile/getProfile";
import {getPersonalChatIsMounted} from "../../selectors/getPrivateChat/getPersonalChat";


interface IResponse {
    collection: IChatsCollection[] | null
}

export const fetchMyPrivateChatsCollection = createAsyncThunk<IChatsCollection[] | null,
    IChatsMyCollectionsQuery | void,
    ThunkConfig<string>>(
        'privateChats/fetchMyPrivateChatsCollection',
        async (data, thunkAPI) => {
            const {extra, rejectWithValue, dispatch, getState} = thunkAPI;
            const activeTokenPersonalChat = getTwilioChatsActiveTwilioToken(getState())

            const myId = getProfileId(getState())
            const isMounted = getPersonalChatIsMounted(getState())
            try {
                if (isMounted) {
                    dispatch(twilioChatsAction.clearMessages());
                }
                const response = await extra.api.get<BaseResponse<IResponse>>('chat/my', {
                    params: data
                })
                if (!response.data.success) {
                    return rejectWithValue('error')
                }
                const myChats = response.data.response.collection
                if (myChats && myChats.length > 0) {
                    dispatch(twilioChatsAction.updateNewMessages(myChats))
                }

                if (activeTokenPersonalChat) {
                    dispatch(twilioChatsAction.deleteNumberOFMessage(activeTokenPersonalChat));
                    const userId = myChats?.find(el => el.twilio_id === activeTokenPersonalChat)?.users?.find(el => el.id !== myId)?.id;
                    if (userId) {
                        dispatch(checkRelationshipWithUser({
                            user_id: userId
                        }));
                    }
                } else {
                    const twilioId = myChats?.[0]?.twilio_id;
                    if (twilioId) {
                        dispatch(twilioChatsAction.setActiveTwilioId(twilioId));
                        dispatch(twilioChatsAction.deleteNumberOFMessage(twilioId));
                    }
                }
                return myChats
            } catch (err) {
                dispatch(snackbarAction.addError('INTERNAL_ERROR'))
                return rejectWithValue('error');
            }
        },
    );
