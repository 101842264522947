import {createAsyncThunk} from '@reduxjs/toolkit';
import {ThunkConfig} from "../../../config/StateSchema";
import {BaseResponse} from "../../../../api/api";
import {IChatsCollection, IChatsCollectionsQuery} from "../../types/chatsTypes";
import {snackbarAction} from "../../../errors/SnackbarSlice";

interface IResponse {
    collection: IChatsCollection[]
}

export const fetchChatsCollectionByOffset = createAsyncThunk<IChatsCollection[],
    IChatsCollectionsQuery | void,
    ThunkConfig<string>>(
        'chats/fetchChatsCollectionByOffset',
        async (data, thunkAPI) => {
            const {extra, rejectWithValue, dispatch} = thunkAPI;
            try {
                const response = await extra.api.get<BaseResponse<IResponse>>('chat', {
                    params:data
                })

                if (!response.data.success) {
                    return rejectWithValue('error')
                }

                return response.data.response.collection
            } catch (err) {
                dispatch(snackbarAction.addError('INTERNAL_ERROR'))
                return rejectWithValue('error');
            }
        },
    );
