import s from "./ProfileInfo.module.css";
import { useTranslation } from "react-i18next";
import { IConstantCollection } from "../../store/reducers/constants/types/constantsTypes";

interface UserInfoProps {
  location?: string;
  about_text?: string;
  addictions?: IConstantCollection[];
  problems?: IConstantCollection[];
}

export const UserInfo = (props: UserInfoProps) => {
    const {t} = useTranslation(['profile', 'problems', 'addictions'])
    const {
        location,
        about_text,
        addictions,
        problems
    } = props

    const isAddiction = addictions !== undefined && addictions?.length > 0 ? true : false
    const isProblems = problems !== undefined &&  problems?.length > 0 ? true : false

    return (
        <div className={s.user__info}>
            {isAddiction ? (
                <div className={s.user__infoLine}>
                    <div className={s.user__infoName}>{t('profile:profile.addiction')}</div>
                    <div className={s.user__infoData}>
                        {addictions?.map(addiction => t(`addictions:${[addiction.lang_key]}.title`)).join(', ')}
                    </div>
                </div>
            ) : null}

            {isProblems ? (
                <div className={s.user__infoLine}>
                    <div className={s.user__infoName}>{t('profile:profile.issues')}</div>
                    <div className={s.user__infoData}>
                        {
                            problems?.map(addiction => t(`problems:${[addiction.lang_key]}.title`)).join(', ')
                        }
                    </div>
                </div>
            ) : null}

            {location ? (
                <div className={s.user__infoLine}>
                    <div className={s.user__infoName}>{t('profile:profile.city')}:</div>
                    {location && <div className={s.user__infoData}>{location}</div>}
                </div>
            ) : null}

            {about_text ? (
                <div className={s.user__infoLine}>
                    <div className={s.user__infoName}>{t('profile:profile.aboutMe')}:</div>
                    {about_text && <div className={s.user__infoData}>{about_text}</div>}
                </div>
            ) : null}

        </div>
    );
};
