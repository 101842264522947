import {createAsyncThunk} from '@reduxjs/toolkit';
import {ThunkConfig} from "../../../config/StateSchema";
import {IProfileSpec, IProfileUser} from "../../types/profileTypes";
import {authAction} from "../../../auth/authSlice";
import {fetchTwilioToken} from "../../../chatItem/services/fetchTwilioToken/fetchTwilioToken";
import {snackbarAction} from "../../../errors/SnackbarSlice";


export const fetchProfileSelf = createAsyncThunk<IProfileSpec | IProfileUser,
    string,
    ThunkConfig<string>>(
        'profile/fetchProfileSelf',
        async (token, thunkAPI) => {
            const {extra, rejectWithValue, dispatch} = thunkAPI;
            const {changeIsNavbarLoading, changeIsMainLoading, setIsAuthentication} = authAction
            dispatch(changeIsNavbarLoading(true))
            try {
                const response = await extra.api.get('profile/self')
                if (!response.data.success) {
                    return rejectWithValue('error')
                }
                dispatch(setIsAuthentication(true))
                dispatch(fetchTwilioToken())
                return response.data.response.info
            } catch (err) {
                dispatch(snackbarAction.addError('INTERNAL_ERROR'))
                return rejectWithValue('error');
            } finally {
                dispatch(changeIsNavbarLoading(false))
                dispatch(changeIsMainLoading(false))
            }
        },
    );
