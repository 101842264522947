import {useMemo, useRef} from "react";
import {TFunction} from "i18next";
import {IConstantCollection} from "../store/reducers/constants/types/constantsTypes";

export const useSpecializations = (t: TFunction, addictions?: IConstantCollection[], problems?: IConstantCollection[]) => {
    const specialization = useRef<string[]>([])
    useMemo(() => {
        specialization.current = []
        addictions?.forEach(addiction => {
            const data = t(`addictions:${[addiction.lang_key]}.title`)
            specialization.current.push(data)
        })
        problems?.forEach(problems => {
            const data = t(`problems:${[problems.lang_key]}.title`)
            specialization.current.push(data)
        })
    }, [addictions, problems, t])
    return specialization.current
}
