import React, {memo} from 'react';
import s from './NotificationMenuHeader.module.css'
import {
    INotification,
} from "../../../../store/reducers/notifications/types/notificationsTypes";
import {useTranslation} from "react-i18next";
import {classNames, Mods} from "../../../../utils/helpers/classNames/classNames";

interface NotificationItemLinkPropsType {
    notification: INotification
    showProfile?: (idProfile: number) => void
    navigateRoom?: (idRoom: number) => void
    navigateChat?: (idRoom: number) => void
    navigateMyCabinet?: () => void
    clearNotification?: (idNotification: number) => void
    notificationIsLoading: number[]
    closeNotification?: ()=> void
}

export const NotificationItemLink = memo((props: NotificationItemLinkPropsType) => {
    const {
        notification,
        clearNotification,
        notificationIsLoading,
        showProfile,
        navigateRoom,
        navigateMyCabinet,
        navigateChat,
        closeNotification
    } = props

    const {t} = useTranslation('notification')
    const {
        event,
        linkedUser,
        id,
        room,
        chat,
        chatUser,
        roomUser,
        schedule,
    } = notification

    const onClearNotification = () => {
        clearNotification?.(id)
    }

    let notificationContent = null

    const onClickShowProfile = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation()
        if (room) {
            showProfile?.(room.createdByUser.id)
        } else if (chat) {
            showProfile?.(chat.createdByUser.id)
        } else if (linkedUser) {
            showProfile?.(linkedUser.id)
        }
    }

    const onClickNavigateCabinet = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation()
        navigateMyCabinet?.()
        closeNotification?.()
    }

    const onClickNavigateRoom = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation()
        if (schedule?.room?.id) {
            navigateRoom?.(schedule?.room?.id)
        } else if (room?.id) {
            navigateRoom?.(room?.id)
        }
        closeNotification?.()
    }

    const onClickNavigateChat = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation()
        if (chat?.id) {
            navigateChat?.(chat?.id)
        }
        closeNotification?.()
    }

    switch (event) {
    //ДРУЗЬЯ
    //принял запрос на дружбу
    case "USER_RELATIONSHIP_FRIEND_ACCEPT":
        if (linkedUser) {
            notificationContent = <p className={s.accept}>
                {t('notification:user')}
                {' '}
                <span
                    className={classNames(s.user, {}, [s.hover])}
                    onClick={onClickShowProfile}
                >
                    {linkedUser.name ? linkedUser.name : linkedUser.nickname}
                </span>
                {' '}
                {t('notification:accept-friends')}
            </p>
        }
        break

        //отклонил запрос на дружбу
    case "USER_RELATIONSHIP_FRIEND_DECLINE":
        if (linkedUser) {
            notificationContent =
                <p className={s.decline}>
                    {t('notification:user')}
                    {' '}
                    <span
                        className={classNames(s.user, {}, [s.hover])}
                        onClick={onClickShowProfile}
                    >
                        {linkedUser.name ? linkedUser.name : linkedUser.nickname}
                    </span>
                    {' '}
                    {t('notification:decline-friends')}
                </p>
        }
        break

        //КОМНАТЫ
        //принял запрос на вступление в комнату
    case "ROOM_USER_REQUEST_APPROVED":
        if (roomUser && room) {
            notificationContent =
                <p className={s.accept}>
                    {t('notification:user')}
                    {' '}
                    <span
                        className={classNames(s.user, {}, [s.hover])}
                        onClick={onClickShowProfile}
                    >
                        {roomUser.user?.name ? roomUser.user?.name : roomUser.user?.nickname}
                    </span>
                    {' '}
                    {t('notification:accept-room')}
                    {' '}
                    <span
                        className={classNames(s.user, {}, [s.hover])}
                        onClick={onClickNavigateRoom}
                    >
                        {room.name}
                    </span>
                </p>
        }
        break

        //отклонил запрос на вступление в комнату
    case "ROOM_USER_REQUEST_DECLINED":
        if (roomUser && room) {
            notificationContent =
                <p className={s.decline}>
                    {t('notification:user')}
                    {' '}
                    <span
                        className={classNames(s.user, {}, [s.hover])}
                        onClick={onClickShowProfile}
                    >
                        {room.createdByUser?.name ? room.createdByUser?.name : room.createdByUser?.nickname}
                    </span>
                    {' '}
                    {t('notification:decline-room')}
                    {' '}
                    <span
                        className={s.user}
                    >
                        {room.name}
                    </span>
                </p>
        }
        break

        //ЧАТЫ
        //принял запрос на вступление в чат
    case "CHAT_USER_REQUEST_APPROVED":
        if (chatUser && chat) {
            notificationContent =
                <p className={s.accept}>
                    {t('notification:user')}
                    {' '}
                    <span
                        className={classNames(s.user, {}, [s.hover])}
                        onClick={onClickShowProfile}
                    >
                        {chat.createdByUser?.name ? chat.createdByUser?.name : chat.createdByUser?.nickname}
                    </span>
                    {' '}
                    {t('notification:accept-chat')}
                    {' '}
                    <span
                        className={classNames(s.user, {}, [s.hover])}
                        onClick={onClickNavigateChat}
                    >
                        {chat.name}
                    </span>
                </p>
        }
        break

        //отклонил запрос на вступление в чат
    case "CHAT_USER_REQUEST_DECLINED":
        if (chatUser && chat) {
            notificationContent = <p className={s.decline}>
                {t('notification:user')}
                {' '}
                <span
                    onClick={onClickShowProfile}
                    className={classNames(s.user, {}, [s.hover])}
                >
                    {chat.createdByUser?.name ? chat.createdByUser?.name : chat.createdByUser?.nickname}
                </span>
                {' '}
                {t('notification:decline-chat')}
                {' '}
                <span
                    className={s.user}
                >
                    {chat.name}
                </span>
            </p>

        }
        break

        //Календарь
        //записался на сессию
    case "SCHEDULE_USER_SUBSCRIBED":
        if (linkedUser && schedule) {
            notificationContent =
                <p className={s.accept}>
                    {t('notification:user')}
                    {' '}
                    <span
                        className={classNames(s.user, {}, [s.hover])}
                        onClick={onClickShowProfile}
                    >
                        {linkedUser.name ? linkedUser.name : linkedUser.nickname}
                    </span>
                    {' '}
                    {t('notification:calendar-subscribed')}
                    {' '}
                    <span
                        className={s.user}
                    >
                        {schedule.name}
                    </span>
                </p>
        }
        break

        //отписался на сессию
    case "SCHEDULE_USER_UNSUBSCRIBED":
        if (linkedUser && schedule) {
            notificationContent = <p className={s.decline}>
                {t('notification:user')}
                {' '}
                <span
                    onClick={onClickShowProfile}
                    className={classNames(s.user, {}, [s.hover])}
                >
                    {linkedUser.name ? linkedUser.name : linkedUser.nickname}
                </span>
                {' '}
                {t('notification:calendar-unsubscribed')}
                {' '}
                <span
                    className={s.user}
                >
                    {schedule.name}
                </span>
            </p>

        }
        break

        //конец сессии
    case "SCHEDULE_END":
        if (schedule) {
            const roomName = schedule.room?.name
            notificationContent = <p className={s.decline}>
                {t('notification:calendar')}
                {' '}
                <span
                    onClick={onClickNavigateCabinet}
                    className={classNames(s.user, {}, [s.hover])}
                >
                    {schedule.name}
                </span>
                {' '}
                {t('notification:calendar-end')}
                {' '}
                {
                    roomName
                        ? (<>
                            {t('notification:calendar-room')}
                            {' '}
                            <span
                                className={classNames(s.user, {}, [s.hover])}
                                onClick={onClickNavigateRoom}
                            >
                                {roomName}
                            </span>
                        </>)
                        :
                        null
                }
            </p>

        }
        break

        //     //старт сессии
        // case "SCHEDULE_START":
        //     if (schedule) {
        //         const roomName = schedule.room?.name
        //         notificationContent = <p className={s.accept}>
        //             {t('notification:calendar')}
        //             {' '}
        //             <span
        //                 onClick={onClickNavigateCabinet}
        //                 className={classNames(s.user, {}, [s.hover])}
        //             >
        //                 {schedule.name}
        //             </span>
        //             {' '}
        //             {t('notification:calendar-start')}
        //             {' '}
        //             {
        //                 roomName
        //                     ? (<>
        //                         {t('notification:calendar-room')}
        //                         {' '}
        //                         <span
        //                             className={classNames(s.user, {}, [s.hover])}
        //                             onClick={onClickNavigateRoom}
        //                         >
        //                             {roomName}
        //                         </span>
        //                     </>)
        //                     :
        //                     null
        //             }
        //         </p>
        //
        //     }
        //     break

        //сессия начнется через 60 минут
    case "SCHEDULE_60MIN_START":
        if (schedule) {
            const roomName = schedule.room?.name
            notificationContent = <p className={s.accept}>
                {t('notification:calendar')}
                {' '}
                <span
                    onClick={onClickNavigateCabinet}
                    className={classNames(s.user, {}, [s.hover])}
                >
                    {schedule.name}
                </span>
                {' '}
                {t('notification:calendar-60-min')}
                {' '}
                {
                    roomName
                        ? (<>
                            {t('notification:calendar-room')}
                            {' '}
                            <span
                                className={classNames(s.user, {}, [s.hover])}
                                onClick={onClickNavigateRoom}
                            >
                                {roomName}
                            </span>
                        </>)
                        :
                        null
                }
            </p>
        }
        break

        //сессия начнется через 5 минут
    case "SCHEDULE_5MIN_START":
        if (schedule) {
            const roomName = schedule.room?.name
            notificationContent = <p className={s.accept}>
                {t('notification:calendar')}
                {' '}
                <span
                    onClick={onClickNavigateCabinet}
                    className={classNames(s.user, {}, [s.hover])}
                >
                    {schedule.name}
                </span>
                {' '}
                {t('notification:calendar-5-min')}
                {' '}
                {
                    roomName
                        ? (<>
                            {t('notification:calendar-room')}
                            {' '}
                            <span
                                className={classNames(s.user, {}, [s.hover])}
                                onClick={onClickNavigateRoom}
                            >
                                {roomName}
                            </span>
                        </>)
                        :
                        null
                }
            </p>
        }
        break


    default:
        return null;
    }

    const isDisabled = notificationIsLoading.includes(id)
    const mods: Mods = {
        [s.loading]: isDisabled
    }

    return (
        <>
            {notificationContent ?
                <div className={classNames(s.notiWrp__item, mods, [])}
                    onClick={isDisabled ? undefined : onClearNotification}
                >
                    <div className={s.messageBlock}>
                        {notificationContent}
                    </div>
                </div>

                : null}
        </>

    )
});
