import {memo} from 'react';
import s from "./SwitchLang.module.css";
import {TooltipBox} from "../../tooltip/TooltipBox";
import {useAppDispatch, useAppSelector} from "../../../store/hooks/redux";
import {useTranslation} from "react-i18next";
import {languageAction} from "../../../store/reducers/language/languageSlice";

import {ReactComponent as LangSVG} from '../../../utils/images/icons/lang.svg'
import {classNames} from "../../../utils/helpers/classNames/classNames";
import {getLanguage} from "../../../store/reducers/language/getLanguege/getLanguage";

type SwitchLangProps = {
    className?: string
}

export const SwitchLang = memo(({className}: SwitchLangProps) => {
    const language = useAppSelector(getLanguage)
    const dispatch = useAppDispatch()
    const {changeLanguageWithButton} = languageAction

    const {t} = useTranslation('navbar')

    const changeLanguageHandler = () => {
        if (language)
            dispatch(changeLanguageWithButton({language}))
    }
    let title = t('navbar:navbar.switchLang.title')
    let content = t('navbar:navbar.switchLang.descriptionTooltip')

    return (
        <div className={classNames (s.header__switchLang, {}, [className])}
            onClick={changeLanguageHandler}>
            {/*<img src={logoIcons} alt={'switch lang'}/>*/}

            <div className={s.header__switchLang_box}>
                <LangSVG/>
                <TooltipBox
                    classTitle={s.span}
                    title={title}
                    content={content}
                    place={'top'}/>
            </div>

        </div>
    );
});
