import {createAsyncThunk} from '@reduxjs/toolkit';
import {ThunkConfig} from "../../../config/StateSchema";
import {BaseResponse} from "../../../../api/api";
import {IRoomsCollection} from "../../types/roomsTypes";
import {snackbarAction} from "../../../errors/SnackbarSlice";

interface IResponse {
    info: IRoomsCollection
}

export const fetchRoomsInfoById = createAsyncThunk<IRoomsCollection,
   number,
    ThunkConfig<string>>(
        'rooms/fetchRoomsInfoById',
        async (id, thunkAPI) => {
            const {extra, rejectWithValue, dispatch} = thunkAPI;
            try {
                const response = await extra.api.get<BaseResponse<IResponse>>(`room/${id}`)

                if (!response.data.success) {
                    return rejectWithValue('error')
                }

                return response.data.response.info
            } catch (err) {
                dispatch(snackbarAction.addError('INTERNAL_ERROR'))
                return rejectWithValue('error');
            }
        },
    );
