import {createAsyncThunk} from '@reduxjs/toolkit';
import {BaseResponse} from "../../../../api/api";
import {ThunkConfig} from "../../../config/StateSchema";
import {IProfileSpec, IProfileUser, UserGroup} from "../../../profile/types/profileTypes";
import {snackbarAction} from "../../../errors/SnackbarSlice";
import {RoutePath} from "../../../../../router/routerConfig/routerConfig";


export interface IResponseProfileInfo {
    info: IProfileSpec | IProfileUser
}

export const fetchProfileInfoByIdToNavigate = createAsyncThunk<
    void,
    {id: number, navigate: (to: string) => void},
    ThunkConfig<string>>(
        'profileInfo/fetchProfileInfoByIdToNavigate',
        async ({navigate, id}, thunkAPI) => {
            const {extra, rejectWithValue, dispatch} = thunkAPI;
            try {
                const response = await extra.api.get<BaseResponse<IResponseProfileInfo>>(`profile/${id}`)

                if (!response.data.success) {
                    return rejectWithValue('error')
                }
                const profileGroupId = response.data.response.info?.user_group_id
                if(profileGroupId) {
                    if (profileGroupId === UserGroup.SPEC || profileGroupId === UserGroup.SPEC_PREMIUM) {
                        navigate(`${RoutePath.specialist_profile}${id}`)
                    } else {
                        navigate(`${RoutePath.profile_user}${id}`)
                    }
                }

            } catch (err) {
                dispatch(snackbarAction.addError('INTERNAL_ERROR'))
                return rejectWithValue('error');
            }
        },
        );
