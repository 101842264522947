import {memo} from 'react';
import {classNames} from '../../utils/helpers/classNames/classNames';
import s from './Text.module.css';

export type TextTheme = 'primary' | 'error' | 'light' | 'successful' | 'notification'

export type TextAlign = 'right' | 'left' | 'center'
export type TextSize = '12' | '14' | '16' | '18'

interface TextProps {
    className?: string
    title?: string
    text?: string
    theme?: TextTheme
    align?: TextAlign
    size?: TextSize
}

export const Text = memo((props: TextProps) => {
    const {
        text,
        theme = 'primary',
        title,
        className,
        align = 'left',
        size = '18',
    } = props;

    return (
        <div className={classNames(s.Text, {}, [className, s[theme], s[align], s[`size${size}`]])}>
            {title && <p className={s.title}>{title}</p>}
            {text && <p className={s.text}>{text}</p>}
        </div>
    );
});
