import {createSlice, DeepPartial, PayloadAction} from "@reduxjs/toolkit";
import {IChatRequest, IChats, IChatsCollection, ICreatChat} from "./types/chatsTypes";
import {fetchChatsCollection} from "./services/fetchChatsCollection/fetchChatsCollection";
import {fetchMyChatsCollection} from "./services/fetchMyChatsCollection/fetchMyChatsCollection";
import {Errors} from "../../api/api";
import {createChat} from "./services/createChat/createChat";
import {IRoomsCollection} from "../rooms/types/roomsTypes";
import {fetchChatsInfoById} from "./services/fetchChatsInfoById/fetchChatsInfoById";
import {
    fetchChatsCollectionByOffset
} from "./services/fetchChatsCollectionByOffset/fetchChatsCollectionByOffset";
import {fetchChatsRequests} from "./services/fetchChatsRequests/fetchChatsRequests";
import {StatusFilterSpecialistRoomsType} from "../../../models/ITabsFilter";
import {updateChatRequest} from "./services/updateChatRequest/updateChatRequest";

const initialState: IChats = {
    collection: [],
    myCollection: [],
    statusFilter: 'all',

    hasMore: true,
    isMounted: false,
    isLoading: false,
    isLoadingChat: false,
    error: undefined
}

export const chatsSlice = createSlice({
    name: 'chatsSlice',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload
        },
        setIsLoadingChat(state, action: PayloadAction<boolean>) {
            state.isLoadingChat = action.payload
        },
        setStatusFilter(state, action: PayloadAction<StatusFilterSpecialistRoomsType>) {
            state.statusFilter = action.payload
        },

        clearChats(state) {
            state.collection = []
            state.myCollection = []
        },
        setCollectionMainPage(state, action: PayloadAction<IChatsCollection[]>) {
            if (action.payload.length > 3) {
                const newArr = action.payload
                newArr.length = 3
                state.collection = newArr
            }
            state.collection = action.payload
        },
        addChatError(state, action: PayloadAction<{ id: number, error: string }>) {
            state.collection = state.collection?.map(ch =>
                ch.id === action.payload.id
                    ? ({
                        ...ch,
                        error: action.payload.error
                    })
                    : ch)
        },
        setCreateChat(state, action: PayloadAction<DeepPartial<ICreatChat>>) {
            state = {
                ...state,
                ...action.payload,
            }
        },
        setChatRequest(state, action: PayloadAction<number>) {
            state.chatRequests = state.chatRequests?.filter(ch => ch.id !== action.payload)
        },
        resetLoaders(state) {
            state.isLoading = false
            state.isLoadingChat = false
            state.error = undefined
            state.isMounted = false
            state.hasMore = true
        },
        resetMyCollection(state) {
            state.myCollection = []
            state.chatRequests = undefined
            state.statusFilter = 'all'
        },
        changeCreateChat(state, action: PayloadAction<DeepPartial<ICreatChat>>) {
            state.createChat = {
                ...state.createChat,
                ...action.payload
            }
        },
        changeCreateChatErrors(state, action: PayloadAction<Errors<ICreatChat>>) {
            if (state.createChat) {
                state.createChat.errorsCreateChats = {
                    ...action.payload
                }
            } else {
                state.createChat = {
                    errorsCreateChats: {
                        ...action.payload
                    }
                }
            }
        },
        clearCreateChat(state) {
            state.createChat = undefined
        }
    },
    extraReducers: (builder) => {
        builder

            //fetch collections chats
            .addCase(fetchChatsCollection.pending, (state) => {
                state.isLoading = true
                state.isLoadingChat = true
                state.error = undefined
            })
            .addCase(fetchChatsCollection.fulfilled, (state, action: PayloadAction<IChatsCollection[]>) => {
                state.collection = [
                    ...action.payload
                ]
                if (!state.isMounted) {
                    state.isMounted = true
                }
                state.isLoading = false
                state.isLoadingChat = false
                state.hasMore = action.payload.length > 0
            })
            .addCase(fetchChatsCollection.rejected, (state, action) => {
                state.isLoading = false
                state.isLoadingChat = false
                state.error = action.payload
            })

            //fetch collections chats by offset
            .addCase(fetchChatsCollectionByOffset.pending, (state) => {
                state.isLoadingChat = true
                state.error = undefined
            })
            .addCase(fetchChatsCollectionByOffset.fulfilled, (state, action: PayloadAction<IChatsCollection[]>) => {
                state.isLoadingChat = false
                state.collection = [
                    ...state.collection,
                    ...action.payload
                ]
                state.hasMore = action.payload.length > 0
            })
            .addCase(fetchChatsCollectionByOffset.rejected, (state, action) => {
                state.isLoadingChat = false
                state.error = action.payload
            })

            //fetch my collections chats
            .addCase(fetchMyChatsCollection.pending, (state) => {
                state.isLoading = true
                state.error = undefined
            })
            .addCase(fetchMyChatsCollection.fulfilled, (state, action: PayloadAction<IChatsCollection[] | null>) => {
                if (action.payload) {
                    state.myCollection = [
                        ...action.payload
                    ]
                }
                state.isLoading = false
                if (!state.isMounted) {
                    state.isMounted = true
                }
            })
            .addCase(fetchMyChatsCollection.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.payload
            })

            //create Chat
            .addCase(createChat.pending, (state) => {
                state.isLoadingChat = true
                if (state.createChat) {
                    state.createChat.errorsCreateChats = undefined
                } else {
                    state.createChat = {
                        errorsCreateChats: undefined
                    }
                }
            })
            .addCase(createChat.fulfilled, (state, action: PayloadAction<boolean>) => {
                if (action.payload) {
                    state.createChat = {
                        errorsCreateChats: undefined
                    }
                }
                state.isLoadingChat = false
            })
            .addCase(createChat.rejected, (state, action) => {
                state.isLoadingChat = false
                state.createChat = {
                    errorsCreateChats: {
                        ...action.payload
                    }
                }
            })

            //fetch Chats Requests
            .addCase(fetchChatsRequests.pending, (state) => {
                state.isLoading = true
                state.error = undefined
            })
            .addCase(fetchChatsRequests.fulfilled, (state, action: PayloadAction<IChatRequest[]>) => {
                state.chatRequests = action.payload
                state.error = undefined
                state.isLoading = false
            })
            .addCase(fetchChatsRequests.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.payload
            })

            //update chat request
            .addCase(updateChatRequest.pending, (state) => {
                state.isLoadingChat = true
                state.error = undefined
            })
            .addCase(updateChatRequest.fulfilled, (state, action: PayloadAction<IChatRequest>) => {
                state.chatRequests =  state.chatRequests?.filter(ch => ch.id !== action.payload.id)
                state.error = undefined
                state.isLoadingChat = false
            })
            .addCase(updateChatRequest.rejected, (state, action) => {
                state.isLoadingChat = false
                state.error = action.payload
            })

            //set user status
            .addCase(fetchChatsInfoById.fulfilled, (state, action: PayloadAction<IRoomsCollection>) => {
                if (action.payload) {
                    state.collection = state.collection.map(ch => ch.id === action.payload.id ? {...action.payload} : ch)
                }
                state.isLoading = false
            })

    },
})


export const chatsReducer = chatsSlice.reducer
export const chatsAction = chatsSlice.actions


