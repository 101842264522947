import {createAsyncThunk} from '@reduxjs/toolkit';
import {ThunkConfig} from "../../../../config/StateSchema";
import {BaseResponse} from "../../../../../api/api";
import {IUserRelationship} from "../../../types/profileTypes";
import {snackbarAction} from "../../../../errors/SnackbarSlice";

export interface IResponseAddFriend {
    info: IUserRelationship
}

interface IResponse {
    user_id: number
}

export const addProfileBlackList = createAsyncThunk<IUserRelationship,
    IResponse,
    ThunkConfig<string>>(
        'profile/addProfileBlackList',
        async ({user_id}, thunkAPI) => {
            const {extra, rejectWithValue, dispatch} = thunkAPI;
            try {
                const response = await extra.api.post<BaseResponse<IResponseAddFriend>>(`relationship/blacklist`, {
                    user_id
                })
                if (!response.data.success) {
                    return rejectWithValue('error')
                }
                return response.data.response.info
            } catch (err) {
                dispatch(snackbarAction.addError('INTERNAL_ERROR'))
                return rejectWithValue('error');
            }
        },
    );
