import {createAsyncThunk} from '@reduxjs/toolkit';
import {ThunkConfig} from "../../../config/StateSchema";
import {BaseResponse} from "../../../../api/api";
import {snackbarAction} from "../../../errors/SnackbarSlice";
import {IChatsCollection, IChatsMyCollectionsQuery} from "../../../chats/types/chatsTypes";
import {twilioChatsAction} from "../../../twilioChats/twilioChatsSlice";


interface IResponse {
    collection: IChatsCollection[] | null
}

export const fetchMyPrivateChatsCollectionStart = createAsyncThunk<IChatsCollection[] | null,
    IChatsMyCollectionsQuery | void,
    ThunkConfig<string>>(
        'privateChats/fetchMyPrivateChatsCollectionStart',
        async (data, thunkAPI) => {
            const {extra, rejectWithValue, dispatch} = thunkAPI;
            try {
                const response = await extra.api.get<BaseResponse<IResponse>>('chat/my', {
                    params: data
                })

                if (!response.data.success) {
                    return rejectWithValue('error')
                }
                if(response.data.response.collection && response.data.response.collection.length > 0) {
                    dispatch(twilioChatsAction.updateNewMessages(response.data.response.collection))
                }
                return response.data.response.collection
            } catch (err) {
                dispatch(snackbarAction.addError('INTERNAL_ERROR'))
                return rejectWithValue('error');
            }
        },
    );
