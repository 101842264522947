export function getQueryParams(params: Record<string, string | number | number[] | undefined>) {
    const searchParams = new URLSearchParams(window.location.search);
    Object.entries(params).forEach(([name, value]) => {
        if (value !== undefined) {
            if (Array.isArray(value)) {
                const formattedValue = value.join(',');
                searchParams.set(name, formattedValue);
            } else {
                const formattedValue = typeof value === 'number' ? value.toLocaleString() : value;
                searchParams.set(name, formattedValue);
            }
        }
    });
    return `?${searchParams.toString()}`;
}

/**
 * Функция добавления параметров строки запроса в URL
 */
export const addQueryParams = (params: Record<string, string | number | number[] | undefined>) => {
    window.history.pushState(null, '', getQueryParams(params));
};
