import {createAsyncThunk} from '@reduxjs/toolkit';
import {ThunkConfig} from "../../../config/StateSchema";
import {BaseResponse, Errors} from "../../../../api/api";
import {IChatsCollection, ICreatChat} from "../../types/chatsTypes";
import {chatsAction} from "../../chatsSlice";
import {fetchChatsCollection} from "../fetchChatsCollection/fetchChatsCollection";
import {IVerification} from "../../../profile/types/profileTypes";
import {snackbarAction} from "../../../errors/SnackbarSlice";
import {RoutePath} from "../../../../../router/routerConfig/routerConfig";

interface IResponse {
    info: IChatsCollection
}

interface IRequests {
    data: FormData
    navigate: (to: string) => void
}

export const createChat = createAsyncThunk<
    boolean,
    IRequests,
    ThunkConfig<Errors<ICreatChat>
    >>(
        'chats/createChat',
        async ({data, navigate}, thunkAPI) => {
            const {extra, rejectWithValue, dispatch} = thunkAPI;
            dispatch(chatsAction.setIsLoadingChat(true))
            const members = data.get('members_limit')
            if (!members) {
                data.append('members_limit', '1000')
            }
            try {
                const response = await extra.api.post<BaseResponse<IResponse & {
                errors: Errors<IVerification>
            }>>('chat', data)

                if (!response.data.success) {
                    return rejectWithValue(response.data.response.errors)
                }
                if (response.data.success && response.data.response.info.id) {
                    navigate(RoutePath.chat_view + response.data.response.info.id)
                }
                return true
            } catch (err) {
                dispatch(snackbarAction.addError('INTERNAL_ERROR'))
                // @ts-ignore
                return rejectWithValue({errorsCreateChats: err.response.data.codeKey});
            } finally {
                dispatch(chatsAction.setIsLoadingChat(false))
            }
        },
    );
