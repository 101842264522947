import {createAsyncThunk} from '@reduxjs/toolkit';
import {ThunkConfig} from "../../../config/StateSchema";
import {ISpecialistFindCollection} from "../../types/specialistFindTypes";
import {BaseResponse} from "../../../../api/api";
import {snackbarAction} from "../../../errors/SnackbarSlice";

export interface IResponseUpdateProfile {
    collection: ISpecialistFindCollection[]
}

export const fetchSpecialistsFindWithEmptyData = createAsyncThunk<ISpecialistFindCollection[],
    void,
    ThunkConfig<string>>(
        'specialistsFind/fetchSpecialistsFindWithEmptyData',
        async (_, thunkAPI) => {
            const {extra, rejectWithValue, dispatch} = thunkAPI;
            try {
                const response = await extra.api.get<BaseResponse<IResponseUpdateProfile>>('profile/collection-spec', {
                    params: {
                        language: 'eng',
                        cost_min: 50,
                        cost_max: 540,
                    }
                })
                if (!response.data.success) {
                    return rejectWithValue('error')
                }

                return response.data.response.collection
            } catch (err) {
                dispatch(snackbarAction.addError('INTERNAL_ERROR'))
                return rejectWithValue('error');
            }
        },
    );
