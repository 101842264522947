import {createSlice, DeepPartial, PayloadAction} from "@reduxjs/toolkit";
import {Errors} from "../../api/api";
import {fetchRoomCollection} from "./services/fetchRoomCollection/fetchRoomCollection";
import {ICreatRoom, IRoomRequest, IRooms, IRoomsCollection} from "./types/roomsTypes";
import {fetchMyRoomsCollection} from "./services/fetchMyRoomsCollection/fetchMyRoomsCollection";
import {fetchRoomsInfoById} from "./services/fetchRoomsInfoById/fetchRoomsInfoById";
import {IChatRequest, IChatsCollection} from "../chats/types/chatsTypes";
import {
    fetchRoomsCollectionByOffset
} from "./services/fetchRoomsCollectionByOffset/fetchRoomsCollectionByOffset";
import {createRoom} from "../chats/services/createRoom/createRoom";
import {StatusFilterSpecialistRoomsType} from "../../../models/ITabsFilter";
import {fetchRoomsRequests} from "./services/fetchRoomsRequests/fetchRoomsRequests";
import {updateChatRequest} from "../chats/services/updateChatRequest/updateChatRequest";
import {updateRoomRequest} from "./services/updateRoomRequest/updateRoomRequest";

const initialState: IRooms = {
    collection: [],
    myCollection: [],
    statusFilter: 'all',

    hasMore: true,
    isMounted: false,
    isLoading: true,
    isLoadingRoom: false,
    error: undefined
}

export const roomsSlice = createSlice({
    name: 'roomsSlice',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload
        },
        setIsLoadingRoom(state, action: PayloadAction<boolean>) {
            state.isLoadingRoom = action.payload
        },
        setStatusFilter(state, action: PayloadAction<StatusFilterSpecialistRoomsType>) {
            state.statusFilter = action.payload
        },
        clearRooms(state) {
            state.collection = []
            state.myCollection = []
        },
        addRoomError(state, action: PayloadAction<{ id: number, error: string }>) {
            state.collection = state.collection?.map(ch =>
                ch.id === action.payload.id
                    ? ({
                        ...ch,
                        error: action.payload.error
                    })
                    : ch)
        },
        setCreateRoom(state, action: PayloadAction<DeepPartial<ICreatRoom>>) {
            state = {
                ...state,
                ...action.payload,
            }
        },
        resetLoaders(state) {
            state.isLoading = true
            state.isLoadingRoom = false
            state.error = undefined
            state.isMounted = false
            state.hasMore = true
        },
        resetMyCollection(state) {
            state.myCollection = []
            state.roomRequests = undefined
            state.statusFilter = 'all'
        },
        setCollectionMainPage(state, action: PayloadAction<IRoomsCollection[]>) {
            if(action.payload.length > 3) {
                const newArr = action.payload
                newArr.length = 3
                state.collection = newArr
            }
            state.collection = action.payload
        },
        changeСreateRoom(state, action: PayloadAction<DeepPartial<ICreatRoom>>) {
            state.createRoom = {
                ...state.createRoom,
                ...action.payload
            }
        },
        changeСreateRoomErrors(state, action: PayloadAction<Errors<ICreatRoom>>) {
            if (state.createRoom) {
                state.createRoom.errorsCreateRooms = {
                    ...action.payload
                }
            } else {
                state.createRoom = {
                    errorsCreateRooms: {
                        ...action.payload
                    }
                }
            }
        },
        changeCreateChat(state, action: PayloadAction<DeepPartial<ICreatRoom>>) {
            state.createRoom = {
                ...state.createRoom,
                ...action.payload
            }
        },
        changeCreateChatErrors(state, action: PayloadAction<Errors<ICreatRoom>>) {
            if (state.createRoom) {
                state.createRoom.errorsCreateRooms = {
                    ...action.payload
                }
            } else {
                state.createRoom = {
                    errorsCreateRooms: {
                        ...action.payload
                    }
                }
            }
        },
        clearСreateRoom(state) {
            state.createRoom = undefined
        }
    },
    extraReducers: (builder) => {
        builder

            //fetch collections rooms
            .addCase(fetchRoomCollection.pending, (state) => {
                state.isLoading = true
                state.isLoadingRoom = true
                state.error = undefined
            })
            .addCase(fetchRoomCollection.fulfilled, (state, action: PayloadAction<IRoomsCollection[]>) => {
                state.collection = [
                    ...action.payload
                ]
                if(!state.isMounted) {
                    state.isMounted = true
                }
                state.isLoading = false
                state.isLoadingRoom = false
                state.hasMore = action.payload.length > 0
            })
            .addCase(fetchRoomCollection.rejected, (state, action) => {
                state.isLoading = false
                state.isLoadingRoom = false
                state.error = action.payload
            })

            //fetch collections rooms by offset
            .addCase(fetchRoomsCollectionByOffset.pending, (state) => {
                state.isLoadingRoom = true
                state.error = undefined
            })
            .addCase(fetchRoomsCollectionByOffset.fulfilled, (state, action: PayloadAction<IChatsCollection[]>) => {
                state.isLoadingRoom = false
                state.collection = [
                    ...state.collection,
                    ...action.payload
                ]
                state.hasMore = action.payload.length > 0
            })
            .addCase(fetchRoomsCollectionByOffset.rejected, (state, action) => {
                state.isLoadingRoom = false
                state.error = action.payload
            })

            //create Chat
            .addCase(createRoom.pending, (state) => {
                state.isLoadingRoom = true
                if (state.createRoom) {
                    state.createRoom.errorsCreateRooms = undefined
                } else {
                    state.createRoom = {
                        errorsCreateRooms: undefined
                    }
                }
            })
            .addCase(createRoom.fulfilled, (state, action: PayloadAction<boolean>) => {
                if (action.payload) {
                    state.createRoom ={
                        successful: true,
                        errorsCreateRooms: undefined
                    }
                }
                state.isLoadingRoom = false
            })
            .addCase(createRoom.rejected, (state, action) => {
                state.isLoadingRoom = false
                state.createRoom = {
                    errorsCreateRooms: {
                        ...action.payload
                    }
                }
            })

            //fetch my collections chats
            .addCase(fetchMyRoomsCollection.pending, (state) => {
                state.isLoading = true
                state.error = undefined
            })
            .addCase(fetchMyRoomsCollection.fulfilled, (state, action: PayloadAction<IRoomsCollection[] | null>) => {
                if (action.payload) {
                    state.myCollection = [
                        ...action.payload
                    ]
                }
                state.isLoading = false
            })
            .addCase(fetchMyRoomsCollection.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.payload
            })

            //update chat request
            .addCase(updateRoomRequest.pending, (state) => {
                state.isLoadingRoom = true
                state.error = undefined
            })
            .addCase(updateRoomRequest.fulfilled, (state, action: PayloadAction<IRoomRequest>) => {
                state.roomRequests =  state.roomRequests?.filter(rm => rm.id !== action.payload.id)
                state.error = undefined
                state.isLoadingRoom = false
            })
            .addCase(updateRoomRequest.rejected, (state, action) => {
                state.isLoadingRoom = false
                state.error = action.payload
            })


            //fetch Chats Requests
            .addCase(fetchRoomsRequests.pending, (state) => {
                state.isLoading = true
                state.error = undefined
            })
            .addCase(fetchRoomsRequests.fulfilled, (state, action: PayloadAction<IRoomRequest[]>) => {
                state.roomRequests = action.payload
                state.error = undefined
                state.isLoading = false
            })
            .addCase(fetchRoomsRequests.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.payload
            })



            //set user status
            .addCase(fetchRoomsInfoById.fulfilled, (state, action: PayloadAction<IRoomsCollection>) => {
                if (action.payload) {
                    state.collection =  state.collection.map(rm => rm.id === action.payload.id ? {...action.payload} : rm)
                }
                state.isLoading = false
            })

        //join room
        // .addCase(joinRoomItem.fulfilled, (state, action: PayloadAction<IResponseJoin>) => {
        //     debugger
        //     if (action.payload.status !== 2) {
        //         state.collection = state.collection.map(rm => {
        //
        //             if (rm.id === action.payload.id) {
        //                 console.log('rm', rm)
        //                 return {
        //                     ...rm,
        //                     users: rm.users
        //                         ? rm?.users.map(us => {
        //                             // @ts-ignore
        //                             console.log('rm.users', [...rm.users])
        //                             if (us.id === action.payload.myId) {
        //                                 return {...us, status: action.payload.status}
        //                             } else {
        //                                 return us
        //                             }
        //                         })
        //                         : undefined
        //                 }
        //             } else {
        //                 return rm
        //             }
        //         }
        //         )
        //     }
        //     state.isLoading = false
        // })
        // .addCase(joinRoomItem.rejected, (state, action) => {
        //     state.error = action.payload
        // })


    },
})


export const roomsReducer = roomsSlice.reducer
export const roomsAction = roomsSlice.actions


