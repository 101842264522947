import {createAsyncThunk} from '@reduxjs/toolkit';
import {BaseResponse} from "../../../../api/api";
import {ThunkConfig} from "../../../config/StateSchema";
import {IProfileSpec, IProfileUser} from "../../../profile/types/profileTypes";
import {snackbarAction} from "../../../errors/SnackbarSlice";

export interface IResponseProfileInfo {
    info: IProfileSpec | IProfileUser
}

export const fetchProfileInfoById = createAsyncThunk<IProfileSpec | IProfileUser,
    string,
    ThunkConfig<string>>(
        'profileInfo/fetchProfileInfoById',
        async (id, thunkAPI) => {
            const {extra, rejectWithValue, dispatch} = thunkAPI;
            try {
                const response = await extra.api.get<BaseResponse<IResponseProfileInfo>>(`profile/${id}`)
                if (!response.data.success) {
                    return rejectWithValue('error')
                }
                return response.data.response.info
            } catch (err) {
                dispatch(snackbarAction.addError('INTERNAL_ERROR'))
                return rejectWithValue('error');
            }
        },
    );
