import {createAsyncThunk} from '@reduxjs/toolkit';
import {BaseResponse} from "../../../../api/api";
import {ThunkConfig} from "../../../config/StateSchema";
import {ICollectionConflictDate} from "../../types/IScheduler";
import {snackbarAction} from "../../../errors/SnackbarSlice";

interface IResponseCalendarDataCheck {
    collection: ICollectionConflictDate[]
}

export interface ISchedulerConflict {
    ignore_schedule_id?: number
    time_start: string
    time_end: string
    spec_id: number
    repeat_days?: number[],
    repeat_end?: string | number
}

export const checkConflictData = createAsyncThunk<ICollectionConflictDate[],
    ISchedulerConflict,
    ThunkConfig<string>>(
        'scheduler/checkConflictData',
        async (params, thunkAPI) => {
            const {extra, rejectWithValue, dispatch} = thunkAPI;
            try {
                const response = await extra.api.get<BaseResponse<IResponseCalendarDataCheck>>(`schedule/check`,
                    {
                        params: params
                    })
                if (!response.data.success) {
                    dispatch(snackbarAction.addError(response.data.codeKey))
                    return rejectWithValue('error')
                }
                return response.data.response.collection
            } catch (err) {
                dispatch(snackbarAction.addError('INTERNAL_ERROR'))
                // @ts-ignore
                return rejectWithValue(err);
            }
        },);

// const formattedDate = moment(date).format('YYYY-MM-DD');
