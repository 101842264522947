import {memo} from 'react';
import s from './HeaderLogout.module.css'
import styleHeaderItem
    from "../../navbar/NavbarBurger/NavbarMenuBurger/headerItemBurger/HeaderItemBurger.module.css";
import {useAppDispatch} from "../../../store/hooks/redux";
import {useNavigateToTop} from "../../../hooks/usenavigateToTop";
import {
    fetchAuthLogout
} from "../../../store/reducers/auth/services/fetchAuthLogout/fetchAuthLogout";

interface IHeaderLogout {
    title?: string | null
}

export const HeaderLogout = memo(({title}: IHeaderLogout) => {
    const dispatch = useAppDispatch()
    const navigateToTop = useNavigateToTop(false)

    const handlerLogout = async () => {
        try {
            await dispatch(fetchAuthLogout())
            navigateToTop('/')
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div className={s.header__logoutBlock}>
            <div className={styleHeaderItem.header__item}>
                <button className={`${s.header__logout} ${s.header__link}`}
                    onClick={handlerLogout}>
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M21.7539 10.75L27.0039 16L21.7539 21.25" stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M13 16H27" stroke="white" strokeWidth="2" strokeLinecap="round"
                            strokeLinejoin="round"/>
                        <path
                            d="M15 27H6C5.73478 27 5.48043 26.8946 5.29289 26.7071C5.10536 26.5196 5 26.2652 5 26V6C5 5.73478 5.10536 5.48043 5.29289 5.29289C5.48043 5.10536 5.73478 5 6 5H15"
                            stroke="white" strokeWidth="2" strokeLinecap="round"
                            strokeLinejoin="round"/>
                    </svg>
                    {title}
                </button>
            </div>
        </div>
    );
});
