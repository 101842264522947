import {createAsyncThunk} from '@reduxjs/toolkit';
import {ThunkConfig} from "../../../../config/StateSchema";
import {BaseResponse} from "../../../../../api/api";
import {IUserRelationship} from "../../../types/profileTypes";
import {profileInfoAction} from "../../../../profileInfo/profileInfoSlice";
import {getProfileId} from "../../../selectors/getProfile/getProfile";
import {snackbarAction} from "../../../../errors/SnackbarSlice";

export interface IResponseAddFriend {
    info: IUserRelationship
}

interface IResponse {
    user_id: number
}

export const addProfileFriend = createAsyncThunk<IUserRelationship,
    IResponse,
    ThunkConfig<string>>(
        'profile/addProfileFriend',
        async ({user_id}, thunkAPI) => {
            const {extra, rejectWithValue, dispatch, getState} = thunkAPI;
            const myId = getProfileId(getState())
            try {
                const response = await extra.api.post<BaseResponse<IResponseAddFriend>>(`relationship/friend`, {
                    user_id
                })
                if (!response.data.success) {
                    return rejectWithValue('error')
                }
                const {
                    id,
                    type,
                    status,
                    user,
                } = response.data.response.info
                if(myId) {
                    dispatch(profileInfoAction.setRelationCurrentUser(
                        {
                            user_id: user.id,
                            type,
                            id,
                            status,
                            created_by_user_id: myId
                        }
                    ))
                }
                return response.data.response.info
            } catch (err) {
                dispatch(snackbarAction.addError('INTERNAL_ERROR'))
                return rejectWithValue('error');
            }
        },
    );
