import {NavLink} from "react-router-dom";
import {IProfileSettingMenuHeader} from "../../../models/INotification";

type ProfileItemLinkProps = {
    datePath: IProfileSettingMenuHeader
    onCloseModal?: () => void
}

export const ProfileItemLink = ({datePath, onCloseModal}: ProfileItemLinkProps) => {
    const {imgSRC, path, isNotifications, title} = datePath
    const onClickHandler = () => {
        if (onCloseModal) {
            onCloseModal()
        }
    }
    return (
        <div key={title}
            onClick={onClickHandler}
        >
            <NavLink to={path}
                className={`menuOptions__link`}
            >
                <img src={imgSRC} alt={'setting'}/>
                {isNotifications && <span className={`newNoti`}></span>}
                {title}
            </NavLink>
        </div>
    );
};
