import {$api, BaseResponse} from "../../../../api/api";

interface IResponse {
    access_token: string
}

export const fetchTwilioGuestToken= async () => {
    try {
        const response = await $api.get<BaseResponse<IResponse>>(`auth/twilio/token`, )
        if(!response.data.success) {
            return false
        }
        return response.data.response.access_token
    } catch (e) {
        return false
    }
}
