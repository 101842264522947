import {createAsyncThunk} from '@reduxjs/toolkit';
import {ThunkConfig} from '../../../config/StateSchema';
import {snackbarAction} from '../../../errors/SnackbarSlice';
import {BaseResponse} from "../../../../api/api";
import {INotification} from "../../types/notificationsTypes";
import {notificationsAction} from "../../notificationsSlice";

interface IResponse {
    info: INotification
}

interface IPayload {
    id: number
}

export const markReadeNotification = createAsyncThunk<
    INotification,
    IPayload,
    ThunkConfig<string>
>(
    'notifications/markReadeNotification',
    async ({id}, thunkAPI) => {
        const {extra, rejectWithValue, dispatch} = thunkAPI;
        dispatch(notificationsAction.addIdInNotificationIsLoading(id))
        try {
            const response = await extra.api.put<BaseResponse<IResponse>>(
                `notification/${id}/mark-read`);
            dispatch(notificationsAction.removeIdInNotificationIsLoading(id))
            if (!response.data.success) {
                return rejectWithValue('error')
            }
            return response.data.response.info

        } catch (err) {
            dispatch(notificationsAction.removeIdInNotificationIsLoading(id))
            dispatch(snackbarAction.addError('INTERNAL_ERROR'))
            console.log('err', err)
            // @ts-ignore
            return rejectWithValue(err.data.error);
        }
    },
);
