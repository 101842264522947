import {useCallback, useEffect} from "react";

export const useCloseMenuOnScroll = (isShowMenu: boolean, onClose: () => void, isMobileWidth: boolean) => {

    const closeMenuProfile = useCallback(() => {
        onClose()
    }, [onClose])

    // }, [isOpen]);
    useEffect(() => {
        const body = document.body
        if (!isMobileWidth) {
            if (isShowMenu) {
                window.addEventListener('scroll', closeMenuProfile);
            }
            if (!isShowMenu) {
                window.removeEventListener('scroll', closeMenuProfile);
            }
        } else {
            if (isShowMenu) {
                body.classList.add('no-scroll');
            }
            if (!isShowMenu) {
                body.classList.remove('no-scroll')
            }
        }

        return () => {
            window.removeEventListener('scroll', closeMenuProfile);
            body.classList.remove('no-scroll')
        };
    }, [closeMenuProfile, isMobileWidth, isShowMenu]);
}
