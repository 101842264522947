import React from 'react';
import s from './SpecialistPrice.module.css'
import {ReactComponent as PriceSVG} from "../../../utils/images/icons/price.svg";
import {classNames} from "../../../utils/helpers/classNames/classNames";

type SpecialistPriceProps = {
    price: number
    variant?: 'center' | 'end' | 'start'
    className?: string
}

export const SpecialistPrice = (props: SpecialistPriceProps) => {
    const {variant = 'end', price, className} = props

    return (
        <div className={classNames(s.price, {}, [className, s[variant]])}>
            <PriceSVG/>
            {price}
            <span>
                $
            </span>
        </div>
    );
};
