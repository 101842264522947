import {
    memo,
    ReactNode, useCallback, useEffect, useRef, useState,
} from 'react';
import {ReactComponent as Close} from '../../utils/images/icons/close-popover.svg'
import s from './Modal.module.css';
import {Portal} from "./Portal/Portal";
import {classNames, Mods} from "../../utils/helpers/classNames/classNames";

interface ModalProps {
    className?: string
    children?: ReactNode
    isOpen?: boolean
    onClose?: () => void
    isCloseIcon?: boolean
    isCloseOnClick?: boolean
    lazy?: boolean
    // isHiddenScrollBody?: boolean
    isCalendar?: boolean
    isCalendarForm?: boolean
    isNotification?: boolean
    isVideo?: boolean
    isMoreZIndex?: boolean
    isCenter?: boolean
    isShowCloseWithBackground?: boolean
    isImg?: boolean
}

const ANIMATION_DELAY = 300;

export const Modal = memo((props: ModalProps) => {

    const {
        className,
        children,
        onClose,
        isOpen,
        lazy,
        // isHiddenScrollBody = true,
        isCloseIcon = true,
        isCalendar = false,
        isNotification = false,
        isCalendarForm = false,
        isVideo = false,
        isMoreZIndex =false,
        isCloseOnClick = false,
        isCenter = true,
        isShowCloseWithBackground =false,
        isImg = false
    } = props;

    const [isClosing, setIsClosing] = useState(false);
    const [isMounted, setIsMounted] = useState(false);
    const [isOpening, setIsOpening] = useState(false);
    const timerRef = useRef<ReturnType<typeof setTimeout>>();



    useEffect(() => {
        const body = document.body
        if (isOpen) {
            setIsMounted(true);
            body.classList.add('no-scroll');
        }
        // Убираем модалку из дома
        return () => {
            setIsMounted(false);
            body.classList.remove('no-scroll')
        };
    }, [isOpen]);

    const closeHandler = useCallback(() => {
        if (onClose) {
            if (isCalendarForm) {
                onClose();
                // setIsClosing(false);
            } else {
                setIsClosing(true);
                timerRef.current = setTimeout(() => {
                    onClose();
                    setIsClosing(false);
                }, ANIMATION_DELAY);
            }
        }
    }, [isCalendarForm, onClose]);

    const onContentClick = (e: React.MouseEvent) => {
        e.stopPropagation();
    };

    const onKeyDown = useCallback((e: KeyboardEvent) => {
        if (e.key === 'Escape') {
            closeHandler();
        }
    }, [closeHandler]);


    // useEffect(() => {
    //     const body = document.body
    //     if (isHiddenScrollBody && isOpen) {
    //         body.classList.add('no-scroll');
    //     }
    //     return () => {
    //         body.classList.remove('no-scroll')
    //     }
    // }, [isHiddenScrollBody, isOpen])

    useEffect(() => {
        if (isOpen) {
            timerRef.current = setTimeout(() => {
                setIsOpening(true);
            }, 0);
            window.addEventListener('keydown', onKeyDown);
        }
        return () => {
            setIsOpening(false);
            clearTimeout(timerRef.current);
            window.removeEventListener('keydown', onKeyDown);
        };
    }, [isOpen, onKeyDown]);

    if (lazy && !isMounted) {
        return null;
    }

    const mods: Mods = {
        [s.isClosing]: isClosing,
        [s.opened]: isOpening && !isCalendar,
        [s.openedCalendar]: isOpening && isCalendar,
        [s.notification]: isNotification,
        [s.moreZIndex]: isMoreZIndex,
        [s.center]: isCenter,
    };

    const modsOverlay: Mods = {
        [s.img] : isImg
    };

    const contentMods: Mods = {
        [s.contentCalendar]: isCalendar,
        [s.contentMain]: !isCalendar,
        [s.contentVideo]: isVideo
    }
    const closeMods: Mods = {
        [s.closeCalendar]: isCalendar,
        [s.closeMain]: !isCalendar,
        [s.closeVideo]: isShowCloseWithBackground
    }

    return (
        <Portal>
            <div className={classNames(s.Modal, mods, [className as string])}
                onClick={isCloseOnClick ? closeHandler : undefined}
            >
                <div
                    className={classNames(s.overlay, modsOverlay, [className as string])}
                    onClick={isCloseOnClick ? closeHandler : undefined}
                >
                    <div
                        className={classNames(s.content, contentMods, [className as string])}
                        onClick={onContentClick}
                    >
                        {onClose
                            && isCloseIcon
                            && !isNotification
                            && !isCalendarForm
                            && <div className={s.modalHead}>
                                <button
                                    className={classNames(s.close, closeMods, [className as string])}
                                    onClick={closeHandler}>
                                    <Close className={s.close}/>
                                </button>
                            </div>}
                        {children}
                    </div>
                </div>
            </div>
        </Portal>
    );
});
