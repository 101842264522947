import {AppDispatch} from "../../../../store";
import {
    fetchNotificationsRequestsChat
} from "./fetchNotificationsRequestsChat/fetchNotificationsRequestsChat";
import {
    fetchNotificationsRequestsFriend
} from "./fetchNotificationsRequestsFriend/fetchNotificationsRequestsFriend";
import {
    fetchNotificationsRequestsRoom
} from "./fetchNotificationsRequestsRoom/fetchNotificationsRequestsRoom";


export const fetchNotificationsRequests = async (dispatch: AppDispatch) => {
    try {
        dispatch(fetchNotificationsRequestsChat())
        dispatch(fetchNotificationsRequestsFriend())
        dispatch(fetchNotificationsRequestsRoom())
    } catch (e) {
        console.log(e)
    }
}
