//spec
import {Errors} from "../../../api/api";
import {
    StatusFilterCabinetType,
    StatusFilterProfileSpecialist
} from "../../../../models/ITabsFilter";
import {ICalendarResponse} from "../../scheduler/types/IScheduler";

export interface IProfileInitialState {
    errors?: string
    isLoading: boolean
    isLoadingProfile: boolean
    profile?: IProfileSpec | IProfileUser
    verification?: IVerification
    clients?: IUserRelationship[]
    blackList?: IUserRelationship[]
    statusFilterFriends?: StatusFilterProfileSpecialist
    statusFilterCabinet?: StatusFilterCabinetType


    friends?: IUserRelationship[]
    findFriends?: ISearchUsers[]
    events?: ICalendarResponse[]

    isMounted: boolean
    hasMore: boolean
    isDataAvailable: boolean

    userRelationship?: IUserRelationship[]
}

export enum Relationship {
    FRIENDSHIP = 1,
    BLACKLIST = 2,
    CLIENT = 3
}

export interface IAttachments {
    id: number
    path: string
    type: number
}

//verification
export interface IProfileVerification {
    name?: string
    phone_number?: string
    location?: string
    about_text?: string
    language?: string
    experience_text?: string
    demo_video_link?: string
}

export interface IProfileSpec extends IProfile {
    user_group_id: UserGroup.SPEC | UserGroup.SPEC_PREMIUM
    name?: string
    experience_text?: string
    demo_video_link?: string
    language?: string[]
    cost_session_single?: number
    cost_session_group?: number
}

//user
export interface IProfileUser extends IProfile {
    user_group_id: UserGroup.USER | UserGroup.USER_PREMIUM
    experience_text?: string
    demo_video_link?: string
}

export enum UserGroup {
    ADMIN = 1,
    USER = 2,
    USER_PREMIUM = 3,
    SPEC = 4,
    SPEC_PREMIUM = 5,
    Guest = 6,
}

export interface IProfile {
    id: number
    email: string
    user_group_id: UserGroup
    nickname?: string
    phone_number?: string
    telegram_nickname?: string
    avatar?: string
    location?: string
    about_text?: string
    status_id?: number
    attachments?: IAttachments[]
    personal_id?: string
    problems_ids?: number[]
    addictions_ids?: number[]
    approval_status_id?: 0 | 1
    relation_current_user?: IRelationCurrentUser[]
    has_active_schedule?: boolean
}

//verification specialist
export interface IVerification {
    errorsVerification?: Errors<IVerification>
    successful?: boolean
    name?: string
    phone_number?: string
    location?: string
    language?: string[]
    experience_text?: string
    demo_video_link?: string
    about_text?: string
    certificates?: string[]
    avatar?: string // type???
    addictions_ids?: number[]
    problems_ids?: number[]
    passports?: string[]
}

//clients
export interface IUserClient {
    id: number
    avatar?: string
    personal_id: string
    email?: string
    verified_email?: string
    name?: string
    nickname: string
    user_group_id: UserGroup
}

export interface IUserRelationship {
    id: number
    type: 1 | 2 | 3
    created_by_user_id: number
    incoming?: boolean
    status?: 0 | 1
    user: IUserClient
}

//collection User
export interface IRelationCurrentUser {
    id: number
    type?: Relationship
    user_id: number
    status?: 0 | 1
    created_by_user_id: number
}

export interface ISearchUsers extends IProfile {
    name?: string
}
