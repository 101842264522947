import {ButtonHTMLAttributes, DetailedHTMLProps, FC, memo} from 'react'
import s from './CustomButton.module.css'
import {classNames, Mods} from "../../utils/helpers/classNames/classNames";

type DefaultButtonPropsType = DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>

type MyButtonPropsType = DefaultButtonPropsType & {
    variant?: 'blue' | 'pink' | 'light'
    size?: 'small' | 'big' | 'w100' | 'medium'
}

export const CustomButton: FC<MyButtonPropsType> = memo((
    {
        className, variant = 'blue',
        size = 'small',
        disabled,
        ...restProps
    }
) => {

    const mods: Mods = {
        'disabled': disabled
    };

    return (
        <button
            className={classNames(s.btn, mods, [className, s[variant], s[size]])}
            {...restProps}
            disabled={disabled}
        />
    )
});

