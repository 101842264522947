import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ISnackbar} from "./types/snackbarTypes";
import {INotification, INotificationPrivateChat} from "../notifications/types/notificationsTypes";


const initialState: ISnackbar = {
    errors: [
        // {
        //     message: "SCHEDULE_START",
        //     id: 2
        // }
    ],
    notifications: [
        // {
        //     'event': 'SCHEDULE_START',
        //     id: 1,
        //     date_creat: '123',
        //     user_id: 123123,
        //     is_self: 0,
        //     schedule: {
        //         name: 'Конференция для умных людей в поисках мира и богатств',
        //         time_end: '123',
        //         time_start: '123',
        //         room: {
        //             id: 1,
        //             name: 'Супер комната для мотивации'
        //         },
        //         id: 1,
        //         date: '123',
        //         spec: {
        //             name: 'Виталий Витальевитч Гущаываыв аыв а',
        //             id: 1,
        //             user_group_id: 3,
        //             email: '',
        //             nickname: '',
        //             personal_id: '',
        //             verified_email: ''
        //         },
        //     }
        // }
    ]
}

export const snackbarSlice = createSlice({
    name: 'errorsSlice',
    initialState,
    reducers: {
        addError(state, action: PayloadAction<string>) {
            if (state.errors && state.errors.length > 0) {
                state.errors = [
                    ...state.errors,
                    {
                        message: action.payload,
                        id: state.errors.length + 1
                    }]
            } else {
                state.errors = [
                    {
                        message: action.payload,
                        id: 1
                    }
                ]
            }
        },
        clearError(state, action: PayloadAction<number>) {
            if (state.errors && state.errors.length > 1) {
                state.errors = state.errors.filter(er => er.id !== action.payload)
            } else {
                state.errors = undefined
            }
        },

        addNewNotification(state, action: PayloadAction<INotification>) {
            if (!window.location.href.includes('calls')) {
                if (state.notifications) {
                    state.notifications = [...state.notifications, action.payload]
                } else {
                    state.notifications = [action.payload]
                }
            }
        },
        addNewNotificationPrivateMessage(state, action: PayloadAction<INotificationPrivateChat>) {
            if (!window.location.href.includes('calls')) {
                if(state.notificationPrivateMessage === undefined){
                    state.notificationPrivateMessage = action.payload
                }
            }
        },
        clearNotificationPrivateMessage(state) {
            state.notificationPrivateMessage = undefined
        },
        clearNotification(state, action: PayloadAction<number>) {
            if (state.notifications && state.notifications.length > 1) {
                state.notifications = state.notifications.filter(er => er.id !== action.payload)
            } else {
                state.notifications = undefined
            }
        },
    },
})


export const snackbarReducer = snackbarSlice.reducer
export const snackbarAction = snackbarSlice.actions


