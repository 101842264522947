import {createAsyncThunk} from '@reduxjs/toolkit';
import {ThunkConfig} from "../../../../config/StateSchema";
import {IChatsCollectionsQuery} from "../../../../chats/types/chatsTypes";
import {ISearchUsers} from "../../../types/profileTypes";
import {BaseResponse} from "../../../../../api/api";
import {snackbarAction} from "../../../../errors/SnackbarSlice";

export interface IProfileFriendsQuery extends Omit<IChatsCollectionsQuery, 'sort_members' & 'is_private'> {}

interface IResponse {
    collection: ISearchUsers[]
}


export const searchProfileFriends = createAsyncThunk<ISearchUsers[],
    IProfileFriendsQuery | void,
    ThunkConfig<string>>(
        'profile/searchProfileFriends',
        async (data, thunkAPI) => {
            const {extra, rejectWithValue, dispatch} = thunkAPI;
            try {
                const response = await extra.api.get<BaseResponse<IResponse>>('profile/collection-user', {
                    params: data
                })
                if (!response.data.success) {
                    return rejectWithValue('error')
                }
                return response.data.response.collection
            } catch (err) {
                dispatch(snackbarAction.addError('INTERNAL_ERROR'))
                return rejectWithValue('error');
            }
        },
    );
