import {createAsyncThunk} from '@reduxjs/toolkit';
import {ThunkConfig} from "../../../../config/StateSchema";
import {BaseResponse} from "../../../../../api/api";
import {IChatsCollection} from "../../../types/chatsTypes";
import {IParamsCreatePersonalChat} from "../fetchPersonalChat/fetchPersonalChat";
import {navigatePersonalChat} from "../navigatePersonalChat";
import {snackbarAction} from "../../../../errors/SnackbarSlice";

interface IResponse {
    info: IChatsCollection
}

export const createPersonalChat = createAsyncThunk<void,
    IParamsCreatePersonalChat,
    ThunkConfig<string>>(
        'chats/createPersonalChat',
        async ({idUser, navigate}, thunkAPI) => {
            const {extra, rejectWithValue, dispatch} = thunkAPI;
            try {
                const response = await extra.api.post<BaseResponse<IResponse>>(`chat/user/${idUser}/chat`)

                if (!response.data.success) {
                    rejectWithValue('error')
                }
                if (response.data.success && response.data.response.info.twilio_id) {
                    dispatch(navigatePersonalChat({
                        twilio_id: response.data.response.info.twilio_id,
                        navigate
                    }))
                }


            } catch (err) {
                dispatch(snackbarAction.addError('INTERNAL_ERROR'))
                return rejectWithValue('error');
            }
        },
    );
