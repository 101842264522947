import {createAsyncThunk} from '@reduxjs/toolkit';
import {ThunkConfig} from "../../../config/StateSchema";
import {RoutePath} from "../../../../../router/routerConfig/routerConfig";
import {NavigateFunction} from "react-router/dist/lib/hooks";
import {getProfileId} from "../../../profile/selectors/getProfile/getProfile";
import {chatsAction} from "../../chatsSlice";
import {checkIfInChat} from "../../../chatItem/services/checkIfInChat/checkIfInChat";
import {joinChatItem} from "../../../chatItem/services/joinChatItem/joinChatItem";

interface IRequestParams {
    idChat: number,
    navigate: NavigateFunction
}

export const navigateChatThunk = createAsyncThunk<void,
    IRequestParams,
    ThunkConfig<string>>(
        'chats/navigateChat',
        async ({idChat, navigate}, thunkAPI) => {
            const {dispatch, getState} = thunkAPI;
            const myId = getProfileId(getState())

            dispatch(chatsAction.setIsLoadingChat(true))
            try {
                const isMyChat = await checkIfInChat(idChat, myId, dispatch)
                if (isMyChat) {
                    navigate(RoutePath.chat_view + idChat)
                } else {
                    await dispatch(joinChatItem({
                        id: idChat, navigate: navigate
                    }))
                }
            } catch (e) {
                console.log(e)
            } finally {
                dispatch(chatsAction.setIsLoadingChat(false))
            }
        },
    );
