import {memo, useCallback, useState} from 'react';
import s from './HeaderProfile.module.css'
import notificationStyle from '../../../common/HeaderNotification.module.css'
import {useAppSelector} from "../../../store/hooks/redux";
import {ProfileMenuHeader} from "../../navbar/profileMenuHeader/ProfileMenuHeader";
import {useOutside} from "../../../hooks/useOutside";
import {useCloseMenuOnScroll} from "../../../hooks/useCloseMenuOnScroll";
import {
    getProfile,
    getProfileGroupId
} from "../../../store/reducers/profile/selectors/getProfile/getProfile";

import avatarDef from '../../../utils/images/avatars/01.svg'
import {UserGroup} from "../../../store/reducers/profile/types/profileTypes";
import {
    getProfileName
} from "../../../store/reducers/profile/selectors/getProfileName/getProfileName";

type HeaderProfileProps = {
    closeMenuBurger?: () => void
}

export const HeaderProfile = memo(({closeMenuBurger}: HeaderProfileProps) => {
    const profile = useAppSelector(getProfile)
    const name = useAppSelector(getProfileName)
    const {avatar, nickname} = profile || {}
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [isOnline, setIsOnline] = useState<boolean>(true)
    const profileGroupId = useAppSelector(getProfileGroupId)
    const {
        isShow: isShowMenuProfile,
        setIsShow: setIsShowMenuProfile,
        ref: refMenuProfile
    } = useOutside(false)

    //закрытие меню при скролле
    const closeMenuProfile = () => {
        setIsShowMenuProfile(false)
    }
    useCloseMenuOnScroll(isShowMenuProfile, closeMenuProfile, false)


    const showMenuProfileHandler = useCallback(() => {
        setIsShowMenuProfile(prev => !prev)
        if (closeMenuBurger) {
            closeMenuBurger()
        }
    }, [closeMenuBurger, setIsShowMenuProfile])

    const onCloseModalLink = useCallback(() => {
        setIsShowMenuProfile(false)
    }, [setIsShowMenuProfile])

    return (
        <div className={`${notificationStyle.header__notifications} ${s.profile}`}>

            <div className={s.header__notificationLabel}
                onClick={showMenuProfileHandler}
                ref={refMenuProfile}>
                <div className={s.header__userPic}>
                    <img src={avatar ? avatar : avatarDef} alt={'Profile Avatar'}/>
                    <span
                        className={`${s.userPicStatus} ${isOnline ? s.online : s.offline}`}></span>
                </div>
                <div className={s.header__userName}>
                    {name || nickname}
                </div>
            </div>

            {/*// @ts-ignore*/}
            {profileGroupId !== UserGroup.Guest
                && <ProfileMenuHeader
                    isShow={isShowMenuProfile}
                    onCloseModal={onCloseModalLink}/>}
        </div>
    );
});
