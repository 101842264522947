import {createAsyncThunk} from '@reduxjs/toolkit';
import {ThunkConfig} from "../../../../config/StateSchema";
import {BaseResponse} from "../../../../../api/api";
import {profileInfoAction} from "../../../../profileInfo/profileInfoSlice";
import {snackbarAction} from "../../../../errors/SnackbarSlice";


interface IResponse {
    idRelationship: number
    idUser?: number
}

export const declineProfileFriend = createAsyncThunk<true,
    IResponse,
    ThunkConfig<string>>(
        'profile/deleteProfileFriend',
        async ({idRelationship}, thunkAPI) => {
            const {extra, rejectWithValue, dispatch} = thunkAPI;
            try {
                const response = await extra.api.post<BaseResponse>(`relationship/friend/${idRelationship}/decline`,)

                if (!response.data.success) {
                    dispatch(snackbarAction.addError('INTERNAL_ERROR'))
                    return  rejectWithValue('error')
                }
                dispatch(profileInfoAction.setRelationCurrentUser())
                return response.data.success
            } catch (err) {
                console.log('!response.data.success', err)
                dispatch(snackbarAction.addError('INTERNAL_ERROR'))
                return rejectWithValue('error');
            }
        },
    );
