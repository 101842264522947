import {createAsyncThunk} from '@reduxjs/toolkit';
import {ThunkConfig} from "../../../config/StateSchema";
import {IRequestSpecialistsFind, ISpecialistFindCollection} from "../../types/specialistFindTypes";
import {BaseResponse} from "../../../../api/api";
import {snackbarAction} from "../../../errors/SnackbarSlice";
import {addQueryParams} from "../../../../../utils/helpers/addQueryParams/addQueryParams";

export interface IResponseUpdateProfile {
    collection: ISpecialistFindCollection[]
}

export const fetchSpecialistsFind = createAsyncThunk<ISpecialistFindCollection[],
    IRequestSpecialistsFind,
    ThunkConfig<string>>(
        'specialistsFind/fetchSpecialistsFind',
        async (data, thunkAPI) => {
            const {extra, rejectWithValue, dispatch} = thunkAPI;
            addQueryParams({
                cost: [data.cost_min, data.cost_max],
                language: data.language,
                addictions: data["addictions_ids[]"],
                problems: data["problems_ids[]"],
            })
            try {
                const response = await extra.api.get<BaseResponse<IResponseUpdateProfile>>('profile/collection-spec', {
                    params: data
                })
                if (!response.data.success) {
                    return rejectWithValue('error')
                }
                // if (response.data.response.collection?.length === 0) {
                //     dispatch(fetchSpecialistsFindWithEmptyData())
                // }
                return response.data.response.collection
            } catch (err) {
                dispatch(snackbarAction.addError('INTERNAL_ERROR'))
                return rejectWithValue('error');
            }
        },
    );
