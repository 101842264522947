import {FC, memo, useMemo} from 'react';
import {useTranslation} from "react-i18next";

import profile from '../../../utils/images/menu-header/profile.svg'
import chat from '../../../utils/images/menu-header/chat.svg'
import cabinet from '../../../utils/images/menu-header/cabinet.svg'
import room from '../../../utils/images/menu-header/room.svg'
import friends from '../../../utils/images/menu-header/friends.svg'
import message from '../../../utils/images/menu-header/message.svg'
import setting from '../../../utils/images/menu-header/setting.svg'
import {CSSTransition} from "react-transition-group";
import {ProfileItemLink} from "./ProfileItemLink";
import {useAppSelector} from "../../../store/hooks/redux";
import {IProfileSettingMenuHeader} from "../../../models/INotification";
import {RoutePath} from "../../../router/routerConfig/routerConfig";
import {
    getProfileGroupId
} from "../../../store/reducers/profile/selectors/getProfile/getProfile";
import {UserGroup} from "../../../store/reducers/profile/types/profileTypes";

type ProfileMenuHeaderPropsType = {
    className?: string
    isShow: boolean
    onCloseModal: () => void
}

export const ProfileMenuHeader = memo((props: ProfileMenuHeaderPropsType) => {
    const {
        isShow,
        onCloseModal,
        className
    } = props

    const groupID = useAppSelector(getProfileGroupId)
    const {t} = useTranslation(['profileSetup'])

    const titleProfilePath: IProfileSettingMenuHeader[] =useMemo(()=> [
        {title: t('profileSetup:menu.title-myProfile'), path: RoutePath.profile, imgSRC: profile},
        {title: t('profileSetup:menu.title-myChats'), path: RoutePath.profile_chats, imgSRC: chat},
        {title: t('profileSetup:menu.title-myRooms'), path: RoutePath.profile_rooms, imgSRC: room},
        {
            title: t('profileSetup:menu.title-myFriends'),
            path: RoutePath.profile_friends,
            imgSRC: friends
        },
        {
            title: t('profileSetup:menu.title-myMessages'),
            path: RoutePath.profile_messages,
            imgSRC: message
        },
        {
            title: t('profileSetup:menu.title-myCabinet'),
            path: RoutePath.profile_cabinet,
            imgSRC: cabinet
        },
        {
            title: t('profileSetup:menu.title-profileSetup'),
            path: RoutePath.profile_setting,
            imgSRC: setting
        },

    ], [t])

    const titleProfilePathSpecialist: IProfileSettingMenuHeader[] =useMemo(()=> [
        {
            title: t('profileSetup:menu.title-myProfile'),
            path: RoutePath.specialist,
            imgSRC: profile
        },
        {
            title: t('profileSetup:menu.title-myChats'),
            path: RoutePath.specialist_chats,
            imgSRC: chat
        },
        {
            title: t('profileSetup:menu.title-myRooms'),
            path: RoutePath.specialist_rooms,
            imgSRC: room
        },
        {
            title: t('profileSetup:menu.title-myFriends'),
            path: RoutePath.specialist_friends,
            imgSRC: friends
        },
        {
            title: t('profileSetup:menu.title-myMessages'),
            path: RoutePath.specialist_messages,
            imgSRC: message
        },
        {
            title: t('profileSetup:menu.title-myCabinet'),
            path: RoutePath.specialist_cabinet,
            imgSRC: cabinet
        },
        {
            title: t('profileSetup:menu.title-ProfileVerification'),
            path: RoutePath.specialist_verification,
            imgSRC: setting
        },
        {
            title: t('profileSetup:menu.title-profileSetup'),
            path: RoutePath.specialist_setting,
            imgSRC: setting
        },

    ], [t])

    return (
        <CSSTransition timeout={500}
            in={isShow}
            classNames={'headerMenuSettingProfile'}
            mountOnEnter
            unmountOnExit>
            <div
                className={`header__notificationBox ${className ? className : ''}`}>
                <div className={`notiWrp`}>
                    <div className={`notiWrp__title`}>
                        {t('title')}
                    </div>
                    {groupID === UserGroup.SPEC_PREMIUM || groupID === UserGroup.SPEC
                        ?
                        titleProfilePathSpecialist.map(el => <ProfileItemLink key={el.path}
                            onCloseModal={onCloseModal}
                            datePath={{...el}}/>)
                        :
                        titleProfilePath.map(el => <ProfileItemLink key={el.path}
                            onCloseModal={onCloseModal}
                            datePath={{...el}}/>)
                    }

                </div>
            </div>
        </CSSTransition>
    );
});
