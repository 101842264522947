import {createAsyncThunk} from '@reduxjs/toolkit';
import {ThunkConfig} from "../../../../config/StateSchema";
import {BaseResponse} from "../../../../../api/api";
import {snackbarAction} from "../../../../errors/SnackbarSlice";
import {IChatRequest} from "../../../../chats/types/chatsTypes";


interface IResponse {
    collection: IChatRequest[]
}


export const fetchNotificationsRequestsChat = createAsyncThunk<
    number,
    void,
    ThunkConfig<string>
>(
    'notifications/fetchNotificationsRequestsChat',
    async (_, thunkAPI) => {
        const {extra, rejectWithValue, dispatch} = thunkAPI;
        try {
            const response = await extra.api.get<BaseResponse<IResponse>>('chat/request/collection', {
                params: {
                    status: 0
                }
            })
            if (!response.data.success) {
                return rejectWithValue('error')
            }

            return response.data.response.collection.length
        } catch (err) {
            dispatch(snackbarAction.addError('INTERNAL_ERROR'))
            return rejectWithValue('error');
        }
    }
);
