import {createAsyncThunk} from '@reduxjs/toolkit';
import {ThunkConfig} from "../../../config/StateSchema";
import {BaseResponse} from "../../../../api/api";
import {snackbarAction} from "../../../errors/SnackbarSlice";
import {IRoomRequest} from "../../types/roomsTypes";

interface IResponse {
    collection: IRoomRequest[]
}

export const fetchRoomsRequests = createAsyncThunk<
    IRoomRequest[],
   void,
    ThunkConfig<string>
>(
    'rooms/fetchRoomsRequests',
    async (_, thunkAPI) => {
        const {extra, rejectWithValue, dispatch} = thunkAPI;
        try {
            const response = await extra.api.get<BaseResponse<IResponse>>('room/request/collection')

            if (!response.data.success) {
                return rejectWithValue('error')
            }

            return response.data.response.collection
        } catch (err) {
            dispatch(snackbarAction.addError('INTERNAL_ERROR'))
            return rejectWithValue('error');
        }
    },
);
