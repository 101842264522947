import {createAsyncThunk} from '@reduxjs/toolkit';
import {ThunkConfig} from "../../../config/StateSchema";
import {BaseResponse} from "../../../../api/api";
import {IChatsCollection} from "../../types/chatsTypes";
import {snackbarAction} from "../../../errors/SnackbarSlice";

interface IResponse {
    info: IChatsCollection
}

export const fetchChatsInfoById = createAsyncThunk<IChatsCollection,
   number,
    ThunkConfig<string>>(
        'chats/fetchChatsInfoById',
        async (id, thunkAPI) => {
            const {extra, rejectWithValue, dispatch} = thunkAPI;
            try {
                const response = await extra.api.get<BaseResponse<IResponse>>(`chat/${id}`)

                if (!response.data.success) {
                    return rejectWithValue('error')
                }

                return response.data.response.info
            } catch (err) {
                dispatch(snackbarAction.addError('INTERNAL_ERROR'))
                return rejectWithValue('error');
            }
        },
    );
