import {createAsyncThunk, DeepPartial} from '@reduxjs/toolkit';
import {ThunkConfig} from "../../../config/StateSchema";
import {BaseResponse} from "../../../../api/api";
import {IProfileSpec, IProfileUser} from "../../types/profileTypes";
import {snackbarAction} from "../../../errors/SnackbarSlice";

export interface IResponseUpdateProfile {
    info: IProfileSpec | IProfileUser
}

export const updateProfile = createAsyncThunk<IResponseUpdateProfile,
    DeepPartial<IProfileSpec | IProfileUser>,
    ThunkConfig<string>>(
        'profile/updateProfile',
        async (data, thunkAPI) => {
            const {extra, rejectWithValue, dispatch} = thunkAPI;
            try {
                const response = await extra.api.put<BaseResponse<IResponseUpdateProfile>>('profile/update', {
                    ...data
                })
                if (!response.data.success) {
                    return rejectWithValue('error')
                }
                return response.data.response
            } catch (err) {
                dispatch(snackbarAction.addError('INTERNAL_ERROR'))
                return rejectWithValue('error');
            }
        },
    );
