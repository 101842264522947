import {useMemo} from "react";
import {IConstantCollection} from "../../store/reducers/constants/types/constantsTypes";


export const useReturnDependencies = (arrsData?: IConstantCollection[], arrUserDependencies?: number[]) => {

    return useMemo(() => {
        if (arrsData === undefined || arrUserDependencies === undefined)
            return []
        const data = arrUserDependencies.map(el => Number(el))
        return arrsData.filter(el => data.includes(el.id))

    }, [arrUserDependencies, arrsData])
}
