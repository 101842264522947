import {createSlice, PayloadAction} from "@reduxjs/toolkit";


type PageType = {
    idTest: string,
    selectedItem: string[]
}

const initialState: PageType[] = []

export const testSlice = createSlice({
    name: 'test',
    initialState,
    reducers: {

        addRadioInputActive(state, action: PayloadAction<{ idTest: string, idSelectedItem: string }>) {
            if (!state.some(el => el.idTest === action.payload.idTest)) {
                state = [...state, {idTest: action.payload.idTest, selectedItem: []}]
            }
            return state.map(el => el.idTest === action.payload.idTest
                ? {...el, selectedItem: [action.payload.idSelectedItem]}
                : el
            )
        },
        addCheckboxActive(state, action: PayloadAction<{ idTest: string, idSelectedItem: string }>) {
            if (!state.some(el => el.idTest === action.payload.idTest)) {
                state = [...state, {idTest: action.payload.idTest, selectedItem: []}]
            }
            return state.map(el => el.idTest === action.payload.idTest
                ? {
                    ...el,
                    selectedItem: (
                        el.selectedItem.some(item => item === action.payload.idSelectedItem)
                            ?
                            el.selectedItem.filter(item => item !== action.payload.idSelectedItem)
                            : [...el.selectedItem, action.payload.idSelectedItem]
                    )
                }
                : el)
        },
        resetState() {
            return []
        }
    },
    extraReducers: () => {
    },
})


export const testReducer = testSlice.reducer
export const testAction = testSlice.actions


// addCheckboxActive(state, action: PayloadAction<{ idTest: string, idSelectedItem: string }>) {
//             return state.map(el => el.idTest === action.payload.idTest
//                 ? {
//                     ...el,
//                     selectedItem: (
//                         el.selectedItem.some(item => item === action.payload.idSelectedItem)
//                             ?
//                           el.selectedItem.filter(item => item !== action.payload.idSelectedItem)
//                             : el.selectedItem.push(action.payload.idSelectedItem)
//                     )
//                 }
//                 : el)
//         },
