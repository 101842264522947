import moment from "moment";
import {IAppointmentModelData, ICalendarResponse} from "../../types/IScheduler";

export const convertToResponseToAppointmentModelData = (calendarResponse: Partial<ICalendarResponse>): Partial<IAppointmentModelData> => {
    return ({
        id: calendarResponse.id?.toString(),
        author: calendarResponse.spec && {
            id: calendarResponse.spec.id,
            name: calendarResponse.spec.name ? calendarResponse.spec.name : calendarResponse.spec.nickname,
            email: calendarResponse.spec.email,
        },
        members: calendarResponse.users?.map((user) => ({
            idMember: user.id,
            nameMember: user.nickname,
            email: user.email,
            payment_status: user.payment_status,
        })),
        room: calendarResponse?.room
            ? {
                name: calendarResponse.room.name,
                description: calendarResponse.room.description,
                id: calendarResponse.room.id
            }
            : undefined,
        notes: calendarResponse.description,
        startDate: calendarResponse.time_start ? moment(calendarResponse.time_start, 'YYYY-MM-DD HH:mm:ss').toDate() : new Date(),
        endDate: calendarResponse.time_end ? moment(calendarResponse.time_end, 'YYYY-MM-DD HH:mm:ss').toDate() : new Date(),
        maxMembers: calendarResponse.members_limit?.toString(),
        title: calendarResponse.name,
        cost: calendarResponse.cost_per_user?.toString(),
        typeSession: (calendarResponse.members_limit === undefined || calendarResponse?.members_limit < 2) ? 'single' : 'group',
        repeat_end: calendarResponse.repeat_end,
        repeat_days: calendarResponse.repeat_days
    })
};
