import {createSelector} from "@reduxjs/toolkit";
import {getProfile} from "../getProfile/getProfile";
import {UserGroup} from "../../types/profileTypes";

export const getProfileName = createSelector(
    getProfile,
    (profile) => {
        if (profile?.user_group_id === UserGroup.SPEC || profile?.user_group_id === UserGroup.SPEC_PREMIUM) {
            return profile.name
        }
        return ''
    }
)
