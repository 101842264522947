import {useLayoutEffect, useState} from "react";

const queries = [
    '(max-width: 470px)',
    '(max-width: 1024px) and (min-width: 769px)',
    '(max-width: 768px) and (min-width: 471px)',
    '(min-width: 1025px)',
]

interface IData {
    isMobile: boolean
    isTablet: boolean
    isTabletMini: boolean
    isDesktop: boolean
}

export const useMatchMedia = () => {
    const mediaQueryLists = queries.map(query => matchMedia(query))

    const getValue = () => mediaQueryLists.map(mql => mql.matches)

    const [values, setValues] = useState<boolean[]>(getValue)

    useLayoutEffect(() => {
        const handler = () => setValues(getValue)

        mediaQueryLists?.forEach(mql => mql.addEventListener('change', handler))

        return () => mediaQueryLists?.forEach(mql => mql.removeEventListener('change', handler))
    })
    const data = ['isMobile', 'isTablet', 'isTabletMini', 'isDesktop']
        .reduce((acc, screen, index) => (
            {
                ...acc,
                [screen]: values[index]
            }
        ), {}) as IData

    return data
}
