import {createAsyncThunk} from '@reduxjs/toolkit';
import {BaseResponse} from "../../../../api/api";
import {ThunkConfig} from "../../../config/StateSchema";
import {ICalendarResponse, ICreateCalendar} from "../../types/IScheduler";
import {snackbarAction} from "../../../errors/SnackbarSlice";

export interface IResponseCalendarData {
    collection: ICalendarResponse
}

export const createCalendar = createAsyncThunk<boolean,
    ICreateCalendar,
    ThunkConfig<number>>(
        'scheduler/createCalendar',
        async (data, thunkAPI) => {
            const {extra, rejectWithValue, dispatch} = thunkAPI;
            try {
                const response = await extra.api.post<BaseResponse<IResponseCalendarData>>(`schedule`,
                    {
                        members_limit: 1,
                        ...data
                    })
                if (!response.data.success) {
                    dispatch(snackbarAction.addError(response.data.codeKey))
                    return rejectWithValue(response.data.code)
                }
                return true
            } catch (err) {
                dispatch(snackbarAction.addError('INTERNAL_ERROR'))
                // @ts-ignore
                return rejectWithValue(err);
            }
        },
    );

// const formattedDate = moment(date).format('YYYY-MM-DD');
