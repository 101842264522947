import {createAsyncThunk} from '@reduxjs/toolkit';
import {ThunkConfig} from "../../../config/StateSchema";
import {BaseResponse} from "../../../../api/api";
import {IChatRequest} from "../../types/chatsTypes";
import {snackbarAction} from "../../../errors/SnackbarSlice";

interface IResponse {
    collection: IChatRequest[]
}

export const fetchChatsRequests = createAsyncThunk<
    IChatRequest[],
   void,
    ThunkConfig<string>
>(
    'chats/fetchChatsRequests',
    async (_, thunkAPI) => {
        const {extra, rejectWithValue, dispatch} = thunkAPI;
        try {
            const response = await extra.api.get<BaseResponse<IResponse>>('chat/request/collection')

            if (!response.data.success) {
                return rejectWithValue('error')
            }

            return response.data.response.collection
        } catch (err) {
            dispatch(snackbarAction.addError('INTERNAL_ERROR'))
            return rejectWithValue('error');
        }
    },
);
