import {createAsyncThunk} from '@reduxjs/toolkit';
import {BaseResponse} from "../../../../api/api";
import {ThunkConfig} from "../../../config/StateSchema";
import {ICalendarResponse} from "../../types/IScheduler";
import {IParamsMyCalendarCollection} from "../fetchCalendarMyCollection/fetchCalendarMyCollection";
import {snackbarAction} from "../../../errors/SnackbarSlice";

export interface IResponseCalendarData {
    collection: ICalendarResponse[]
}

export interface IParamsCalendarCollectionByUserId extends IParamsMyCalendarCollection {
    user_id?: number
}

export const fetchCalendarCollectionByUserId = createAsyncThunk<ICalendarResponse[],
    IParamsCalendarCollectionByUserId,
    ThunkConfig<number>>(
        'scheduler/fetchCalendarCollectionByUserId',
        async (paramsData, thunkAPI) => {
            const {extra, rejectWithValue, dispatch} = thunkAPI;
            try {
                const response = await extra.api.get<BaseResponse<IResponseCalendarData>>(`schedule/collection`, {
                    params: {
                        ...paramsData
                    }
                }
                )
                if (!response.data.success) {
                    return rejectWithValue(response.data.code)
                }
                return response.data.response.collection
            } catch (err) {
                dispatch(snackbarAction.addError('INTERNAL_ERROR'))
                // @ts-ignore
                return rejectWithValue(err);
            }
        },
    );

